
import NotificationService from "../services/notification-service";
import Cookies from "js-cookie";
window.Cookies = Cookies;

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
      notificationnumber: 0,
      idusuario: 0,
      intervalNotification: null

    };
  },
  created() {
    this.notificationService = new NotificationService();
  },
  mounted() {
    this.idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
    this.intervalNotification = setInterval(this.getnotificationuser, 10000);
  },
  methods: {
    notification() {

      this.$router.push({ path: '/registronotificaciones' })
    },
    getnotificationuser() {
      let dataJson = {
        pusuario: this.idusuario
      };

      this.notificationService.existsNotification(dataJson).then(data => {
        this.notificationnumber = data[0].existenotificacion;
      });

    }
  },
  unmounted() {
    clearInterval(this.intervalNotification)
  },

}
