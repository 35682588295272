
  
  import {
    DxDataGrid, DxColumn, DxEditing, DxPaging, DxExport, DxButton, DxSearchPanel, DxFilterRow,
  } from 'devextreme-vue/data-grid';
  import { DxToast } from 'devextreme-vue/toast';
  import Reporteservice from '../services/reportevisitacampo-service';
  import notify from 'devextreme/ui/notify';
  import { saveAs } from 'file-saver-es';
  import { Workbook } from 'exceljs';
  import { exportDataGrid } from 'devextreme/excel_exporter';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
  import DxScrollView from 'devextreme-vue/scroll-view';
  
  import { jsPDF } from "jspdf";
  import 'jspdf-autotable';
  import Cookies from "js-cookie";
    window.Cookies = Cookies;
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxEditing,
      DxPaging,
      DxToast,
      DxExport,
      DxButton,
      DxSearchPanel,
      DxFilterRow,
      DxPopup,
      DxScrollView,
      DxToolbarItem,
      DxTextBox,
    },
    data() {
      return {
        popupVisible: false,
        popupTitle: "Resultado Visita Campo",
        loading: true,
  
        dataSourceDetalle: null,
        dataSourceVisita: null,
        dataSourceResultados: null,
        dataSourceGenerales: null,
  
        datasource_datos_motivo_visita: null,
        datasource_datos_emisiones_atmos: null,
        datasource_datos_aguas_residuales: null,
        datasource_datos_residuos_peligrosos: null,
        datasource_datos_impacto_riesgo_ambiental: null,
        datasource_datos_agua_suministro: null,
  
  
        datasource_datos_alm_temp_residuos_peligrosos: null,
        datasource_datos_manejo_especial: null,
        datasource_datos_impuestos_verdes: null,
  
        datasource_datos_revisionpapeleria: null,
        datasource_datos_visitaautoridades: null,
  
        v_visita_inicio: null,
        v_visita_fin: null,
        v_visita_cliente: null,
        v_visita_fecharegistro: null,
        v_visita_usuarioregistro: null,
        v_visita_firmaluhott: null,
        v_visita_firmaempresa: null,
        v_visita_resposanble: null,
        v_visita_puesto: null,
  
        v_visita_dg_nombre: null,
        v_visita_dg_direccion: null,
        v_visita_dg_telefono: null,
        v_visita_dg_fecharegistro: null,
        v_visita_dg_iniciovisita: null,
        v_visita_dg_finvisita: null,
        v_visita_dg_rol: null,
        v_visita_dg_responsable: null,
        v_visita_dg_observaciones: null,
  
        empresa: {},
        events: [],
        rolService: null,
        isVisible: false,
        message: '',
        type: 'info',
      };
    },
    created() {
      this.reportesService = new Reporteservice();
    },
    mounted() {
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
        let myJSON = JSON.stringify({
                  pusuarioid: idusuario,
              });
      this.reportesService.getReporteVisitaCampo(myJSON).then(data => {
  
        this.dataSourceVisita = data;
  
      });
  
    },
    methods: {
  
      onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('VisitaCampo');
  
        exportDataGrid({
          component: e.component,
          worksheet,
          autoFilterEnabled: true,
        }).then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'VisitaCampo.xlsx');
          });
        });
        e.cancel = true;
      },
  
      logEvent(eventName) {
        this.events.unshift(eventName);
      },
  
      VerDetalle(e) {
  
        this.dataSourceResultados = null;
        this.v_visita_inicio = '';
        this.v_visita_fin = '';
        this.v_cliente = '';
        this.v_visita_usuarioregistro = '';
        this.v_visita_fecharegistro = '';
        this.v_visita_firmaluhott = '';
        this.v_visita_firmaempresa = '';
        this.v_visita_responsable = '';
        this.v_visita_puesto = '';
  
        this.v_visita_inicio = e.row.data.iniciovisita;
        this.v_visita_fin = e.row.data.finvisita;
        this.v_visita_cliente = e.row.data.cliente;
        this.v_visita_usuarioregistro = e.row.data.usuarioregistro;
        this.v_visita_fecharegistro = e.row.data.fecharegistro;
        this.v_visita_firmaluhott = e.row.data.firmaluhott;
        this.v_visita_firmaempresa = e.row.data.firmaempresa;
        this.v_visita_responsable = e.row.data.responsable;
        this.v_visita_puesto = e.row.data.puesto;
  
  
        let myJSONg = JSON.stringify({
          pid: e.row.data.id,
        });
        this.reportesService.getDetalleReporteVisitaCampoParaPDFGenerales(myJSONg).then(data => {
          this.dataSourceGenerales = JSON.stringify(data);
          this.v_visita_dg_nombre = data[0].nombre;
          this.v_visita_dg_direccion = data[0].direccion;
          this.v_visita_dg_telefono = data[0].telefono;
          this.v_visita_dg_fecharegistro = data[0].fecharegistro;
          this.v_visita_dg_iniciovisita = data[0].iniciovisita;
          this.v_visita_dg_finvisita = data[0].finvisita;
          this.v_visita_dg_rol = data[0].rol;
          this.v_visita_dg_responsable = data[0].responsable;
          this.v_visita_dg_observaciones = data[0].observaciones;
   
  
        });
  
  
  
        let myJSON = JSON.stringify({
          pid: e.row.data.id,
        });
        this.reportesService.getDetalleReporteVisitaCampoParaPDF(myJSON).then(data => {
  
  
  
          var datosModificados = JSON.parse(JSON.stringify(data));
          this.dataSourceResultados = data;
          /*
          Cambio de la parte Observaciones de las tablas
          datosModificados.forEach(objeto => {
              // Verificar si el valor de 'elemento' es igual a "OBSERVACIONES:"
              if (objeto.elemento === "OBSERVACIONES" ) {
                  // Concatenar el valor de 'res' con el valor de 'elemento'
                  objeto.elemento += ": " + objeto.res;
              }
          });*/
          
          this.datasource_datos_motivo_visita = JSON.stringify(datosModificados.filter(data => data.claveconceptopadre == 'CLA001'));
          this.datasource_datos_emisiones_atmos = JSON.stringify(datosModificados.filter(data => data.claveconceptopadre == 'CLA002'));
          this.datasource_datos_aguas_residuales = JSON.stringify(datosModificados.filter(data => data.claveconceptopadre == 'CLA003'));
  
          this.datasource_datos_residuos_peligrosos = JSON.stringify(datosModificados.filter(data => data.claveconceptopadre == 'CLA004'));
          this.datasource_datos_impacto_riesgo_ambiental = JSON.stringify(datosModificados.filter(data => data.claveconceptopadre == 'CLA005'));
          this.datasource_datos_agua_suministro = JSON.stringify(datosModificados.filter(data => data.claveconceptopadre == 'CLA006'));
  
          this.datasource_datos_alm_temp_residuos_peligrosos = JSON.stringify(datosModificados.filter(data => data.claveconceptopadre == 'CLA007'));
          this.datasource_datos_manejo_especial = JSON.stringify(datosModificados.filter(data => data.claveconceptopadre == 'CLA008'));
          this.datasource_datos_impuestos_verdes = JSON.stringify(datosModificados.filter(data => data.claveconceptopadre == 'CLA009'));
  
          this.datasource_datos_revisionpapeleria = JSON.stringify(datosModificados.filter(data => data.claveconceptopadre == 'CLA010'));
          this.datasource_datos_visitaautoridades = JSON.stringify(datosModificados.filter(data => data.claveconceptopadre == 'CLA011'));
  
        });
        this.loading = true;
        this.popupVisible = true;
      },
  
      exportarPDFValidacion() {
  
        if(
            this.v_visita_firmaluhott == null || this.v_visita_firmaluhott == 'null' ||
          this.v_visita_firmaempresa == null || this.v_visita_firmaempresa == 'null' )
        {
          this.type = 'error';
                  this.message = 'Formato no se ha generado, hacen faltas las firmas';
                  this.isVisible = true;
                  notify({
                      message: 'Ocurrio un error',
                  }, this.type, 4000);
        }
        else{
          this.exportarPDF();
        }
        
      },
     
  
      exportarPDF() {
  
        
        // TRABAJAR EXPORT PDF
        const doc = new jsPDF('landscape', 'mm', 'a4');
  
        //Centrar el texto
        let url = 'https://www.lmeraki.com/';
        var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        var img = new Image();
        img.src = require("../assets/logo.png");
        doc.addImage(img, "PNG", 15, 3, 40, 20);
  
        doc.setFontSize(6);
        doc.setFont('helvetica', 'bold');
        doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
        doc.setFont('helvetica', 'normal');
        doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
        doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
        doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
  
        doc.setFontSize(6);
        doc.setFont('helvetica', 'bold');
        doc.text("NOMBRE DE LA EMPRESA", 20, 35, { align: "left" });
        doc.text( this.v_visita_dg_nombre, 55, 35, { align: "left" });
        doc.text("FECHA", 247, 35, { align: "left" });
        doc.text(this.v_visita_dg_fecharegistro, 260, 35, { align: "left" });
        doc.line(15, 37, 290, 37);
        doc.text("DIRECCIÓN", 28, 40, { align: "left" });
        doc.text(this.v_visita_dg_direccion, 55, 40, { align: "left" });
        doc.line(15, 42, 290, 42);
        doc.text("RESPONSABLE DE VISITA", 20, 45, { align: "left" });
        doc.text(this.v_visita_dg_responsable, 55, 45, { align: "left" });
        doc.text("PUESTO", 236, 45, { align: "left" });
        doc.text(this.v_visita_dg_rol, 255, 45, { align: "left" });
        doc.line(15, 47, 290, 47);
        doc.text("TELÉFONO", 28, 50, { align: "left" });
        doc.text(this.v_visita_dg_telefono, 55, 50, { align: "left" });
        doc.text("INICIO DE VISITA", 165, 50, { align: "left" });
        doc.text(this.v_visita_dg_iniciovisita, 192, 50, { align: "left" });
        doc.text("FIN DE VISITA", 235, 50, { align: "left" });
        doc.text(this.v_visita_dg_finvisita, 257, 50, { align: "left" });
  
  
        doc.line(51, 32, 51, 52);
        //Fecha
        doc.line(243, 32, 243, 37);
        doc.line(258, 32, 258, 37);
        //Puesto
        doc.line(233, 42, 233, 47);
        doc.line(248, 42, 248, 47);
        //Inicio Visita
        doc.line(163, 47, 163, 52);
        doc.line(185, 47, 185, 52);
        //Fin Visita
        doc.line(232, 47, 232, 52);
        doc.line(252, 47, 252, 52);
        doc.rect(15, 32, 275, 20);
  
  
        // Columnas
  
        var columns_tabla = [
          { title: "", dataKey: "elemento" },
          { title: "", dataKey: "res" },
        ];
        
        
        var columns_tabla_observaciones = [
          { title: "", dataKey: "observaciones" } 
        ];
  
        //Datos de cada elemento
  
        var datos_motivo_visita = JSON.parse(this.datasource_datos_motivo_visita);
        var datos_emisiones_atmos = JSON.parse(this.datasource_datos_emisiones_atmos);
        var datos_aguas_residuales = JSON.parse(this.datasource_datos_aguas_residuales);
        var datos_residuos_peligrosos = JSON.parse(this.datasource_datos_residuos_peligrosos);
        var datos_impacto_riesgo_ambiental = JSON.parse(this.datasource_datos_impacto_riesgo_ambiental);
        var datos_agua_suministro = JSON.parse(this.datasource_datos_agua_suministro);
        var datos_alm_temp_residuos_peligrosos = JSON.parse(this.datasource_datos_alm_temp_residuos_peligrosos);
        var datos_manejo_especial = JSON.parse(this.datasource_datos_manejo_especial);
        var datos_impuestos_verdes = JSON.parse(this.datasource_datos_impuestos_verdes);
        var datos_revisionpapeleria = JSON.parse(this.datasource_datos_revisionpapeleria);
        var datos_visitaautoridades = JSON.parse(this.datasource_datos_visitaautoridades);
        var datos_generales = JSON.parse(this.dataSourceGenerales);
  
  
        var nestedTableCell = {
          content: '',
          styles: { minCellHeight: 40 },
        }
  
        var observacionesTableCell = {
          content: '',
          styles: { minCellHeight: 110 },
        }
  
  
        // 1 
        doc.autoTable({
          theme: 'plain',
          head: [['MOTIVO DE VISITA', 'EMISIONES A LA ATMÓSFERA', 'AGUAS RESIDUALES']],
          body: [[nestedTableCell]],
          startY: 53,
          headStyles: { halign: 'center', cellWidth: 'auto' },
          bodyStyles: { cellWidth: 'wrap' },
          styles: { cellPadding: 0.5, fontSize: 8 },
          pageBreak: 'avoid',
          didDrawCell: function (data) {
            if (data.row.index === 0 && data.row.section === 'body') {
              if (data.column.index === 0) {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 6
                  },
                  columns: columns_tabla,
                  body: datos_motivo_visita, //DATASOURCE 1
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                  bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                  },
                });
              } else if (data.column.index === 1) {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 6
                  },
                  columns: columns_tabla,
                  body: datos_emisiones_atmos, //DATASOURCE 2
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                  bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                  },
                });
              } else {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 6
                  },
                  columns: columns_tabla,
                  body: datos_aguas_residuales, //DATASOURCE 3
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                  bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                  },
                });
              }
  
            } 
          },
        });
  
        // 2
  
        doc.autoTable({
          theme: 'plain',
          head: [['RESIDUOS PELIGROSOSOS', 'IMPACTO Y RIESGO AMBIENTAL', 'AGUA DE SUMINISTRO']],
          body: [[nestedTableCell]],
          startY: doc.lastAutoTable.finalY + 2,
          headStyles: { halign: 'center', cellWidth: 'wrap' },
          styles: { cellPadding: 0.5, fontSize: 6 },
          pageBreak: 'avoid',
          didDrawCell: function (data) {
            if (data.row.index === 0 && data.row.section === 'body') {
              if (data.column.index === 0) {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 6
                  },
                  columns: columns_tabla,
                  body: datos_residuos_peligrosos,
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                  bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                  },
                });
              } else if (data.column.index === 1) {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 6
                  },
                  columns: columns_tabla,
                  body: datos_impacto_riesgo_ambiental,
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                  bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                  },
                });
              } else {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 6
                  },
                  columns: columns_tabla,
                  body: datos_agua_suministro,
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                  bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                  },
                });
              }
  
            }
          },
        });
  
        //3
        doc.autoTable({
          theme: 'plain',
          head: [['ALMACÉN TEMPORAL DE RESIDUOS PELIGROSOS', 'RESIDUOS DE MANEJO ESPECIAL', 'IMPUESTOS VERDES']],
          body: [[nestedTableCell]],
          startY: doc.lastAutoTable.finalY + 2,
          headStyles: { halign: 'center', cellWidth: 'wrap' },
          styles: { cellPadding: 0.5, fontSize: 8 },
          pageBreak: 'avoid',
          didDrawCell: function (data) {
            if (data.row.index === 0 && data.row.section === 'body') {
              if (data.column.index === 0) {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 8
                  },
                  columns: columns_tabla,
                  body: datos_alm_temp_residuos_peligrosos,
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                  bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                  },
                });
              } else if (data.column.index === 1) {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 8
                  },
                  columns: columns_tabla,
                  body: datos_manejo_especial,
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                  bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                  },
                });
              } else {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 8
                  },
                  columns: columns_tabla,
                  body: datos_impuestos_verdes,
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                  bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                  },
                });
              }
  
            }
          },
        });
  
        doc.addPage();
  
        doc.addImage(img, "PNG", 15, 3, 40, 20);
  
        doc.setFontSize(6);
        doc.setFont('helvetica', 'bold');
        doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
        doc.setFont('helvetica', 'normal');
        doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
        doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
        doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
  
        var datos_nomenclatura = [
            { elemento: "OK", res: "CORRECTO" },
            { elemento: "NA", res: "NO APLICA" },
            { elemento: "SC", res: "SIN CUMPLIMIENTO" },
            { elemento: "CP", res: "CUMPLIMIENTO PARCIAL" },
            { elemento: "P", res: "PENDIENTE" },
            { elemento: "U", res: "URGENTE" },
            { elemento: "RA", res: "REQUIERE ANÁLISIS" }
        ];
  
        //4
  
        doc.autoTable({
          theme: 'plain',
          head: [['REVISIÓN DE PAPELERÍA', '                                                           ']],
          body: [[nestedTableCell]],
          startY: 25,
          headStyles: { halign: 'center', cellWidth: 'wrap' },
          styles: { cellPadding: 0.5, fontSize: 8 },
          pageBreak: 'avoid',
          didDrawCell: function (data) {
            if (data.row.index === 0 && data.row.section === 'body') {
              if (data.column.index === 0) {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 8
                  },
                  columns: columns_tabla,
                  body: datos_revisionpapeleria,
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                  bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                  },
                });
              } 
            }
          },
        });
  
        doc.autoTable({
          theme: 'plain',
          head: [['VISITA DE AUTORIDADES', '                                                           ']],
          body: [[nestedTableCell]],
          startY: doc.lastAutoTable.finalY + 25,
          headStyles: { halign: 'center', cellWidth: 'wrap' },
          styles: { cellPadding: 0.5, fontSize: 8 },
          pageBreak: 'avoid',
          didDrawCell: function (data) {
            if (data.row.index === 0 && data.row.section === 'body') {
              if (data.column.index === 0) {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 8
                  },
                  columns: columns_tabla,
                  body: datos_visitaautoridades,
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                  bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                  },
                });
              } 
            }
          },
        });
  
        doc.autoTable({
          theme: 'plain',
          head: [['NOMENCLATURA', '                                          ']],
          body: [[nestedTableCell]],
          startY: doc.lastAutoTable.finalY + 8, 
          headStyles: { halign: 'center', cellWidth: 'wrap' },
          styles: { cellPadding: 0.5, fontSize: 8 },
          pageBreak: 'avoid',
          didDrawCell: function (data) {
            if (data.row.index === 0 && data.row.section === 'body') {
              if (data.column.index === 0) {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 8
                  },
                  columns: columns_tabla,
                  body: datos_nomenclatura,
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                  bodyStyles: {
                    lineWidth: 0.2,
                    lineColor: [0, 0, 0],
                  },
                });
              } 
            }
          },
        });
  
        doc.autoTable({
          theme: 'plain',
          head: [['                                                                        ', 'OBSERVACIONES Y/O RECOMENDACIONES']],
          body: [[observacionesTableCell]],
          startY: 25, 
          headStyles: { halign: 'center', cellWidth: 'wrap' },
          styles: { cellPadding: 0.5, fontSize: 8 },
          pageBreak: 'avoid',
          bodyStyles: {
            1 : {
              lineWidth: 0.2,
              lineColor: [0, 0, 0],
            }  
          },
          didParseCell: function (data) {
            if(data.column.index === 1 && data.row.section === 'body'){
              data.cell.styles.lineWidth = 0.2;
              data.cell.styles.lineColor = [0, 0, 0];
            }
          },
          didDrawCell: function (data) {
            if (data.row.index === 0 && data.row.section === 'body') {
              if (data.column.index === 1) {
                doc.autoTable({
                  startY: data.cell.y + 2,
                  margin: { left: data.cell.x + 2 },
                  tableWidth: data.cell.width - 4,
                  theme: 'plain',
                  styles: {
                    maxCellHeight: 4,
                    cellPadding: 0.5,
                    fontSize: 8
                  },
                  columns: columns_tabla_observaciones,
                  body: datos_generales,
                  headStyles: {
                    textColor: [255, 255, 255], // Color de texto blanco (transparente)
                    fontStyle: 'normal',        // Estilo de fuente normal
                  },
                });
              } 
            }
          },
        });
  
        var tablaFirmas = {
          content: '',
          styles: { minCellHeight: 30,  },
        }
        
         
        var valid1 = this.v_visita_firmaluhott;
        var valid2 = this.v_visita_firmaempresa;
        var firmaLuhott = process.env.VUE_APP_FOTOSURL + this.v_visita_firmaluhott + '';
        var firmaEmpresa = process.env.VUE_APP_FOTOSURL + this.v_visita_firmaempresa + '';
       
       
  
       
        
        doc.autoTable({
          theme: 'plain',
          head: [['RESPONSABLE POR PARTE DE LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.', 'RESPONSABLE POR PARTE DE LA EMPRESA']],
          body: [[tablaFirmas]],
          startY: doc.lastAutoTable.finalY + 6,
          headStyles: { halign: 'center', cellWidth: 'auto',},
          styles: { cellPadding: 0.5, fontSize: 8 },
          margin: { left: 145 },
          pageBreak: 'avoid',
          bodyStyles: {
            lineWidth: 0.2,
            lineColor: [0, 0, 0],
          },
          didParseCell: function (data) {
            if(data.column.index === 1 && data.row.section === 'body'){
              data.cell.styles.cellWidth = 70;
            }
          },
          didDrawCell: function (data) {
            if (data.row.index === 0 && data.row.section === 'body') {
              if (data.column.index === 0) {
                
                if(valid1 != null){
                  var imgF1 = new Image();
                  imgF1.src = firmaLuhott;
                  
                  doc.addImage(imgF1, "PNG", data.cell.x + 7, data.cell.y + 5, data.cell.width - 15, data.cell.height - 15);
                }
                
  
              } else if (data.column.index === 1){
                
                if(valid2 != null){
                  var imgF2 = new Image();
                  imgF2.src = firmaEmpresa;
                  doc.addImage(imgF2, "PNG", data.cell.x + 7, data.cell.y + 5, data.cell.width - 15, data.cell.height - 15);
                }
                 
              }
  
            }
          },
        });
  
  
        doc.autoPrint();
        window.open(
          URL.createObjectURL(doc.output("blob")),
          "_blank",
          "height=650,width=500,scrollbars=yes,location=yes"
        );
        setTimeout(() => {
          window.URL.revokeObjectURL(doc.output("bloburl"));
        }, 100);
  
  
      },
  
    },
  };
  