


import DxForm, {
    DxGroupItem,
    DxSimpleItem,
    DxLabel 
} from 'devextreme-vue/form';

 
 
import 'devextreme-vue/text-area';

import Cookies from "js-cookie";

window.Cookies = Cookies;
import UsuarioService from '../services/usuario-service';

export default {
    components: {
     
        DxGroupItem,
        DxSimpleItem,
        DxForm,
        DxLabel 
    },
    data() {
        return {
            customers:null,
            backendURL: process.env.VUE_APP_APIURL,
            
        };
    },
    computed: {

    },
    created() {

        this.usuarioService = new UsuarioService();
    },
    mounted() {
        let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
        let myJSON = JSON.stringify({
                    pusuarioid: idusuario,
                   
                });
             

            this.usuarioService.getUsuarioPerfilEmpresa(myJSON).then(data => {
                this.customers = data
                 console.log(data);
                }
  
        );
    },
    methods: {
        buttonClick(e) {
           console.log(e);
        }
    },
};
