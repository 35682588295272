

import {
  DxDataGrid, DxColumn, DxEditing, DxPaging, DxExport, DxFormItem,
  DxGrouping,
  DxGroupPanel, DxButton
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import Reporteservice from '../services/reportes-service';
import EnviaAlertaService from '../services/reportes-service';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import { DxTextBox } from 'devextreme-vue/text-box';

import DxTextArea from 'devextreme-vue/text-area';


import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxNumberBox } from 'devextreme-vue/number-box';

import notify from 'devextreme/ui/notify';



export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxExport,
    DxNumberBox,
    DxButton,
    DxFormItem,
    DxGrouping,
    DxGroupPanel,
    DxPopup,
    DxScrollView,
    DxToolbarItem,
    DxTextBox,

    DxTextArea,
  },
  data() {
    return {
      popupVisible: false,
      popupTitle: "Enviar de Alertas",
      loading: true,

      frm_id: null,
      frm_numeroordentrabajocompuesta: null,
      frm_numeroordentrabajo: null,
      frm_servicio: null,
      frm_fecharegistro: null,
      frm_observacion: null,
      frm_estatusid: null,
      frm_estatus: null,
      frm_etapaid: null,
      frm_cliente: null,
      frm_polizaid: null,
      frm_numeropoliza: null,
      frm_actividad: null,
      frm_progreso: null,
      frm_fechaactual: null,
      frm_fechainicio: null,
      frm_fechafin: null,
      frm_diferencia: null,
      frm_responsableactividad: null,
      frm_responsableactividadcorreos: null,
      frm_titulo: null,
      frm_mensaje: null,
      frm_cc: null,
      frm_co: null,
      frm_enviado: null,
      frm_resjson: null,

      dataSourceEnvioAlerta: null,
      dataSourceCompany: null,
      empresa: {},
      events: [],
      rolService: null,
      isVisible: false,
      message: '',
      type: 'info',
      v_valor: null,
      popupOptions: {
        title: 'Informacion Alerta Envio',
        showTitle: true,
        width: 1000,
        height: 700,
      },
    };
  },
  created() {
    this.reportesService = new Reporteservice();
    this.enviaralertaService = new EnviaAlertaService();
  },
  mounted() {


  },
  methods: {
    clickBuscar() {
      let myJSON = JSON.stringify({
        pidusuario: 0,
        pdiasvencimiento: this.v_valor,
      });


      this.reportesService.getReporteAlertas(myJSON).then(data => { this.dataSourceCompany = data; });


    },
    editRegister(e) {



      this.frm_id = e.row.data.id;
      this.frm_numeroordentrabajocompuesta = e.row.data.numeroordentrabajocompuesta;
      this.frm_numeroordentrabajo = e.row.data.numeroordentrabajo;
      this.frm_servicio = e.row.data.servicio;
      this.frm_fecharegistro = e.row.data.fecharegistro;
      this.frm_observacion = e.row.data.observacion;
      this.frm_estatusid = e.row.data.estatusid;
      this.frm_estatus = e.row.data.estatus;
      this.frm_etapaid = e.row.data.etapaid;
      this.frm_cliente = e.row.data.cliente;
      this.frm_polizaid = e.row.data.polizaid;
      this.frm_numeropoliza = e.row.data.numeropoliza;
      this.frm_actividad = e.row.data.actividad;
      this.frm_progreso = e.row.data.progreso;
      this.frm_fechaactual = e.row.data.fechaactual;
      this.frm_fechainicio = e.row.data.fechainicio;
      this.frm_fechafin = e.row.data.fechafin;
      this.frm_diferencia = e.row.data.diferencia;
      this.frm_responsableactividad = e.row.data.responsableactividad;
      this.frm_responsableactividadcorreos = e.row.data.responsableactividadcorreos;
      this.frm_titulo = e.row.data.titulo;
      this.frm_mensaje = e.row.data.mensaje;
      this.frm_cc = e.row.data.cc;
      this.frm_co = e.row.data.co;
      this.frm_enviado = e.row.data.enviado;
      this.frm_resjson = JSON.stringify(e.row.data);
      this.loading = true;
      this.popupVisible = true;


    },
    enviaMail() {


      let err = 0;
      let msgerror = '';


      if (this.frm_responsableactividadcorreos == null || this.frm_responsableactividadcorreos == '') {
        err = 1;
        msgerror = 'Actividad No Cuenta con Responsables / Correos Asignados';
      }




      let myJSON = JSON.stringify({
        pid: this.frm_id,
      });



      if (err == 0) {
        this.reportesService.updateReporteAlertasEnviado(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha enviado alerta';
            this.isVisible = true;
            this.clickBuscar();
            notify({
              message: this.message,
            }, this.type, 8000);
            this.popupVisible = false;

          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
        this.enviaralertaService.EnviaAlertas(this.frm_resjson).then(data => { this.dataSourceEnvioAlerta = data; });

      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 9000);

      }




    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('AvanceListado');

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReporteAlertas.xlsx');
        });
      });
      e.cancel = true;
    },


  },
};
