
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxValidationRule,
  DxPaging,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxSelection,
 
} from 'devextreme-vue/data-grid';

 
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import TipoServicioService from '../services/tiposervicio-service';
//import GrupoListadoInformacionService from '../services/grupolistadodocumentacion-service';
 
import Cookies from "js-cookie";
window.Cookies = Cookies;


export default {
  components: {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxValidationRule,
    DxPaging,
    
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxSelection,
    DxToast,
    
  },
  data() {
    return {
      productos: null,
      dataSourceProducts: null,
      dataSourceListado: null,
      dataSourceGroupProducts: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Servicios',
        showTitle: true,
        width: 500,
        height: 250,
      },
      suppliersData: createStore({
                key: 'SupplierID',
            }),
      servicioService: null,
       labelMode: 'floating',
      labelLocation: 'left',
    };
  },
  created() {
    this.tiposervicioService = new TipoServicioService();
    //this.grupolistadoinformacionService = new GrupoListadoInformacionService();
  },
  mounted() {
    this.loadDataInfo();
  },

  methods: {
    loadDataInfo() {
      this.tiposervicioService.getTipoServicio().then(data => {
        data.forEach(
          element => {

            if (element.bactivo == 1) {
              element.bactivo = true;
            } else {
              element.bactivo = false;
            }

          });
        this.dataSourceProducts = data;

      });

    //this.grupolistadoinformacionService.getGrupoListadoInformacion().then(data => this.dataSourceListado = data);
      
    },
    onSaved() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },

    editRegister(e) {
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
      let vactivo = e.data.bactivo == true ? 1 : 0;
       let vaplicaguia = e.data.baplicaguia == true ? 1 : 0;
      let vaplicaplan = e.data.baplicaplan == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pid: e.data.id,
        pclave: e.data.clave,
        pdescripcion: e.data.descripcion,
        pusuarioregistroid: idusuario,
        pbactivo: vactivo,
         pbaplicaguia: vaplicaguia,
        pbaplicaplan: vaplicaplan,
         
      });



      this.tiposervicioService.updateTipoServicio(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;

        }


      });
    },
    deleteRegister(e) {
      let myJSON = JSON.stringify({
        pid: e.data.id,
      });

     
       this.tiposervicioService.deleteTipoServicio(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
          //this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
         // this.loadDataInfo();
        }
        this.loadDataInfo();

      });
    },
    createRegister(e) {

      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
      let vactivo = e.data.bactivo == true ? 1 : 0;
      let vaplicaguia = e.data.baplicaguia == true ? 1 : 0;
      let vaplicaplan = e.data.baplicaplan == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pclave: e.data.clave,
        pdescripcion: e.data.descripcion,
        pusuarioregistroid: idusuario,
        pbactivo: vactivo,
        pbaplicaguia: vaplicaguia,
        pbaplicaplan: vaplicaplan,
       
      });



      this.tiposervicioService.createTipoServicio(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          //this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
         // this.loadDataInfo();
        }
        this.loadDataInfo();

      });


    },

    onInitNewRow(e) {
      e.data.serializable = true;
      e.data.bactivo = true;
  
      //e.data.id_equipo_padre = null;      
      //this.equipmentService.getProductoPadres().then(data => this.dataSourceFatherItems=data);
    },
  },
};
