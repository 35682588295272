
  import DxLoadIndicator from "devextreme-vue/load-indicator";
  import DxForm, {
    DxItem,
    DxRequiredRule,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
  } from "devextreme-vue/form";
  import notify from 'devextreme/ui/notify';
  
  import UsuarioService from '../services/usuario-service';
  import Cookies from "js-cookie";
  window.Cookies = Cookies;
  export default {
    name: "Login",
    props: ["user"],
    data() {
      return {
        formData: {},
        loading: false,
        loginpost: [],
      };
    },
    setup() {
       
    },

    loginservice: null,
    created() {
        this.usuarioService = new UsuarioService();
    },
    mounted() {
      
    },
    methods: {
      onCreateAccountClick() {
        this.$router.push("/create-account");
      },
      onSubmit: async function () {
  
        const { passwordactual, passwordnuevo , passwordnuevoconfirma } = this.formData;

       
  
        let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
        let myJSON = JSON.stringify({
                pid: idusuario,
                pcontrasena: passwordactual,
                pcontrasenanueva: passwordnuevo,
                pcontrasenaconfirma:passwordnuevoconfirma
                   
                });
                

                 this.usuarioService.cambiacontrasenaEmpresa(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado la contraseña';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                         
                        Cookies.set("ets_user_ec", "null");
                        this.$router.push({ name: 'login'});
                        
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                  

                    });
 
      },
    },
    components: {
      DxLoadIndicator,
      DxForm,
  
      DxRequiredRule,
      DxItem,
      DxLabel,
      DxButtonItem,
      DxButtonOptions,
    },
  };
  