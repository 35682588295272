
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,

} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import 'devextreme-vue/text-area';

import ConsideracionService from '../services/consideracion-service';
import Cookies from "js-cookie";
window.Cookies = Cookies;



export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxLookup,
        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,

    },
    props: {
        supplierId: {
            type: Number,
            default: null,
        },
    },
    data() {

        return {

            procedimientos: null,
            dataSourceEstatus: null,
            dataSourceCotizacionServicio: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Agregar Condición',
                showTitle: true,
                width: 700,
                height: 200,
            },



        };
    },
    created() {

        this.cotizacionconsideracionService = new ConsideracionService();

    },
    mounted() {

        this.loadDataInfo();
    },
    methods: {
        loadDataInfo() {
            let id = this.supplierId;
            let myJSON = JSON.stringify({
                pcotizacionid: id
            });

            this.cotizacionconsideracionService.getConsideracionCotizacion(myJSON).then(data => this.dataSourceCotizacionServicio = data);
            this.cotizacionconsideracionService.getConsideracion().then(data => this.dataSourceEstatus = data);
        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },


        createRegister(e) {
            let idcotizacion = this.supplierId;
            let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
            let myJSON = JSON.stringify({
                pcotizacionid: idcotizacion,
                pconsideracionid: e.data.consideracionid,
                pusuarioregistroid: idusuario,
            });


            this.cotizacionconsideracionService.createConsideracionCotizacion(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        deleteRegister(e) {

            let myJSON = JSON.stringify({
                pid: e.data.id
            });


            this.cotizacionconsideracionService.deleteConsideracionCotizacion(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },

        onInitNewRow(e) {
            e.data.bactivo = true;
        },
    },
};
