

import Chart from 'chart.js';
import DxSelectBox from 'devextreme-vue/select-box';
import Periodoservice from '../services/periodo-service';



import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxSearchPanel,
    DxFilterRow,
    DxButton,

} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import Reporteservice from '../services/reportes-service';

import Clienteservice from '../services/cliente-service';

import notify from 'devextreme/ui/notify';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';



import Cookies from "js-cookie";
window.Cookies = Cookies;

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxPaging,
        DxToast,
        DxSearchPanel,
        DxFilterRow,
        DxSelectBox,
        DxButton,
    },
    data() {
        return {
            ordentrabajoreporte:null,
            ordentrabajoreporte_variable:null,

            frm_v_agua_json_inc:null ,
            frm_v_agua_json_aut :null,
            frm_v_suelo_json_inc :null,
            frm_v_suelo_json_aut:null  ,
            frm_v_ruido_json_inc:null ,
            frm_v_ruido_json_aut :null,
            frm_v_aire_json_inc:null,
            frm_v_aire_json_aut :null,
            frm_v_residuos_json_inc:null,
            frm_v_residuos_json_aut :null,
            frm_v_riesgo_json_inc:null,
            frm_v_riesgo_json_aut :null,
            frm_v_impacto_json_inc:null,
            frm_v_impacto_json_aut :null,
            frm_v_registroemision_json_inc:null,
            frm_v_registroemision_json_aut :null,
            frm_v_permisos_json_inc:null,
            frm_v_permisos_json_aut :null,
            frm_v_administracionambiental_json_inc :null,
            frm_v_administracionambiental_json_aut :null,
            frm_v_otras_json_inc:null,
            frm_v_otras_json_aut :null,
            
            frm_v_cumplimiento :null,
            frm_v_incumplimiento :null,
            frm_v_agua_cumplimiento:null ,
            frm_v_agua_incumplimiento:null,
            frm_v_suelo_cumplimiento:null ,
            frm_v_suelo_incumplimiento:null,
            frm_v_ruido_cumplimiento:null ,
            frm_v_ruido_incumplimiento:null,
            frm_v_aire_cumplimiento:null ,
            frm_v_aire_incumplimiento:null,
            frm_v_residuos_cumplimiento:null ,
            frm_v_residuos_incumplimiento:null,
            frm_v_riesgo_cumplimiento:null ,
            frm_v_riesgo_incumplimiento:null,
            frm_v_impacto_cumplimiento:null ,
            frm_v_impacto_incumplimiento:null,
            frm_v_registroemision_cumplimiento:null ,
            frm_v_registroemision_incumplimiento:null,
            frm_v_permisos_cumplimiento:null ,
            frm_v_permisos_incumplimiento:null,
            frm_v_administracionambiental_cumplimiento:null ,
            frm_v_administracionambiental_incumplimiento:null,
            frm_v_otras_cumplimiento:null ,
            frm_v_otras_incumplimiento:null,

            frm_v_agua_noaplica :null,
            frm_v_suelo_noaplica :null,
            frm_v_ruido_noaplica :null,
            frm_v_aire_noaplica :null,
            frm_v_residuos_noaplica :null,
            frm_v_riesgo_noaplica :null,
            frm_v_impacto_noaplica :null,
            frm_v_registroemision_noaplica :null,
            frm_v_permisos_noaplica :null,
            frm_v_administracionambiental_noaplica :null,
            frm_v_otras_noaplica :null,

            dataSourcePeriodo:null,
            dataSourceGrafico: null,
            dataSourcePendienteInterno: null,
            dataSourcePendienteCliente: null,
            frm_v_cliente: null,
            frm_v_servicio: null,
            frm_v_fechaelaboracion: null,
            frm_v_periodo: null,
            frm_v_estatus: null,
            frm_v_grafica: null,
            frm_v_ot: null,
 
            frm_periodo: null,

            dataSourceCliente: null,
            dataSourceCompany: [],
            dataSourceVisita: null,
            empresa: {},
            events: [],
            rolService: null,
            isVisible: false,
            message: '',
            type: 'info',

            popupVisible: false,
            popupTitle: "Detalle",
            loading: true,

            popupOptions: {
                title: 'Detalle de Reporte',
                showTitle: true,
                width: 1000,
                height: 900,
            },
            dataSourcePieDev: [{
                texto: 'Russia',
                valor: 12,
            }, {
                texto: 'Canada',
                valor: 7,
            }, {
                texto: 'USA',
                valor: 7,
            }],
        };
    },
    created() {
        this.reportesService = new Reporteservice();
        this.periodoService = new Periodoservice();
        this.clienteService = new Clienteservice();
    },
    mounted() {

        this.periodoService.getPeriodo().then(data => this.dataSourcePeriodo = data);
        this.clienteService.getCliente().then(data => this.dataSourceCliente = data);
    },
    methods: {
        generarTodo() {
            
            this.ordentrabajoreporte = '';
            this.exportarTodo();
        },
        exportar(e) {
            
            this.ordentrabajoreporte = "myChart" + "_normal_" +  e.row.data.id+"_"+e.row.data.ordentrabajoid.toString();
            this.ordentrabajoreporte_variable =  e.row.data.id+"_"+e.row.data.ordentrabajoid.toString();
           //this.dataSourceGrafico = JSON.parse(e.row.data.json_graficaentregajson);
          
           this.frm_v_cliente = e.row.data.cliente;
           this.frm_v_servicio = e.row.data.servicio;
           this.frm_v_fechaelaboracion = e.row.data.fechaelaboracion;
           this.frm_v_periodo = e.row.data.periodo;
           this.frm_v_ot = e.row.data.ot;
           this.frm_v_estatus = e.row.data.estatus;
           this.frm_v_grafica = e.row.data.json_graficaentregajson;

           this.frm_v_agua_json_inc= e.row.data.agua_json_inc;
           this.frm_v_agua_json_aut = e.row.data.agua_json_aut;
           this.frm_v_suelo_json_inc = e.row.data.suelo_json_inc;
           this.frm_v_suelo_json_aut= e.row.data.suelo_json_aut;
           this.frm_v_ruido_json_inc= e.row.data.ruido_json_inc;
           this.frm_v_ruido_json_aut = e.row.data.ruido_json_aut;
           this.frm_v_aire_json_inc= e.row.data.aire_json_inc;
           this.frm_v_aire_json_aut = e.row.data.aire_json_aut;
           this.frm_v_residuos_json_inc= e.row.data.residuos_json_inc;
           this.frm_v_residuos_json_aut = e.row.data.residuos_json_aut;
           this.frm_v_riesgo_json_inc= e.row.data.riesgo_json_inc;
           this.frm_v_riesgo_json_aut = e.row.data.riesgo_json_aut;
           this.frm_v_impacto_json_inc= e.row.data.impacto_json_inc;
           this.frm_v_impacto_json_aut= e.row.data.impacto_json_aut;
           this.frm_v_registroemision_json_inc= e.row.data.registroemision_json_inc;
           this.frm_v_registroemision_json_aut = e.row.data.registroemision_json_aut;
           this.frm_v_permisos_json_inc= e.row.data.permisos_json_inc;
           this.frm_v_permisos_json_aut = e.row.data.permisos_json_aut;
           this.frm_v_administracionambiental_json_inc= e.row.data.administracionambiental_json_inc;
           this.frm_v_administracionambiental_json_aut= e.row.data.administracionambiental_json_aut;
           this.frm_v_otras_json_inc= e.row.data.otras_json_inc;
           this.frm_v_otras_json_aut = e.row.data.otras_json_aut;

           
           this.frm_v_cumplimiento = e.row.data.cumplimiento;
           this.frm_v_incumplimiento = e.row.data.incumplimiento;
           this.frm_v_agua_cumplimiento = e.row.data.agua_cumplimiento;
           this.frm_v_agua_incumplimiento = e.row.data.agua_incumplimiento;
            this.frm_v_suelo_cumplimiento = e.row.data.suelo_cumplimiento;
            this.frm_v_suelo_incumplimiento = e.row.data.suelo_incumplimiento;
            this.frm_v_ruido_cumplimiento = e.row.data.ruido_cumplimiento;
            this.frm_v_ruido_incumplimiento = e.row.data.ruido_incumplimiento;
            this.frm_v_aire_cumplimiento = e.row.data.aire_cumplimiento;
            this.frm_v_aire_incumplimiento = e.row.data.aire_incumplimiento;
            this.frm_v_residuos_cumplimiento = e.row.data.residuos_cumplimiento;
            this.frm_v_residuos_incumplimiento = e.row.data.residuos_incumplimiento;
            this.frm_v_riesgo_cumplimiento = e.row.data.riesgo_cumplimiento;
            this.frm_v_riesgo_incumplimiento = e.row.data.riesgo_incumplimiento;
            this.frm_v_impacto_cumplimiento = e.row.data.impacto_cumplimiento;
            this.frm_v_impacto_incumplimiento = e.row.data.impacto_incumplimiento;
            this.frm_v_registroemision_cumplimiento = e.row.data.registroemision_cumplimiento;
            this.frm_v_registroemision_incumplimiento = e.row.data.registroemision_incumplimiento;
            this.frm_v_permisos_cumplimiento = e.row.data.permisos_cumplimiento;
            this.frm_v_permisos_incumplimiento = e.row.data.permisos_incumplimiento;
            this.frm_v_administracion_cumplimiento = e.row.data.administracion_cumplimiento;
            this.frm_v_administracion_incumplimiento = e.row.data.administracion_incumplimiento;
            this.frm_v_otras_cumplimiento = e.row.data.otras_cumplimiento;
            this.frm_v_otras_incumplimiento = e.row.data.otras_incumplimiento;

            this.frm_v_agua_noaplica  = e.row.data.agua_noaplica;
            this.frm_v_suelo_noaplica  = e.row.data.suelo_noaplica;
            this.frm_v_ruido_noaplica  = e.row.data.ruido_noaplica;
            this.frm_v_aire_noaplica  = e.row.data.aire_noaplica;
            this.frm_v_residuos_noaplica  = e.row.data.residuos_noaplica;
            this.frm_v_riesgo_noaplica  = e.row.data.riesgo_noaplica;
            this.frm_v_impacto_noaplica  = e.row.data.impacto_noaplica;
            this.frm_v_registroemision_noaplica  = e.row.data.registroemision_noaplica;
            this.frm_v_permisos_noaplica  = e.row.data.permisos_noaplica;
            this.frm_v_administracionambiental_noaplica = e.row.data.administracionambiental_noaplica;
            this.frm_v_otras_noaplica  = e.row.data.otras_noaplica;

            this.generatePDF();
        },

        ObtieneReporte() {
             
            let err = 0;
       

            if (err == 0 && this.frm_periodo === null || this.frm_periodo === 0 || this.frm_periodo === 'undefined') {
                this.type = 'error';
                this.message = 'Capture el Periodo';
                this.isVisible = true;
                notify({
                    message: 'Ocurrio un error',
                }, this.type, 4000);
                err = 1;
            }


            if (err == 0) {


                let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
                let idcliente = JSON.parse(Cookies.get("ets_user_ec")).clienteid;//1;
                console.log('CLIENTE COOKIE',idcliente);
                let myJSON = JSON.stringify({
                    pusuarioid: idusuario,
                    pperiodoid: this.frm_periodo,
                    pfechainicio: '2024-01-01',
                    pfechafin: '2024-01-01',
                    pclienteid: idcliente,
                });

                this.reportesService.getReporteECA(myJSON).then(data => {

                    this.dataSourceCompany = data;

                    // Generacion de graficas
                    const CHART_COLORS = {
                            green: 'rgb(96, 178, 13)',
                            blue: 'rgb(51, 141, 255)'
                        };

                        var ctxchart = document.getElementById("graficas");
                
                        this.dataSourceCompany.forEach((element) => {
                            
                            // NORMAL GENERAL
                            var valuesChart = JSON.parse(element.cumplimiento_json);
                            var labelsChart = [];
                            var dataChart = [];
                            valuesChart.forEach(label => {
                                labelsChart.push(label.texto);
                                dataChart.push((label.valor));
                            });

                            var idChart = "myChart" + "_normal_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                            var ctx = document.createElement("canvas");
                            ctx.setAttribute("id", idChart);
                            new Chart(ctx, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart,
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#36A2EB'
                                        },
                                    }
                                }
                            });
                            ctxchart.appendChild(ctx);
                        
                            // AGUA
                            var valuesChart_agua = JSON.parse(element.agua_json);
                            var labelsChart_agua = [];
                            var dataChart_agua = [];
                            valuesChart_agua.forEach(label => {
                                labelsChart_agua.push(label.texto);
                                dataChart_agua.push((label.valor));
                            });

                            var idChart_agua = "myChart" + "_agua_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                            var ctx_agua = document.createElement("canvas");
                            ctx_agua.setAttribute("id", idChart_agua);
                            new Chart(ctx_agua, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart_agua,
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart_agua,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#36A2EB'
                                        },
                                    }
                                }
                            });
                            ctxchart.appendChild(ctx_agua);



                            // AIRE
                            var valuesChart_aire = JSON.parse(element.aire_json);
                            var labelsChart_aire = [];
                            var dataChart_aire = [];
                            valuesChart_aire.forEach(label => {
                                labelsChart_aire.push(label.texto);
                                dataChart_aire.push((label.valor));
                            });

                            var idChart_aire = "myChart" + "_aire_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                            var ctx_aire = document.createElement("canvas");
                            ctx_aire.setAttribute("id", idChart_aire);
                            new Chart(ctx_aire, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart_aire,
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart_aire,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#36A2EB'
                                        },
                                    }
                                }
                            });
                            ctxchart.appendChild(ctx_aire);

                            // IMPACTO
                            var valuesChart_impacto = JSON.parse(element.impacto_json);
                            var labelsChart_impacto = [];
                            var dataChart_impacto = [];
                            valuesChart_impacto.forEach(label => {
                                labelsChart_impacto.push(label.texto);
                                dataChart_impacto.push((label.valor));
                            });

                            var idChart_impacto = "myChart" + "_impacto_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                            var ctx_impacto = document.createElement("canvas");
                            ctx_impacto.setAttribute("id", idChart_impacto);
                            new Chart(ctx_impacto, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart_impacto,
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart_impacto,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#36A2EB'
                                        },
                                    }
                                }
                            });
                            ctxchart.appendChild(ctx_impacto);


                            // ADMINISTRACION 
                            var valuesChart_administracion = JSON.parse(element.administracion_json);
                            var labelsChart_administracion = [];
                            var dataChart_administracion = [];
                            valuesChart_administracion.forEach(label => {
                                labelsChart_administracion.push(label.texto);
                                dataChart_administracion.push((label.valor));
                            });

                            var idChart_administracion = "myChart" + "_administracion_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                            var ctx_administracion = document.createElement("canvas");
                            ctx_administracion.setAttribute("id", idChart_administracion);
                            new Chart(ctx_administracion, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart_administracion,
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart_administracion,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#36A2EB'
                                        },
                                    }
                                }
                            });
                            ctxchart.appendChild(ctx_administracion);


                            
                            // PERMISOS 
                            var valuesChart_permisos = JSON.parse(element.permisos_json);
                            var labelsChart_permisos = [];
                            var dataChart_permisos = [];
                            valuesChart_permisos.forEach(label => {
                                labelsChart_permisos.push(label.texto);
                                dataChart_permisos.push((label.valor));
                            });

                            var idChart_permisos = "myChart" + "_permisos_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                            var ctx_permisos = document.createElement("canvas");
                            ctx_permisos.setAttribute("id", idChart_permisos);
                            new Chart(ctx_permisos, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart_permisos,
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart_permisos,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#36A2EB'
                                        },
                                    }
                                }
                            });
                            ctxchart.appendChild(ctx_permisos);


                            // REGISTRO EMISION 
                            var valuesChart_registroemision = JSON.parse(element.registroemision_json);
                            var labelsChart_registroemision = [];
                            var dataChart_registroemision = [];
                            valuesChart_registroemision.forEach(label => {
                                labelsChart_registroemision.push(label.texto);
                                dataChart_registroemision.push((label.valor));
                            });

                            var idChart_registroemision = "myChart" + "_registroemision_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                            var ctx_registroemision = document.createElement("canvas");
                            ctx_registroemision.setAttribute("id", idChart_registroemision);
                            new Chart(ctx_registroemision, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart_registroemision,
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart_registroemision,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#36A2EB'
                                        },
                                    }
                                }
                            });
                            ctxchart.appendChild(ctx_registroemision);


                            // RESIDUOS
                            var valuesChart_residuos = JSON.parse(element.residuos_json);
                            var labelsChart_residuos = [];
                            var dataChart_residuos = [];
                            valuesChart_residuos.forEach(label => {
                                labelsChart_residuos.push(label.texto);
                                dataChart_residuos.push((label.valor));
                            });

                            var idChart_residuos = "myChart" + "_residuos_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                            var ctx_residuos = document.createElement("canvas");
                            ctx_residuos.setAttribute("id", idChart_residuos);
                            new Chart(ctx_residuos, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart_residuos,
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart_residuos,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#36A2EB'
                                        },
                                    }
                                }
                            });
                            ctxchart.appendChild(ctx_residuos);

                            // RIESGO
                            var valuesChart_riesgo = JSON.parse(element.riesgo_json);
                            var labelsChart_riesgo = [];
                            var dataChart_riesgo = [];
                            valuesChart_riesgo.forEach(label => {
                                labelsChart_riesgo.push(label.texto);
                                dataChart_riesgo.push((label.valor));
                            });

                            var idChart_riesgo = "myChart" + "_riesgo_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                            var ctx_riesgo = document.createElement("canvas");
                            ctx_riesgo.setAttribute("id", idChart_riesgo);
                            new Chart(ctx_riesgo, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart_riesgo,
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart_riesgo,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#36A2EB'
                                        },
                                    }
                                }
                            });
                            ctxchart.appendChild(ctx_riesgo);



                            // ruido
                            var valuesChart_ruido = JSON.parse(element.ruido_json);
                            var labelsChart_ruido = [];
                            var dataChart_ruido = [];
                            valuesChart_ruido.forEach(label => {
                                labelsChart_ruido.push(label.texto);
                                dataChart_ruido.push((label.valor));
                            });

                            var idChart_ruido = "myChart" + "_ruido_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                            var ctx_ruido = document.createElement("canvas");
                            ctx_ruido.setAttribute("id", idChart_ruido);
                            new Chart(ctx_ruido, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart_ruido,
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart_ruido,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#36A2EB'
                                        },
                                    }
                                }
                            });
                            ctxchart.appendChild(ctx_ruido);


                            // suelo
                            var valuesChart_suelo = JSON.parse(element.suelo_json);
                            var labelsChart_suelo = [];
                            var dataChart_suelo = [];
                            valuesChart_suelo.forEach(label => {
                                labelsChart_suelo.push(label.texto);
                                dataChart_suelo.push((label.valor));
                            });

                            var idChart_suelo = "myChart" + "_suelo_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                            var ctx_suelo = document.createElement("canvas");
                            ctx_suelo.setAttribute("id", idChart_suelo);
                            new Chart(ctx_suelo, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart_suelo,
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart_suelo,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#36A2EB'
                                        },
                                    }
                                }
                            });
                            ctxchart.appendChild(ctx_suelo);

                            // otras
                            var valuesChart_otras = JSON.parse(element.otras_json);
                            var labelsChart_otras = [];
                            var dataChart_otras = [];
                            valuesChart_otras.forEach(label => {
                                labelsChart_otras.push(label.texto);
                                dataChart_otras.push((label.valor));
                            });

                            var idChart_otras = "myChart" + "_otras_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                            var ctx_otras = document.createElement("canvas");
                            ctx_otras.setAttribute("id", idChart_otras);
                            new Chart(ctx_otras, {
                                type: 'pie',
                                data: {
                                    labels: labelsChart_otras,
                                    datasets: [{
                                        label: '# of Tomatoes',
                                        data: dataChart_otras,
                                        backgroundColor: Object.values(CHART_COLORS),
                                        // borderWidth: 1
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            color: '#36A2EB'
                                        },
                                    }
                                }
                            });
                            ctxchart.appendChild(ctx_otras);


                        });

                });
            }
        },

       
        generategrap() {
            const CHART_COLORS = {
                green: 'rgb(96, 178, 13)',
                blue: 'rgb(51, 141, 255)'
            };

            var ctxchart = document.getElementById("graficas");
     
            this.dataSourceCompany.forEach((element) => {
                
                // NORMAL GENERAL
                var valuesChart = JSON.parse(element.cumplimiento_json);
                var labelsChart = [];
                var dataChart = [];
                valuesChart.forEach(label => {
                    labelsChart.push(label.texto);
                    dataChart.push((label.valor));
                });

                var idChart = "myChart" + "_normal_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                var ctx = document.createElement("canvas");
                ctx.setAttribute("id", idChart);
                new Chart(ctx, {
                    type: 'pie',
                    data: {
                        labels: labelsChart,
                        datasets: [{
                            label: '# of Tomatoes',
                            data: dataChart,
                            backgroundColor: Object.values(CHART_COLORS),
                            // borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#36A2EB'
                            },
                        }
                    }
                });
                ctxchart.appendChild(ctx);
              
                // AGUA
                var valuesChart_agua = JSON.parse(element.agua_json);
                var labelsChart_agua = [];
                var dataChart_agua = [];
                valuesChart_agua.forEach(label => {
                    labelsChart_agua.push(label.texto);
                    dataChart_agua.push((label.valor));
                });

                var idChart_agua = "myChart" + "_agua_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                var ctx_agua = document.createElement("canvas");
                ctx_agua.setAttribute("id", idChart_agua);
                new Chart(ctx_agua, {
                    type: 'pie',
                    data: {
                        labels: labelsChart_agua,
                        datasets: [{
                            label: '# of Tomatoes',
                            data: dataChart_agua,
                            backgroundColor: Object.values(CHART_COLORS),
                            // borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#36A2EB'
                            },
                        }
                    }
                });
                ctxchart.appendChild(ctx_agua);



                // AIRE
                var valuesChart_aire = JSON.parse(element.aire_json);
                var labelsChart_aire = [];
                var dataChart_aire = [];
                valuesChart_aire.forEach(label => {
                    labelsChart_aire.push(label.texto);
                    dataChart_aire.push((label.valor));
                });

                var idChart_aire = "myChart" + "_aire_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                var ctx_aire = document.createElement("canvas");
                ctx_aire.setAttribute("id", idChart_aire);
                new Chart(ctx_aire, {
                    type: 'pie',
                    data: {
                        labels: labelsChart_aire,
                        datasets: [{
                            label: '# of Tomatoes',
                            data: dataChart_aire,
                            backgroundColor: Object.values(CHART_COLORS),
                            // borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#36A2EB'
                            },
                        }
                    }
                });
                ctxchart.appendChild(ctx_aire);

                 // IMPACTO
                 var valuesChart_impacto = JSON.parse(element.impacto_json);
                var labelsChart_impacto = [];
                var dataChart_impacto = [];
                valuesChart_impacto.forEach(label => {
                    labelsChart_impacto.push(label.texto);
                    dataChart_impacto.push((label.valor));
                });

                var idChart_impacto = "myChart" + "_impacto_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                var ctx_impacto = document.createElement("canvas");
                ctx_impacto.setAttribute("id", idChart_impacto);
                new Chart(ctx_impacto, {
                    type: 'pie',
                    data: {
                        labels: labelsChart_impacto,
                        datasets: [{
                            label: '# of Tomatoes',
                            data: dataChart_impacto,
                            backgroundColor: Object.values(CHART_COLORS),
                            // borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#36A2EB'
                            },
                        }
                    }
                });
                ctxchart.appendChild(ctx_impacto);


                 // ADMINISTRACION 
                 var valuesChart_administracion = JSON.parse(element.administracion_json);
                var labelsChart_administracion = [];
                var dataChart_administracion = [];
                valuesChart_administracion.forEach(label => {
                    labelsChart_administracion.push(label.texto);
                    dataChart_administracion.push((label.valor));
                });

                var idChart_administracion = "myChart" + "_administracion_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                var ctx_administracion = document.createElement("canvas");
                ctx_administracion.setAttribute("id", idChart_administracion);
                new Chart(ctx_administracion, {
                    type: 'pie',
                    data: {
                        labels: labelsChart_administracion,
                        datasets: [{
                            label: '# of Tomatoes',
                            data: dataChart_administracion,
                            backgroundColor: Object.values(CHART_COLORS),
                            // borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#36A2EB'
                            },
                        }
                    }
                });
                ctxchart.appendChild(ctx_administracion);


                
                 // PERMISOS 
                 var valuesChart_permisos = JSON.parse(element.permisos_json);
                var labelsChart_permisos = [];
                var dataChart_permisos = [];
                valuesChart_permisos.forEach(label => {
                    labelsChart_permisos.push(label.texto);
                    dataChart_permisos.push((label.valor));
                });

                var idChart_permisos = "myChart" + "_permisos_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                var ctx_permisos = document.createElement("canvas");
                ctx_permisos.setAttribute("id", idChart_permisos);
                new Chart(ctx_permisos, {
                    type: 'pie',
                    data: {
                        labels: labelsChart_permisos,
                        datasets: [{
                            label: '# of Tomatoes',
                            data: dataChart_permisos,
                            backgroundColor: Object.values(CHART_COLORS),
                            // borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#36A2EB'
                            },
                        }
                    }
                });
                ctxchart.appendChild(ctx_permisos);


                  // REGISTRO EMISION 
                  var valuesChart_registroemision = JSON.parse(element.registroemision_json);
                var labelsChart_registroemision = [];
                var dataChart_registroemision = [];
                valuesChart_registroemision.forEach(label => {
                    labelsChart_registroemision.push(label.texto);
                    dataChart_registroemision.push((label.valor));
                });

                var idChart_registroemision = "myChart" + "_registroemision_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                var ctx_registroemision = document.createElement("canvas");
                ctx_registroemision.setAttribute("id", idChart_registroemision);
                new Chart(ctx_registroemision, {
                    type: 'pie',
                    data: {
                        labels: labelsChart_registroemision,
                        datasets: [{
                            label: '# of Tomatoes',
                            data: dataChart_registroemision,
                            backgroundColor: Object.values(CHART_COLORS),
                            // borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#36A2EB'
                            },
                        }
                    }
                });
                ctxchart.appendChild(ctx_registroemision);


                // RESIDUOS
                var valuesChart_residuos = JSON.parse(element.residuos_json);
                var labelsChart_residuos = [];
                var dataChart_residuos = [];
                valuesChart_residuos.forEach(label => {
                    labelsChart_residuos.push(label.texto);
                    dataChart_residuos.push((label.valor));
                });

                var idChart_residuos = "myChart" + "_residuos_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                var ctx_residuos = document.createElement("canvas");
                ctx_residuos.setAttribute("id", idChart_residuos);
                new Chart(ctx_residuos, {
                    type: 'pie',
                    data: {
                        labels: labelsChart_residuos,
                        datasets: [{
                            label: '# of Tomatoes',
                            data: dataChart_residuos,
                            backgroundColor: Object.values(CHART_COLORS),
                            // borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#36A2EB'
                            },
                        }
                    }
                });
                ctxchart.appendChild(ctx_residuos);

                  // RIESGO
                  var valuesChart_riesgo = JSON.parse(element.riesgo_json);
                var labelsChart_riesgo = [];
                var dataChart_riesgo = [];
                valuesChart_riesgo.forEach(label => {
                    labelsChart_riesgo.push(label.texto);
                    dataChart_riesgo.push((label.valor));
                });

                var idChart_riesgo = "myChart" + "_riesgo_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                var ctx_riesgo = document.createElement("canvas");
                ctx_riesgo.setAttribute("id", idChart_riesgo);
                new Chart(ctx_riesgo, {
                    type: 'pie',
                    data: {
                        labels: labelsChart_riesgo,
                        datasets: [{
                            label: '# of Tomatoes',
                            data: dataChart_riesgo,
                            backgroundColor: Object.values(CHART_COLORS),
                            // borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#36A2EB'
                            },
                        }
                    }
                });
                ctxchart.appendChild(ctx_riesgo);



                   // ruido
                   var valuesChart_ruido = JSON.parse(element.ruido_json);
                var labelsChart_ruido = [];
                var dataChart_ruido = [];
                valuesChart_ruido.forEach(label => {
                    labelsChart_ruido.push(label.texto);
                    dataChart_ruido.push((label.valor));
                });

                var idChart_ruido = "myChart" + "_ruido_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                var ctx_ruido = document.createElement("canvas");
                ctx_ruido.setAttribute("id", idChart_ruido);
                new Chart(ctx_ruido, {
                    type: 'pie',
                    data: {
                        labels: labelsChart_ruido,
                        datasets: [{
                            label: '# of Tomatoes',
                            data: dataChart_ruido,
                            backgroundColor: Object.values(CHART_COLORS),
                            // borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#36A2EB'
                            },
                        }
                    }
                });
                ctxchart.appendChild(ctx_ruido);


                  // suelo
                  var valuesChart_suelo = JSON.parse(element.suelo_json);
                var labelsChart_suelo = [];
                var dataChart_suelo = [];
                valuesChart_suelo.forEach(label => {
                    labelsChart_suelo.push(label.texto);
                    dataChart_suelo.push((label.valor));
                });

                var idChart_suelo = "myChart" + "_suelo_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                var ctx_suelo = document.createElement("canvas");
                ctx_suelo.setAttribute("id", idChart_suelo);
                new Chart(ctx_suelo, {
                    type: 'pie',
                    data: {
                        labels: labelsChart_suelo,
                        datasets: [{
                            label: '# of Tomatoes',
                            data: dataChart_suelo,
                            backgroundColor: Object.values(CHART_COLORS),
                            // borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#36A2EB'
                            },
                        }
                    }
                });
                ctxchart.appendChild(ctx_suelo);

                  // otras
                  var valuesChart_otras = JSON.parse(element.otras_json);
                var labelsChart_otras = [];
                var dataChart_otras = [];
                valuesChart_otras.forEach(label => {
                    labelsChart_otras.push(label.texto);
                    dataChart_otras.push((label.valor));
                });

                var idChart_otras = "myChart" + "_otras_" + element.id.toString()+"_"+element.ordentrabajoid.toString();
                var ctx_otras = document.createElement("canvas");
                ctx_otras.setAttribute("id", idChart_otras);
                new Chart(ctx_otras, {
                    type: 'pie',
                    data: {
                        labels: labelsChart_otras,
                        datasets: [{
                            label: '# of Tomatoes',
                            data: dataChart_otras,
                            backgroundColor: Object.values(CHART_COLORS),
                            // borderWidth: 1
                        }]
                    },
                    options: {
                        responsive: true,
                        plugins: {
                            datalabels: {
                                display: true,
                                color: '#36A2EB'
                            },
                        }
                    }
                });
                ctxchart.appendChild(ctx_otras);


            });


        },
        
        exportarTodo() {
            this.generategrap();

            setTimeout(() => {
                this.generatePDF();
            }, 2500);
        },
        generatePDF() {
            
            // TRABAJAR EXPORT PDF
            const doc = new jsPDF('portrait', 'mm', 'a4');

            //Centrar el texto
            let url = 'https://www.lmeraki.com/';
            var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();


            let inicio = 32;
            let imgx = 75;
            doc.setFontSize(9);
            var ctxchart = document.getElementById("graficas");


             
             if(this.ordentrabajoreporte==''){

                        this.dataSourceCompany.forEach(
                            async (dataR, index) => {
                                var img = new Image();
                                img.src = require("../assets/logo.png");
                                doc.addImage(img, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');
                                doc.text("REPORTE GENERAL", pageWidth / 2, 25, { align: "center" });

                                var idChart = "myChart" + "_normal_" + dataR.id.toString()+"_"+ dataR.ordentrabajoid.toString();

                                inicio = inicio + 15;
                                doc.setFontSize(6);
                                doc.rect(10, 38, 185, 10);
                                doc.rect(70, 38, 65, 10);
                                //doc.text("Razón Social:", 12, 42, { align: "left" });
                                // doc.text(dataR.cliente, 12, 42, { align: "left" });
                                var splitTitleCliente = doc.splitTextToSize(dataR.cliente, 50);
                                doc.text(splitTitleCliente, 12, 42, { align: "left" });


                                //doc.text(dataR.ot + "-" + dataR.servicio, 75, 42, { align: "left" });
                                var splitTitleOT = doc.splitTextToSize(dataR.ot + "-" + dataR.servicio, 50);
                                doc.text(splitTitleOT, 75, 42, { align: "left" });

                                doc.text("Fecha Elaboración:", 138, 42, { align: "left" });
                                doc.text(dataR.fechaelaboracion, 138, 46, { align: "left" });

                                inicio = inicio + 10;
                            

                                doc.rect(10, 50, 185, 7);
                                doc.text("NIVEL DE CUMPLIMIENTO AMBIENTAL", 15, 54, { align: "left" });

                                
                                        if(dataR.cumplimiento === 0 && dataR.incumplimiento === 0){
                                            doc.setFontSize(10);
                                            doc.text("PENDIENTE", 15, 70,{ align: "left" });
                                        } else {
                                            const canvas = document.getElementById(idChart);
                                            const base64graph = canvas.toDataURL();

                                            var imgpie = new Image();
                                            imgpie.src = base64graph;
                                            doc.addImage(imgpie, "PNG", 10, imgx, 200, 100);

                                            var cumplimiento = "Cumplimiento : " + dataR.cumplimiento + "%";
                                            var incumplimiento = "Incumplimiento : " + dataR.incumplimiento + "%";
                                            doc.setFontSize(10);
                                            doc.text(cumplimiento, 30, inicio+140, { align: "left"});
                                            doc.text(incumplimiento, 160, inicio+140, { align: "left"});
                                        }
                                   

                                doc.setFontSize(8);


                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;

                                //////////// AGUA
                                var img_agua = new Image();
                                img_agua.src = require("../assets/logo.png");
                                doc.addImage(img_agua, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                var idChart_agua = "myChart" + "_agua_" + dataR.id.toString()+"_"+ dataR.ordentrabajoid.toString();
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: AGUA", 15, 42, { align: "left" });

                                

                                if(dataR.agua_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });
                                    inicio = inicio + 40
                                }
                                else{
                                        if(dataR.agua_cumplimiento === 0 && dataR.agua_incumplimiento === 0){
                                            doc.setFontSize(10);
                                            doc.text("PENDIENTE", 15, 60,{ align: "left" });
                                            inicio = inicio + 40
                                        } else {
                                            const canvas_agua = document.getElementById(idChart_agua);
                                            const base64graph_agua = canvas_agua.toDataURL();

                                            var imgpie_agua = new Image();
                                            imgpie_agua.src = base64graph_agua;
                                            doc.addImage(imgpie_agua, "PNG", 10, imgx-15, 200, 100);

                                            var cumplimiento_agua = "Cumplimiento : " + dataR.agua_cumplimiento + "%";
                                            var incumplimiento_agua = "Incumplimiento : " + dataR.agua_incumplimiento + "%";
                                            doc.setFontSize(10);
                                            doc.text(cumplimiento_agua, 30, inicio+130, { align: "left"});
                                            doc.text(incumplimiento_agua, 160, inicio+130, { align: "left"});
                                            inicio = inicio + 150;
                                        }
                                    }

                                doc.setFontSize(8);
                                

                                
                                imgx = imgx + 105;
            
                        
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (dataR.agua_json_aut != null) {
                                    var valuesJsonPendiente = JSON.parse(dataR.agua_json_aut);
                                valuesJsonPendiente.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });

                                if (dataR.agua_json_inc != null) {
                                    var valuesJsonInterno = JSON.parse(dataR.agua_json_inc);
                                    
                                    
                                    valuesJsonInterno.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 185);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }

                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;

                                //////////// AIRE
                                var img_aire = new Image();
                                img_aire.src = require("../assets/logo.png");
                                doc.addImage(img_aire, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_aire = "myChart" + "_aire_" + dataR.id.toString()+"_"+ dataR.ordentrabajoid.toString();
                                
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: AIRE", 15, 42, { align: "left" });


                                if(dataR.aire_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                }
                                else{
                                if(dataR.aire_cumplimiento === 0 && dataR.aire_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {

                                    const canvas_aire = document.getElementById(idChart_aire);
                                    const base64graph_aire = canvas_aire.toDataURL();

                                    var imgpie_aire= new Image();
                                    imgpie_aire.src = base64graph_aire;
                                    doc.addImage(imgpie_aire, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_aire = "Cumplimiento : " + dataR.aire_cumplimiento + "%";
                                    var incumplimiento_aire = "Incumplimiento : " + dataR.aire_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_aire, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_aire, 160, inicio+130, { align: "left"});

                                    inicio = inicio + 140;

                                }
                            }
                                doc.setFontSize(8);

                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (dataR.aire_json_aut != null) {
                                    var valuesJsonPendiente_aire = JSON.parse(dataR.aire_json_aut);
                                    valuesJsonPendiente_aire.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (dataR.aire_json_inc != null) {
                                    var valuesJsonInterno_aire = JSON.parse(dataR.aire_json_inc);
                                    
                                    valuesJsonInterno_aire.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }

                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;

                                //////////// IMPACTO
                                var img_impacto = new Image();
                                img_impacto.src = require("../assets/logo.png");
                                doc.addImage(img_impacto, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_impacto = "myChart" + "_impacto_" + dataR.id.toString()+"_"+ dataR.ordentrabajoid.toString();
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: IMPACTO AMBIENTAL", 15, 42, { align: "left" });


                                if(dataR.impacto_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                }
                                else{
                                    if(dataR.impacto_cumplimiento === 0 && dataR.impacto_incumplimiento === 0){
                                        doc.setFontSize(10);
                                        doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                        inicio = inicio + 30
                                    } else {
                                        const canvas_impacto = document.getElementById(idChart_impacto);
                                        const base64graph_impacto = canvas_impacto.toDataURL();

                                        var imgpie_impacto= new Image();
                                        imgpie_impacto.src = base64graph_impacto;
                                        doc.addImage(imgpie_impacto, "PNG", 10, imgx-15, 200, 100);

                                        var cumplimiento_impacto = "Cumplimiento : " + dataR.impacto_cumplimiento + "%";
                                        var incumplimiento_impacto = "Incumplimiento : " + dataR.impacto_incumplimiento + "%";
                                        doc.setFontSize(10);
                                        doc.text(cumplimiento_impacto, 30, inicio+130, { align: "left"});
                                        doc.text(incumplimiento_impacto, 160, inicio+130, { align: "left"});
                                        
                                        inicio = inicio + 140;
                                    }
                                }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (dataR.impacto_json_aut != null) {
                                    var valuesJsonPendiente_impacto = JSON.parse(dataR.impacto_json_aut);
                                    valuesJsonPendiente_impacto.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (dataR.impacto_json_inc != null) {
                                    var valuesJsonInterno_impacto = JSON.parse(dataR.impacto_json_inc);
                                    
                                    valuesJsonInterno_impacto.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;

                                //////////// ADMINISTRACION 
                                var img_administracion = new Image();
                                img_administracion.src = require("../assets/logo.png");
                                doc.addImage(img_administracion, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_administracion = "myChart" + "_administracion_" + dataR.id.toString()+"_"+ dataR.ordentrabajoid.toString();
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: ADMINISTRACION AMBIENTAL", 15, 42, { align: "left" });

                                if(dataR.administracion_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                }
                                else{
                                if(dataR.administracion_cumplimiento === 0 && dataR.administracion_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_administracion = document.getElementById(idChart_administracion);
                                    const base64graph_administracion = canvas_administracion.toDataURL();

                                    var imgpie_administracion= new Image();
                                    imgpie_administracion.src = base64graph_administracion;
                                    doc.addImage(imgpie_administracion, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_administracion = "Cumplimiento : " + dataR.administracion_cumplimiento + "%";
                                    var incumplimiento_administracion = "Incumplimiento : " + dataR.administracion_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_administracion, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_administracion, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (dataR.administracion_json_aut != null) {
                                    var valuesJsonPendiente_administracion = JSON.parse(dataR.administracion_json_aut);
                                    valuesJsonPendiente_administracion.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (dataR.administracion_json_inc != null) {
                                    var valuesJsonInterno_administracion = JSON.parse(dataR.administracion_json_inc);
                                    
                                    valuesJsonInterno_administracion.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;



                                //////////// PERMISOS 
                                var img_permisos = new Image();
                                img_permisos.src = require("../assets/logo.png");
                                doc.addImage(img_permisos, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_permisos = "myChart" + "_permisos_" + dataR.id.toString()+"_"+ dataR.ordentrabajoid.toString();
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: PERMISOS", 15, 42, { align: "left" });

                                if(dataR.permisos_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                }
                                else{
                                if(dataR.permisos_cumplimiento === 0 && dataR.permisos_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_permisos = document.getElementById(idChart_permisos);
                                    const base64graph_permisos = canvas_permisos.toDataURL();

                                    var imgpie_permisos= new Image();
                                    imgpie_permisos.src = base64graph_permisos;
                                    doc.addImage(imgpie_permisos, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_permisos = "Cumplimiento : " + dataR.permisos_cumplimiento + "%";
                                    var incumplimiento_permisos = "Incumplimiento : " + dataR.permisos_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_permisos, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_permisos, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (dataR.permisos_json_aut != null) {
                                    var valuesJsonPendiente_permisos = JSON.parse(dataR.permisos_json_aut);
                                    valuesJsonPendiente_permisos.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (dataR.permisos_json_inc != null) {
                                    var valuesJsonInterno_permisos = JSON.parse(dataR.permisos_json_inc);
                                    
                                    valuesJsonInterno_permisos.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;




                                //////////// REGISTRO EMISION 
                                var img_registroemision = new Image();
                                img_registroemision.src = require("../assets/logo.png");
                                doc.addImage(img_registroemision, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_registroemision = "myChart" + "_registroemision_" + dataR.id.toString()+"_"+ dataR.ordentrabajoid.toString();
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: REGISTRO EMISIONES", 15, 42, { align: "left" });
                               
                                if(dataR.registroemision_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                }
                                else{
                                    if(dataR.registroemision_cumplimiento === 0 && dataR.registroemision_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_registroemision = document.getElementById(idChart_registroemision);
                                    const base64graph_registroemision = canvas_registroemision.toDataURL();

                                    var imgpie_registroemision= new Image();
                                    imgpie_registroemision.src = base64graph_registroemision;
                                    doc.addImage(imgpie_registroemision, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_registroemision = "Cumplimiento : " + dataR.registroemision_cumplimiento + "%";
                                    var incumplimiento_registroemision = "Incumplimiento : " + dataR.registroemision_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_registroemision, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_registroemision, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                                }
                               
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (dataR.registroemision_json_aut != null) {
                                    var valuesJsonPendiente_registroemision = JSON.parse(dataR.registroemision_json_aut);
                                    valuesJsonPendiente_registroemision.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (dataR.registroemision_json_inc != null) {
                                    var valuesJsonInterno_registroemision = JSON.parse(dataR.registroemision_json_inc);
                                    
                                    valuesJsonInterno_registroemision.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;


                                //////////// RESIDUOS
                                var img_residuos = new Image();
                                img_residuos.src = require("../assets/logo.png");
                                doc.addImage(img_residuos, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_residuos = "myChart" + "_residuos_" + dataR.id.toString()+"_"+ dataR.ordentrabajoid.toString();
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: RESIDUOS", 15, 42, { align: "left" });

                                if(dataR.residuos_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                }
                                else{
                                if(dataR.residuos_cumplimiento === 0 && dataR.residuos_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_residuos = document.getElementById(idChart_residuos);
                                    const base64graph_residuos = canvas_residuos.toDataURL();

                                    var imgpie_residuos= new Image();
                                    imgpie_residuos.src = base64graph_residuos;
                                    doc.addImage(imgpie_residuos, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_residuos = "Cumplimiento : " + dataR.residuos_cumplimiento + "%";
                                    var incumplimiento_residuos = "Incumplimiento : " + dataR.residuos_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_residuos, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_residuos, 160, inicio+130, { align: "left"});

                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (dataR.residuos_json_aut != null) {
                                    var valuesJsonPendiente_residuos = JSON.parse(dataR.residuos_json_aut);
                                    valuesJsonPendiente_residuos.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (dataR.residuos_json_inc != null) {
                                    var valuesJsonInterno_residuos = JSON.parse(dataR.residuos_json_inc);
                                    
                                    valuesJsonInterno_residuos.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;



                                //////////// RIESGO
                                var img_riesgo = new Image();
                                img_riesgo.src = require("../assets/logo.png");
                                doc.addImage(img_riesgo, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_riesgo = "myChart" + "_riesgo_" + dataR.id.toString()+"_"+ dataR.ordentrabajoid.toString();
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: RIESGOS AMBIENTALES", 15, 42, { align: "left" });

                                if(dataR.riesgo_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                }
                                else{
                                if(dataR.riesgo_cumplimiento === 0 && dataR.riesgo_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_riesgo = document.getElementById(idChart_riesgo);
                                    const base64graph_riesgo = canvas_riesgo.toDataURL();

                                    var imgpie_riesgo= new Image();
                                    imgpie_riesgo.src = base64graph_riesgo;
                                    doc.addImage(imgpie_riesgo, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_riesgo = "Cumplimiento : " + dataR.riesgo_cumplimiento + "%";
                                    var incumplimiento_riesgo = "Incumplimiento : " + dataR.riesgo_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_riesgo, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_riesgo, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (dataR.riesgo_json_aut != null) {
                                    var valuesJsonPendiente_riesgo = JSON.parse(dataR.riesgo_json_aut);
                                    valuesJsonPendiente_riesgo.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (dataR.riesgo_json_inc != null) {
                                    var valuesJsonInterno_riesgo = JSON.parse(dataR.riesgo_json_inc);
                                    
                                    valuesJsonInterno_riesgo.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;


                                //////////// RUIDO
                                var img_ruido = new Image();
                                img_ruido.src = require("../assets/logo.png");
                                doc.addImage(img_ruido, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_ruido = "myChart" + "_ruido_" + dataR.id.toString()+"_"+ dataR.ordentrabajoid.toString();
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: RUIDO", 15, 42, { align: "left" });

                                if(dataR.ruido_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                }
                                else{
                                if(dataR.ruido_cumplimiento === 0 && dataR.ruido_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_ruido = document.getElementById(idChart_ruido);
                                    const base64graph_ruido = canvas_ruido.toDataURL();

                                    var imgpie_ruido= new Image();
                                    imgpie_ruido.src = base64graph_ruido;
                                    doc.addImage(imgpie_ruido, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_ruido = "Cumplimiento : " + dataR.ruido_cumplimiento + "%";
                                    var incumplimiento_ruido= "Incumplimiento : " + dataR.ruido_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_ruido, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_ruido, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (dataR.ruido_json_aut != null) {
                                    var valuesJsonPendiente_ruido = JSON.parse(dataR.ruido_json_aut);
                                    valuesJsonPendiente_ruido.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (dataR.ruido_json_inc != null) {
                                    var valuesJsonInterno_ruido = JSON.parse(dataR.ruido_json_inc);
                                    
                                    valuesJsonInterno_ruido.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;


                                //////////// SUELO
                                var img_suelo = new Image();
                                img_suelo.src = require("../assets/logo.png");
                                doc.addImage(img_suelo, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_suelo = "myChart" + "_suelo_" + dataR.id.toString()+"_"+ dataR.ordentrabajoid.toString();
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: SUELO Y SUBSUELO", 15, 42, { align: "left" });

                                if(dataR.suelo_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                }
                                else{
                                if(dataR.suelo_cumplimiento === 0 && dataR.suelo_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_suelo = document.getElementById(idChart_suelo);
                                    const base64graph_suelo = canvas_suelo.toDataURL();

                                    var imgpie_suelo= new Image();
                                    imgpie_suelo.src = base64graph_suelo;
                                    doc.addImage(imgpie_suelo, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_suelo = "Cumplimiento : " + dataR.suelo_cumplimiento + "%";
                                    var incumplimiento_suelo= "Incumplimiento : " + dataR.suelo_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_suelo, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_suelo, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (dataR.suelo_json_aut != null) {
                                    var valuesJsonPendiente_suelo = JSON.parse(dataR.suelo_json_aut);
                                    valuesJsonPendiente_suelo.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (dataR.suelo_json_inc != null) {
                                    var valuesJsonInterno_suelo = JSON.parse(dataR.suelo_json_inc);
                                    
                                    valuesJsonInterno_suelo.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;



                                //////////// OTRAS
                                var img_otras = new Image();
                                img_otras.src = require("../assets/logo.png");
                                doc.addImage(img_otras, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_otras = "myChart" + "_otras_" + dataR.id.toString()+"_"+ dataR.ordentrabajoid.toString();
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: OTRAS OBLIGACIONES", 15, 42, { align: "left" });

                                if(dataR.otras_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                }
                                else{
                                if(dataR.otras_cumplimiento === 0 && dataR.otras_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_otras = document.getElementById(idChart_otras);
                                    const base64graph_otras = canvas_otras.toDataURL();

                                    var imgpie_otras= new Image();
                                    imgpie_otras.src = base64graph_otras;
                                    doc.addImage(imgpie_otras, "PNG", 10, imgx-15, 200, 100);

                                    
                                    var cumplimiento_otras = "Cumplimiento : " + dataR.otras_cumplimiento + "%";
                                    var incumplimiento_otras= "Incumplimiento : " + dataR.otras_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_otras, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_otras, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (dataR.otras_json_aut != null) {
                                    var valuesJsonPendiente_otras = JSON.parse(dataR.otras_json_aut);
                                    valuesJsonPendiente_otras.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (dataR.otras_json_inc != null) {
                                    var valuesJsonInterno_otras = JSON.parse(dataR.otras_json_inc);
                                    
                                    valuesJsonInterno_otras.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }


                                ////// FIN
                                inicio = inicio + 20;

                                if ((index + 1) < this.dataSourceCompany.length) {
                                    doc.addPage();
                                    inicio = 30; // Restart height position
                                    imgx = 75;
                                }

                                ctxchart.removeChild(canvas);

                                
                            }
                        );

                        doc.autoPrint();
                        window.open(
                            URL.createObjectURL(doc.output("blob")),
                            "_blank",
                            "height=850,width=1600,scrollbars=yes,location=yes"
                        );
                        setTimeout(() => {
                            window.URL.revokeObjectURL(doc.output("bloburl"));
                        }, 100);

                    }
                else
                
                               var img = new Image();
                                img.src = require("../assets/logo.png");
                                doc.addImage(img, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');
                                doc.text("REPORTE GENERAL", pageWidth / 2, 25, { align: "center" });

                                var idChart = this.ordentrabajoreporte;
                                console.log(idChart);
                                inicio = inicio + 15;
                                doc.setFontSize(6);
                                doc.rect(10, 38, 185, 10);
                                doc.rect(70, 38, 65, 10);
                                //doc.text("Razón Social:", 12, 42, { align: "left" });
                                // doc.text(dataR.cliente, 12, 42, { align: "left" });
                                var splitTitleCliente = doc.splitTextToSize(this.frm_v_cliente, 50);
                                doc.text(splitTitleCliente, 12, 42, { align: "left" });


                                //doc.text(dataR.ot + "-" + dataR.servicio, 75, 42, { align: "left" });
                                var splitTitleOT = doc.splitTextToSize(this.frm_v_ot + "-" + this.frm_v_servicio, 50);
                                doc.text(splitTitleOT, 75, 42, { align: "left" });

                                doc.text("Fecha Elaboración:", 138, 42, { align: "left" });
                                doc.text(this.frm_v_fechaelaboracion, 138, 46, { align: "left" });

                                inicio = inicio + 10;
                            

                                doc.rect(10, 50, 185, 7);
                                doc.text("NIVEL DE CUMPLIMIENTO AMBIENTAL", 15, 54, { align: "left" });

                                const canvas = document.getElementById(idChart);
                                const base64graph = canvas.toDataURL();

                                var imgpie = new Image();
                                imgpie.src = base64graph;
                                doc.addImage(imgpie, "PNG", 10, imgx, 200, 100);

                               
                                var cumplimiento = "Cumplimiento : " + this.frm_v_cumplimiento + "%";
                                var incumplimiento = "Incumplimiento : " + this.frm_v_incumplimiento + "%";
                                doc.setFontSize(10);
                                doc.text(cumplimiento, 30, inicio+140, { align: "left"});
                                doc.text(incumplimiento, 160, inicio+140, { align: "left"});

                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;

                                //////////// AGUA
                                var img_agua = new Image();
                                img_agua.src = require("../assets/logo.png");
                                doc.addImage(img_agua, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                var idChart_agua = "myChart" + "_agua_" + this.ordentrabajoreporte_variable;
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: AGUA", 15, 42, { align: "left" });

                                if(this.frm_v_agua_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                if(this.frm_v_agua_cumplimiento === 0 && this.frm_v_agua_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_agua = document.getElementById(idChart_agua);
                                    const base64graph_agua = canvas_agua.toDataURL();

                                    var imgpie_agua = new Image();
                                    imgpie_agua.src = base64graph_agua;
                                    doc.addImage(imgpie_agua, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_agua = "Cumplimiento : " + this.frm_v_agua_cumplimiento + "%";
                                    var incumplimiento_agua = "Incumplimiento : " + this.frm_v_agua_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_agua, 30, inicio+140, { align: "left"});
                                    doc.text(incumplimiento_agua, 160, inicio+140, { align: "left"});
                                    
                                    inicio = inicio + 150;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                        
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (this.frm_v_agua_json_aut != null) {
                                    var valuesJsonPendiente = JSON.parse(this.frm_v_agua_json_aut);
                                valuesJsonPendiente.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;
                                
                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (this.frm_v_agua_json_inc != null) {
                                    var valuesJsonInterno = JSON.parse(this.frm_v_agua_json_inc);
                                    
                                    valuesJsonInterno.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 185);
                    
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }

                                    });
                                }

                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;


                                
                                //////////// AIRE
                                var img_aire = new Image();
                                img_aire.src = require("../assets/logo.png");
                                doc.addImage(img_aire, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_aire = "myChart" + "_aire_" + this.ordentrabajoreporte_variable;
                                
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: AIRE", 15, 42, { align: "left" });

                                if(this.frm_v_aire_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                if(this.frm_v_aire_cumplimiento === 0 && this.frm_v_aire_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {

                                    const canvas_aire = document.getElementById(idChart_aire);
                                    const base64graph_aire = canvas_aire.toDataURL();

                                    var imgpie_aire= new Image();
                                    imgpie_aire.src = base64graph_aire;
                                    doc.addImage(imgpie_aire, "PNG", 10, imgx-15, 200, 100);
                                    var cumplimiento_aire = "Cumplimiento : " + this.frm_v_aire_cumplimiento + "%";
                                    var incumplimiento_aire = "Incumplimiento : " + this.frm_v_aire_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_aire, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_aire, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (this.frm_v_aire_json_aut != null) {
                                    var valuesJsonPendiente_aire = JSON.parse(this.frm_v_aire_json_aut);
                                    valuesJsonPendiente_aire.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (this.frm_v_aire_json_inc != null) {
                                    var valuesJsonInterno_aire = JSON.parse(this.frm_v_aire_json_inc);
                                    
                                    valuesJsonInterno_aire.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }

                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;


                                
                                //////////// IMPACTO
                                var img_impacto = new Image();
                                img_impacto.src = require("../assets/logo.png");
                                doc.addImage(img_impacto, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_impacto = "myChart" + "_impacto_" + this.ordentrabajoreporte_variable;
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: IMPACTO AMBIENTAL", 15, 42, { align: "left" });


                                if(this.frm_v_impacto_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                if(this.frm_v_impacto_cumplimiento === 0 && this.frm_v_impacto_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_impacto = document.getElementById(idChart_impacto);
                                    const base64graph_impacto = canvas_impacto.toDataURL();

                                    var imgpie_impacto= new Image();
                                    imgpie_impacto.src = base64graph_impacto;
                                    doc.addImage(imgpie_impacto, "PNG", 10, imgx-15, 200, 100);
                                    var cumplimiento_impacto = "Cumplimiento : " + this.frm_v_impacto_cumplimiento + "%";
                                    var incumplimiento_impacto = "Incumplimiento : " + this.frm_v_impacto_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_impacto, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_impacto, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (this.frm_v_impacto_json_aut != null) {
                                    var valuesJsonPendiente_impacto = JSON.parse(this.frm_v_impacto_json_aut);
                                    valuesJsonPendiente_impacto.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (this.frm_v_impacto_json_inc != null) {
                                    var valuesJsonInterno_impacto = JSON.parse(this.frm_v_impacto_json_inc);
                                    
                                    valuesJsonInterno_impacto.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;

                                //////////// ADMINISTRACION 
                                var img_administracion = new Image();
                                img_administracion.src = require("../assets/logo.png");
                                doc.addImage(img_administracion, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_administracion = "myChart" + "_administracion_" + this.ordentrabajoreporte_variable;
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: ADMINISTRACION AMBIENTAL", 15, 42, { align: "left" });


                                if(this.frm_v_administracion_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                if(this.frm_v_administracion_cumplimiento === 0 && this.frm_v_administracion_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_administracion = document.getElementById(idChart_administracion);
                                    const base64graph_administracion = canvas_administracion.toDataURL();

                                    var imgpie_administracion= new Image();
                                    imgpie_administracion.src = base64graph_administracion;
                                    doc.addImage(imgpie_administracion, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_administracion = "Cumplimiento : " + this.frm_v_administracion_cumplimiento + "%";
                                    var incumplimiento_administracion = "Incumplimiento : " + this.frm_v_administracion_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_administracion, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_administracion, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (this.frm_v_administracionambiental_json_aut != null) {
                                    var valuesJsonPendiente_administracion = JSON.parse(this.frm_v_administracionambiental_json_aut);
                                    valuesJsonPendiente_administracion.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (this.frm_v_administracionambiental_json_inc != null) {
                                    var valuesJsonInterno_administracion = JSON.parse(this.frm_v_administracionambiental_json_inc);
                                    
                                    valuesJsonInterno_administracion.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;



                                //////////// PERMISOS 
                                var img_permisos = new Image();
                                img_permisos.src = require("../assets/logo.png");
                                doc.addImage(img_permisos, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_permisos = "myChart" + "_permisos_" + this.ordentrabajoreporte_variable;
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: PERMISOS", 15, 42, { align: "left" });


                                if(this.frm_v_permisos_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                if(this.frm_v_permisos_cumplimiento === 0 && this.frm_v_permisos_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_permisos = document.getElementById(idChart_permisos);
                                    const base64graph_permisos = canvas_permisos.toDataURL();

                                    var imgpie_permisos= new Image();
                                    imgpie_permisos.src = base64graph_permisos;
                                    doc.addImage(imgpie_permisos, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_permisos = "Cumplimiento : " + this.frm_v_permisos_cumplimiento + "%";
                                    var incumplimiento_permisos = "Incumplimiento : " + this.frm_v_permisos_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_permisos, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_permisos, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (this.frm_v_permisos_json_aut != null) {
                                    var valuesJsonPendiente_permisos = JSON.parse(this.frm_v_permisos_json_aut);
                                    valuesJsonPendiente_permisos.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (this.frm_v_permisos_json_inc != null) {
                                    var valuesJsonInterno_permisos = JSON.parse(this.frm_v_permisos_json_inc);
                                    
                                    valuesJsonInterno_permisos.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;




                                //////////// REGISTRO EMISION 
                                var img_registroemision = new Image();
                                img_registroemision.src = require("../assets/logo.png");
                                doc.addImage(img_registroemision, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_registroemision = "myChart" + "_registroemision_" + this.ordentrabajoreporte_variable;
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: REGISTRO EMISIONES", 15, 42, { align: "left" });


                                if(this.frm_v_registroemision_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                if(this.frm_v_registroemision_cumplimiento === 0 && this.frm_v_registroemision_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_registroemision = document.getElementById(idChart_registroemision);
                                    const base64graph_registroemision = canvas_registroemision.toDataURL();

                                    var imgpie_registroemision= new Image();
                                    imgpie_registroemision.src = base64graph_registroemision;
                                    doc.addImage(imgpie_registroemision, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_registroemision = "Cumplimiento : " + this.frm_v_registroemision_cumplimiento + "%";
                                    var incumplimiento_registroemision = "Incumplimiento : " + this.frm_v_registroemision_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_registroemision, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_registroemision, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (this.frm_v_registroemision_json_aut != null) {
                                    var valuesJsonPendiente_registroemision = JSON.parse(this.frm_v_registroemision_json_aut);
                                    valuesJsonPendiente_registroemision.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (this.frm_v_registroemision_json_inc != null) {
                                    var valuesJsonInterno_registroemision = JSON.parse(this.frm_v_registroemision_json_inc);
                                    
                                    valuesJsonInterno_registroemision.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;


                                //////////// RESIDUOS
                                var img_residuos = new Image();
                                img_residuos.src = require("../assets/logo.png");
                                doc.addImage(img_residuos, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_residuos = "myChart" + "_residuos_" + this.ordentrabajoreporte_variable;
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: RESIDUOS", 15, 42, { align: "left" });

                                if(this.frm_v_residuos_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                if(this.frm_v_residuos_cumplimiento === 0 && this.frm_v_residuos_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_residuos = document.getElementById(idChart_residuos);
                                    const base64graph_residuos = canvas_residuos.toDataURL();

                                    var imgpie_residuos= new Image();
                                    imgpie_residuos.src = base64graph_residuos;
                                    doc.addImage(imgpie_residuos, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_residuos = "Cumplimiento : " + this.frm_v_residuos_cumplimiento + "%";
                                    var incumplimiento_residuos = "Incumplimiento : " + this.frm_v_residuos_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_residuos, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_residuos, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (this.frm_v_residuos_json_aut != null) {
                                    var valuesJsonPendiente_residuos = JSON.parse(this.frm_v_residuos_json_aut);
                                    valuesJsonPendiente_residuos.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (this.frm_v_residuos_json_inc != null) {
                                    var valuesJsonInterno_residuos = JSON.parse(this.frm_v_residuos_json_inc);
                                    
                                    valuesJsonInterno_residuos.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;



                                //////////// RIESGO
                                var img_riesgo = new Image();
                                img_riesgo.src = require("../assets/logo.png");
                                doc.addImage(img_riesgo, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_riesgo = "myChart" + "_riesgo_" +this.ordentrabajoreporte_variable;
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: RIESGOS AMBIENTALES", 15, 42, { align: "left" });

                                if(this.frm_v_riesgo_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                if(this.frm_v_riesgo_cumplimiento === 0 && this.frm_v_riesgo_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_riesgo = document.getElementById(idChart_riesgo);
                                    const base64graph_riesgo = canvas_riesgo.toDataURL();

                                    var imgpie_riesgo= new Image();
                                    imgpie_riesgo.src = base64graph_riesgo;
                                    doc.addImage(imgpie_riesgo, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_riesgo = "Cumplimiento : " + this.frm_v_riesgo_cumplimiento + "%";
                                    var incumplimiento_riesgo = "Incumplimiento : " + this.frm_v_riesgo_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_riesgo, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_riesgo, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (this.frm_v_riesgo_json_aut != null) {
                                    var valuesJsonPendiente_riesgo = JSON.parse(this.frm_v_riesgo_json_aut);
                                    valuesJsonPendiente_riesgo.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (this.frm_v_riesgo_json_inc != null) {
                                    var valuesJsonInterno_riesgo = JSON.parse(this.frm_v_riesgo_json_inc);
                                    
                                    valuesJsonInterno_riesgo.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;


                                //////////// RUIDO
                                var img_ruido = new Image();
                                img_ruido.src = require("../assets/logo.png");
                                doc.addImage(img_ruido, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_ruido = "myChart" + "_ruido_" + this.ordentrabajoreporte_variable;
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: RUIDO", 15, 42, { align: "left" });

                                if(this.frm_v_ruido_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                if(this.frm_v_ruido_cumplimiento === 0 && this.frm_v_ruido_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                        const canvas_ruido = document.getElementById(idChart_ruido);
                                        const base64graph_ruido = canvas_ruido.toDataURL();

                                        var imgpie_ruido= new Image();
                                        imgpie_ruido.src = base64graph_ruido;
                                        doc.addImage(imgpie_ruido, "PNG", 10, imgx-15, 200, 100);

                                        var cumplimiento_ruido = "Cumplimiento : " + this.frm_v_ruido_cumplimiento + "%";
                                        var incumplimiento_ruido = "Incumplimiento : " + this.frm_v_ruido_incumplimiento + "%";
                                        doc.setFontSize(10);
                                        doc.text(cumplimiento_ruido, 30, inicio+130, { align: "left"});
                                        doc.text(incumplimiento_ruido, 160, inicio+130, { align: "left"});
                                        
                                        inicio = inicio + 140;
                                    }
                                }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (this.frm_v_ruido_json_aut != null) {
                                    var valuesJsonPendiente_ruido = JSON.parse(this.frm_v_ruido_json_aut);
                                    valuesJsonPendiente_ruido.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (this.frm_v_ruido_json_inc != null) {
                                    var valuesJsonInterno_ruido = JSON.parse(this.frm_v_ruido_json_inc);
                                    
                                    valuesJsonInterno_ruido.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;


                                //////////// SUELO
                                var img_suelo = new Image();
                                img_suelo.src = require("../assets/logo.png");
                                doc.addImage(img_suelo, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_suelo = "myChart" + "_suelo_" + this.ordentrabajoreporte_variable;
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: SUELO Y SUBSUELO", 15, 42, { align: "left" });


                                if(this.frm_v_suelo_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                if(this.frm_v_suelo_cumplimiento === 0 && this.frm_v_suelo_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_suelo = document.getElementById(idChart_suelo);
                                    const base64graph_suelo = canvas_suelo.toDataURL();

                                    var imgpie_suelo= new Image();
                                    imgpie_suelo.src = base64graph_suelo;
                                    doc.addImage(imgpie_suelo, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_suelo = "Cumplimiento : " + this.frm_v_suelo_cumplimiento + "%";
                                    var incumplimiento_suelo = "Incumplimiento : " + this.frm_v_suelo_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_suelo, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_suelo, 160, inicio+130, { align: "left"});
                                
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (this.frm_v_suelo_json_aut != null) {
                                    var valuesJsonPendiente_suelo = JSON.parse(this.frm_v_suelo_json_aut);
                                    valuesJsonPendiente_suelo.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (this.frm_v_suelo_json_inc != null) {
                                    var valuesJsonInterno_suelo = JSON.parse(this.frm_v_suelo_json_inc);
                                    
                                    valuesJsonInterno_suelo.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }
                                doc.addPage();
                                inicio = 30; // Restart height position
                                imgx = 75;



                                //////////// OTRAS
                                var img_otras = new Image();
                                img_otras.src = require("../assets/logo.png");
                                doc.addImage(img_otras, "PNG", 15, 3, 40, 20);

                                doc.setFontSize(8);
                                doc.setFont('helvetica', 'bold');
                                doc.text("LUHOTT-MERAKI ASESORES AMBIENTALES, S.A. DE C.V.", pageWidth / 2, 7, { align: "center" });
                                doc.setFont('helvetica', 'normal');
                                doc.text("FRAY LUIS DE LEÓN 1730, COL. JARDÍN ESPAÑOL, MONTERREY, N.L., C.P. 64820", pageWidth / 2, 11, { align: "center" });
                                doc.text("TEL. 81 8387 4515 E-MAIL: info@lmeraki.com", pageWidth / 2, 15, { align: "center" });
                                doc.textWithLink("www.lmeraki.com", pageWidth / 2, 19, { align: "center", url: url });
                                doc.setFont('helvetica', 'bold');

                                inicio = inicio + 10;
                                var idChart_otras = "myChart" + "_otras_" + this.ordentrabajoreporte_variable;
                                doc.rect(10, 38, 185, 7);
                                doc.text("NIVEL CUMPLIMIENTO: OTRAS OBLIGACIONES", 15, 42, { align: "left" });


                                if(this.frm_v_otras_noaplica === 1){
                                    doc.setFontSize(10);
                                    doc.text("NO APLICA", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                if(this.frm_v_otras_cumplimiento === 0 && this.frm_v_otras_incumplimiento === 0){
                                    doc.setFontSize(10);
                                    doc.text("PENDIENTE", 15, 60,{ align: "left" });

                                    inicio = inicio + 30
                                } else {
                                    const canvas_otras = document.getElementById(idChart_otras);
                                    const base64graph_otras = canvas_otras.toDataURL();

                                    var imgpie_otras= new Image();
                                    imgpie_otras.src = base64graph_otras;
                                    doc.addImage(imgpie_otras, "PNG", 10, imgx-15, 200, 100);

                                    var cumplimiento_otras = "Cumplimiento : " + this.frm_v_otras_cumplimiento + "%";
                                    var incumplimiento_otras = "Incumplimiento : " + this.frm_v_otras_incumplimiento + "%";
                                    doc.setFontSize(10);
                                    doc.text(cumplimiento_otras, 30, inicio+130, { align: "left"});
                                    doc.text(incumplimiento_otras, 160, inicio+130, { align: "left"});
                                    
                                    inicio = inicio + 140;
                                }
                            }
                                doc.setFontSize(8);
                                imgx = imgx + 105;
            
                                
                                doc.text("- Autorizaciones Pendientes ", 10, inicio, { align: "left" }); 
                                if (this.frm_v_otras_json_aut != null) {
                                    var valuesJsonPendiente_otras = JSON.parse(this.frm_v_otras_json_aut);
                                    valuesJsonPendiente_otras.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });
                                    });
                                }

                                inicio = inicio + 10;

                                doc.text("- Incumplimientos", 10, inicio, { align: "left" });
                                if (this.frm_v_otras_json_inc != null) {
                                    var valuesJsonInterno_otras = JSON.parse(this.frm_v_otras_json_inc);
                                    
                                    valuesJsonInterno_otras.forEach(valueJson => {
                                        let splitText = doc.splitTextToSize('• ' + valueJson.descripcion, 180);
                                        inicio = inicio + 5;
                                        doc.text(splitText, 20, inicio, { align: "left" });

                                        if(splitText.length > 1){
                                            inicio = inicio + (3.4 * (splitText.length - 1));
                                        }
                                    });
                                }


                                ////// FIN

                                //ctxchart.removeChild(canvas);

                                doc.autoPrint();
                                window.open(
                                    URL.createObjectURL(doc.output("blob")),
                                    "_blank",
                                    "height=850,width=1600,scrollbars=yes,location=yes"
                                );
                                setTimeout(() => {
                                    window.URL.revokeObjectURL(doc.output("bloburl"));
                                }, 100);
                
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },
    },
};
