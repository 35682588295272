
import {
    DxFormItem,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxLookup,
    DxFilterRow,
    DxSearchPanel,
    DxValidationRule,
    DxItem,
    DxForm,
    DxGrouping,
    DxGroupPanel,
    DxButton

} from 'devextreme-vue/data-grid';
import 'devextreme-vue/text-area';

import { DxToast } from 'devextreme-vue/toast';
import moment from 'moment';

import Servicioervice from '../services/servicio-service';
import SolicitudService from '../services/solicitud-service';
import CompromisoService from '../services/compromisopago-service';
import CondicionService from '../services/condicionpago-service';
import FormaService from '../services/formapago-service';
import PlazoService from '../services/plazofacturacion-service';
import ClienteService from '../services/cliente-service';
import ContactoService from '../services/clientecontacto-service';
import EstatusService from '../services/estatus-service';
import SolicitudServicioService from '../services/solicitudservicio-service';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { confirm } from 'devextreme/ui/dialog';
import Cookies from "js-cookie";
window.Cookies = Cookies;
import notify from 'devextreme/ui/notify';

import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxDateBox from 'devextreme-vue/date-box';
import DxTextArea from 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxNumberBox } from 'devextreme-vue/number-box';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxLookup,
        DxFormItem,
        DxFilterRow,
        DxSearchPanel,
        DxItem,
        DxToast,
        DxForm,
        DxValidationRule,

        DxGrouping,
        DxGroupPanel,
        DxButton,
        DxPopup,
        DxScrollView,
        DxToolbarItem,
        DxTextBox,
        DxDateBox,
        DxTextArea,
        DxSelectBox,
        DxNumberBox,
    },
    data() {

        return {

            dataSourceSolicitud: null,
            dataSourceServicioAlta: null,
            dataSourceServicioEdicion: null,

            dataSourceCliente: null,
            dataSourceContacto: null,
            dataSourceEstatus:  [
                                    {
                                        "id": 1,
                                        "descripcion": "Pendiente",
                                        "bactivo": 1
                                    },
                                    {
                                        "id": 4,
                                        "descripcion": "Aprobada",
                                        "bactivo": 1
                                    },
                                    {
                                        "id": 5,
                                        "descripcion": "Rechazada",
                                        "bactivo": 1
                                    }
                                ],
            dataSourceTipoSolicitud: [{
                        id: 1,
                        descripcion: 'Generar Código Automático'
                        }, {
                            id: 2,
                        descripcion: 'Generar Código Manual (Solicitud Anterior)'
                        }],
            dataSourceContactoCliente: null,

            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Solicitudes',
                showTitle: true,
                width: 750,
                height: 650,
            },
            popupOptionsAlta: {
                title: 'Agregar Servicios',
                showTitle: true,
                width: 750,
                height: 600,
            },
            suppliersData: createStore({
                key: 'SupplierID',
            }),
            setStateValue(rowData, value) {
                rowData.clientecontactoid = null;
                this.defaultSetCellValue(rowData, value);
            },


            popupVisible: false,
            popupTitle: "Alta Solicitud",
            loading: true,

            popupVisible2: false,
            popupTitle2: "Edición Solicitud",
            loading2: true,

            frm_id: null,
            frm_clienteid: null,
            frm_clientecontactoid: null,
            frm_numerosolicitudcliente: null,
            frm_fechasolicitud: null,
            frm_fechadeseadaservicio: null,
            frm_tiempoentrega: null,
            frm_observaciones: null,
            frm_servicio: null,
            frm_tiposolicitud: null,

            v_es_manual:'N',


            frm_ed_clienteid: null,
            frm_ed_clientecontactoid: null,
            frm_ed_numerosolicitudcliente: null,
            frm_ed_fechasolicitud: null,
            frm_ed_estatus: null,
            frm_ed_fechadeseadaservicio: null,
            frm_ed_tiempoentrega: null,
            frm_ed_observaciones: null,
            frm_ed_servicio: null,

            dataSourceServicio: null,



        };
    },
    created() {
        this.solicitudService = new SolicitudService();
        this.compromisoService = new CompromisoService();
        this.condicionService = new CondicionService();
        this.formaService = new FormaService();
        this.plazoService = new PlazoService();
        this.clienteService = new ClienteService();

        this.estatusService = new EstatusService();
        this.contactoService = new ContactoService();
        this.servicioService = new Servicioervice();
        this.solicitudservicioService = new SolicitudServicioService();
    },
    mounted() {
        this.loadDataInfo();
    },
    methods: {
        visibleDelete(e) {
            return e.row.data.estatusid == 1;
        },
        onValueChanged_cod(e) {
            this.v_es_manual='N';
            this.frm_numerosolicitudcliente = '';
            if(e.value == '2')
                this.v_es_manual='S';

        },


        onValueChanged(e) {

            if (this.frm_clienteid != null) {
                let myJSON = JSON.stringify({
                    pid: e.value,
                });
                this.contactoService.getClienteContactoCliente(myJSON).then(data => this.dataSourceContactoCliente = data);
            }
            else {
                this.frm_clientecontactoid = null;
            }
        },
        onValueChangededit(e) {

            if (this.frm_ed_clienteid != null) {
                let myJSON = JSON.stringify({
                    pid: e.value,
                });
                this.contactoService.getClienteContactoCliente(myJSON).then(data => this.dataSourceContactoCliente = data);
            }
            else {
                this.frm_ed_clientecontactoid = null;
            }
        },
        agregarSolicitud() {
            this.frm_id = null;
            this.frm_clienteid = null;
            this.frm_clientecontactoid = null;
            this.frm_numerosolicitudcliente = null;
            this.frm_fechasolicitud = null;
            this.frm_fechadeseadaservicio = null;
            this.frm_tiempoentrega = null;
            this.frm_observaciones = null;
            this.frm_servicio = null;
            this.frm_tiposolicitud = null;
            this.loading = true;
            this.popupVisible = true;

        },
        saveRegister() {


            let err = 0;
            let msgerror = '';
            let vnumerosolicitud = '';
            let vgeneranumero = 1;
            let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;


            if (this.frm_clienteid == null) {
                err = 1;
                msgerror = 'Ingresar cliente';
            }

            if (this.frm_tiposolicitud == null) {
                err = 1;
                msgerror = 'Ingresar tipo solicitud a generar';
            }

            if (err == 0) {
                if (this.frm_tiposolicitud == 2) {
                    vgeneranumero=0;
                    if (this.frm_numerosolicitudcliente == null || this.frm_numerosolicitudcliente == '') {
                        err = 1;
                        msgerror = 'Ingresar número solicitud';
                    }else{
                        vnumerosolicitud = this.frm_numerosolicitudcliente;
                    }
                }
            }

            if (err == 0) {
                if (this.frm_fechasolicitud == null) {
                    err = 1;
                    msgerror = 'Ingresar fecha de solicitud';
                }
            }

            let myJSON = JSON.stringify({
                pnumerosolicitudcliente: vnumerosolicitud,
                pfechasolicitud: moment(this.frm_fechasolicitud).format('YYYY-MM-DD'),
                pfechadeseadaservicio: this.frm_fechadeseadaservicio ? moment(this.frm_fechadeseadaservicio).format('YYYY-MM-DD') : null,
                ptiempoentrega: this.frm_tiempoentrega,
                pcompromisopagoid: 0,
                pcondicionpagoid: 0,
                pformapagoid: 0,
                pplazofacturacionid: 0,
                pobservaciones: this.frm_observaciones,
                pclienteid: this.frm_clienteid,
                pclientecontactoid: this.frm_clientecontactoid,
                pusuarioregistroid: idusuario,
                pgeneranumero:vgeneranumero

            });



            if (err == 0) {
                this.solicitudService.createSolicitud(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha guardado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);


                        // SE ENLAZA A PANTALLA DE EDICION
                        console.log('DATOS RECIBIDOS', data);
                        notify({
                            message: "Codigo Asignado: " + data.codigo,
                        }, this.type, 8000);

                        this.popupVisible = false;
                        this.frm_numerosolicitudcliente = data.codigo;
                        this.frm_id = data.id;
                        this.editarSolicitud_enlace(data.clienteid, this.frm_id);
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }


                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }




        },
        editRegister() {


            let err = 0;
            let msgerror = '';

            let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;


            if (this.frm_ed_clienteid == null) {
                err = 1;
                msgerror = 'Ingresar cliente';
            }
            if (err == 0) {
                if (this.frm_ed_numerosolicitudcliente == null) {
                    err = 1;
                    msgerror = 'Ingresar solicitud de cliente';
                }
            }

            if (err == 0) {
                if (this.frm_ed_fechasolicitud == null) {
                    err = 1;
                    msgerror = 'Ingresar fecha de solicitud';
                }
            }




            let myJSON = JSON.stringify({
                pid: this.frm_id,

                pfechasolicitud: moment(this.frm_ed_fechasolicitud).format('YYYY-MM-DD'),
                pfechadeseadaservicio: this.frm_ed_fechadeseadaservicio ? moment(this.frm_ed_fechadeseadaservicio).format('YYYY-MM-DD') : null,
                ptiempoentrega: this.frm_ed_tiempoentrega,
                pcompromisopagoid: 0,
                pcondicionpagoid: 0,
                pformapagoid: 0,
                pplazofacturacionid: 0,
                pobservaciones: this.frm_ed_observaciones,
                pclienteid: this.frm_ed_clienteid,
                pclientecontactoid: this.frm_ed_clientecontactoid,
                pusuarioregistroid: idusuario,

            });




            if (err == 0) {
                this.solicitudService.updateSolicitud(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha actualizado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible2 = false;
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }


                });
            } else {
                this.type = 'error';
                this.message = msgerror;
                this.isVisible = true;
                notify({
                    message: this.message,
                }, this.type, 4000);

            }





        },

        editarSolicitud_enlace(clienteid, id) {
            this.frm_id = id;

            
            //Estatus
            //this.estatusService.getEstatusSolicitud().then(data => this.dataSourceEstatus = data);


            //Contactos del Cliente
            let myJSON = JSON.stringify({
                pid: clienteid,
            });
            this.contactoService.getClienteContactoCliente(myJSON).then(data => this.dataSourceContactoCliente = data);

            //Servicios de la Solicitud
            let myJSON3 = JSON.stringify({
                psolicitudid: this.frm_id
            });

            this.solicitudservicioService.getSolicitudServicio(myJSON3).then(data => {

                this.dataSourceServicioAlta = data;
            });

            //Datos de la solicitud
            let myJSON2 = JSON.stringify({
                pid: id,
            });

            //Servicios de la Solicitud

            this.servicioService.getServicio().then(data => this.dataSourceServicio = data);
            
                this.frm_ed_clienteid = '';
                this.frm_ed_clientecontactoid = '';
                this.frm_ed_estatus = '';
                this.frm_ed_numerosolicitudcliente = '';
                this.frm_ed_fechasolicitud = '';
                this.frm_ed_fechadeseadaservicio = '';
                this.frm_ed_tiempoentrega = '';
                this.frm_ed_observaciones = '';
                this.frm_ed_servicio = '';

            this.solicitudService.getSolicitudDatosParaEdicion(myJSON2).then(data => {

                this.frm_id = data.id;
                this.frm_ed_clienteid = data.clienteid;
                this.frm_ed_clientecontactoid = data.clientecontactoid;
                this.frm_ed_estatus = data.estatus;
                this.frm_ed_numerosolicitudcliente = data.numerosolicitudcliente;
                this.frm_ed_fechasolicitud = data.fechasolicitud;
                this.frm_ed_fechadeseadaservicio = data.fechadeseadaservicio;
                this.frm_ed_tiempoentrega = data.tiempoentrega;
                this.frm_ed_observaciones = data.observaciones;
                this.frm_ed_servicio = data.servicioid;
            });



            this.loading2 = true;
            this.popupVisible2 = true;

        },

        editarSolicitud(e) {
            this.dataSourceServicioAlta = null;
            this.frm_id = e.row.data.id;
            this.editarSolicitud_enlace(e.row.data.clienteid, this.frm_id);
            /**
            //Contactos del Cliente
            let myJSON = JSON.stringify({
                pid: e.row.data.clienteid,
            });
            this.contactoService.getClienteContactoCliente(myJSON).then(data => this.dataSourceContactoCliente = data);


            //Servicios de la Solicitud
            let myJSON2 = JSON.stringify({
                pid: e.row.data.id,
            });
            this.servicioService.getServicioEdicion(myJSON2).then(data => this.dataSourceServicio = data);

            //Estatus
            // this.estatusService.getEstatusParaEdicion2(myJSON2).then(data => this.dataSourceEstatus = data);
            //Estatus
            this.estatusService.getEstatus().then(data => this.dataSourceEstatus = data);

            this.frm_id = e.row.data.id;
            this.frm_ed_clienteid = e.row.data.clienteid;
            this.frm_ed_clientecontactoid = e.row.data.clientecontactoid;
            this.frm_ed_estatus = e.row.data.estatus;
            this.frm_ed_numerosolicitudcliente = e.row.data.numerosolicitudcliente;
            this.frm_ed_fechasolicitud = e.row.data.fechasolicitud;
            this.frm_ed_fechadeseadaservicio = e.row.data.fechadeseadaservicio;
            this.frm_ed_tiempoentrega = e.row.data.tiempoentrega;
            this.frm_ed_observaciones = e.row.data.observaciones;
            this.frm_ed_servicio = e.row.data.servicioid;

            this.loading2 = true;
            this.popupVisible2 = true;
            **/

        },

        eliminarSolicitud(e) {


            let myJSON = JSON.stringify({
                pid: e.row.data.id,
            });


            let result = confirm("<i>Estas seguro que desea eliminar la solicitud?</i>", "Confirmación");
            result.then((dialogResult) => {
                if (dialogResult) {


                    this.solicitudService.deleteSolicitud(myJSON).then(data => {
                        if (data.err == 0) {
                            this.type = 'success';
                            this.message = 'Se ha eliminado el registro';
                            this.isVisible = true;
                            this.loadDataInfo();
                        } else {
                            this.type = 'error';
                            this.message = 'Ocurrio un error ' + data.msg;
                            this.isVisible = true;
                        }
                    });



                }
            });

        },

        loadDataInfo() {

            this.solicitudService.getSolicitud().then(data => this.dataSourceSolicitud = data);
            this.condicionService.getCondicionPago().then(data => this.dataSourceCondicion = data);
            this.compromisoService.getCompromisoPago().then(data => this.dataSourceCompromiso = data);
            this.formaService.getFormaPago().then(data => this.dataSourceForma = data);
            this.plazoService.getPlazoFacturacion().then(data => this.dataSourcePlazo = data);
            this.clienteService.getCliente().then(data => this.dataSourceCliente = data);


        },

        onEditorPreparing(e) {

            if (e.parentType === 'dataRow' && e.dataField === 'clientecontactoid') {
                e.editorOptions.disabled = (typeof e.row.data.clienteid !== 'number');
            }
        },

        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },


        computedDatesFormat(value) {
            return moment(value).format('DD/MM/YYYY')
        },

        deleteProcedureService(e) {
            this.frm_id = e.data.solicitudid;
            let myJSON = JSON.stringify({
                pid: e.data.id
            });



            this.solicitudservicioService.deleteSolicitudServicio(myJSON).then(data => {

                if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha eliminado el registro';
                    this.isVisible = true;
                    //Se carga informacion de solicitudes servicios
                    //Servicios de la Solicitud
                    let myJSON3 = JSON.stringify({
                        psolicitudid: this.frm_id
                    });

                    this.solicitudservicioService.getSolicitudServicio(myJSON3).then(data => {

                        this.dataSourceServicioAlta = data;
                    });
                } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                    let myJSON3 = JSON.stringify({
                        psolicitudid: this.frm_id
                    });

                    this.solicitudservicioService.getSolicitudServicio(myJSON3).then(data => {

                        this.dataSourceServicioAlta = data;
                    });
                }

            });


        },

        createProcedureService(e) {

            let myJSON = JSON.stringify({
                psolicitudid: this.frm_id,
                pservicioid: e.data.servicioid,
                pobservaciones: e.data.observaciones,
                pestatusid: e.data.estatusid,
            });



            this.solicitudservicioService.createSolicitudServicio(myJSON).then(data => {

                if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha creado el registro';
                    this.isVisible = true;
                    //Se carga informacion de solicitudes servicios
                    this.loadDataInfo();
                    //Servicios de la Solicitud
                    let myJSON3 = JSON.stringify({
                        psolicitudid: this.frm_id
                    });

                    this.solicitudservicioService.getSolicitudServicio(myJSON3).then(data => {

                        this.dataSourceServicioAlta = data;
                    });
                    
                } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                    //Se carga informacion de solicitudes servicios
                    //Servicios de la Solicitud
                    let myJSON3 = JSON.stringify({
                        psolicitudid: this.frm_id
                    });

                    this.solicitudservicioService.getSolicitudServicio(myJSON3).then(data => {

                        this.dataSourceServicioAlta = data;
                    });
                }

            });


        },

        editProcedureService(e) {
            this.frm_id = e.data.solicitudid;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pservicioid: e.data.servicioid,
                pobservaciones: e.data.observaciones,
                pestatusid: e.data.estatusid,
            });
            console.log('JSON DE UPDATE',myJSON);

            this.solicitudservicioService.updateSolicitudServicio(myJSON).then(data => {

                if (data.err == 0) {
                    this.type = 'success';
                    this.message = 'Se ha actualizado el registro';
                    this.isVisible = true;
                    //Se carga informacion de solicitudes servicios
                    this.loadDataInfo();
                    //Servicios de la Solicitud
                    let myJSON3 = JSON.stringify({
                        psolicitudid: this.frm_id
                    });

                    this.solicitudservicioService.getSolicitudServicio(myJSON3).then(data => {
                        this.dataSourceServicioAlta = data;
                    });
                    console.log('JSON DE reconsulta UPDATE',myJSON3);
                } else {

                    this.type = 'error';
                    this.message = 'Ocurrio un error ' + data.msg;
                    this.isVisible = true;
                    //Se carga informacion de solicitudes servicios
                    //Servicios de la Solicitud
                    let myJSON3 = JSON.stringify({
                        psolicitudid: this.frm_id
                    });

                    this.solicitudservicioService.getSolicitudServicio(myJSON3).then(data => {

                        this.dataSourceServicioAlta = data;
                    });
                }

            });


        },
    },
};
