


import DxForm, {
  DxGroupItem,
  DxSimpleItem,
  DxLabel,
  DxItem
} from 'devextreme-vue/form';


import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,

  DxGroupPanel,
  DxFormItem,
  DxValidationRule,

  DxLookup,
  DxButton,


} from 'devextreme-vue/data-grid';


import {
  DxGantt,
  DxTasks,
  DxDependencies,
  DxResources,
  DxResourceAssignments,


  DxValidation,
  DxToolbar,

} from 'devextreme-vue/gantt';


import axios from 'axios';
import { DxSelectBox } from 'devextreme-vue/select-box';
import DxTabPanel from "devextreme-vue/tab-panel";
import { customers } from './../data/dataperfil.js';
import { DxToast } from 'devextreme-vue/toast';
import 'devextreme-vue/text-area';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import Cookies from "js-cookie";
window.Cookies = Cookies;

import TipoProcesoResolucion from '../services/tipoprocesoresolucion-service';
import TipoRespuestaService from '../services/tiporespuesta-service';
import OrdenTrabajoResultadoService from '../services/ordentrabajoresultado-service';
import OrdenTrabajoDocumentoService from '../services/ordentrabajodocumento-service';
import OrdenTrabajoEtapaService from '../services/ordentrabajoetapa-service';
import OrdenTrabajoPlanService from '../services/ordentrabajoplan-service';
import TipoDocumentoService from '../services/tipodocumento-service';
import EstatusService from '../services/estatus-service';
import LeyDetalleService from '../services/leydetalle-service';
import LeyService from '../services/ley-service';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxTextBox } from 'devextreme-vue/text-box';
import 'devextreme-vue/text-area';
import DxCheckBox from 'devextreme-vue/check-box';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxTextArea from 'devextreme-vue/text-area';
import DxDateBox from 'devextreme-vue/date-box';
import { jscompetencia, jstipoacuerdo, jstipodia, jsrespuesta } from '../data/dataProc.js';
import ResponsabilidadEntregaService from '../services/responsabilidadentrega-service';

import 'jspdf-autotable';
import { exportGantt as exportGanttToPdf } from 'devextreme/pdf_exporter';

var dataSourceArticulos = [];
const ganttRef = 'gantt';
export default {
  components: {

    DxGroupItem,
    DxSimpleItem,
    DxDataGrid,
    DxTabPanel,
    DxItem,
    DxToast,
    DxEditing,
    DxColumn,
    DxPaging,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxLabel,
    DxGroupPanel,
    DxFormItem,
    DxSelectBox,
    DxFileUploader,
    DxTextBox,
    DxValidationRule,
    DxButton,
    DxLookup,
    DxPopup,
    DxScrollView,
    DxToolbarItem,
    DxDateBox,
    DxNumberBox,
    DxTextArea,
    DxCheckBox,


    DxGantt,
    DxTasks,
    DxDependencies,
    DxResources,
    DxResourceAssignments,
    DxValidation,
    DxToolbar,
  },
  data() {
    return {
      ganttRef,
      popupVisible: false,
      popupTitle: "Expediente",
      loading: true,
      popupTitle4: "Contestación",
      popupTitle8: "Expediente Medidas de Seguridad",
      popupTitle9: "Medidas de Seguridad",
      dataSourceResponsabilidadEntrega: null,
      frm_contestacion_fechaescrito: null,
      frm_contestacion_comentarios: null,
      frm_contestacion_tiporespuestaid: null,
      frm_contestacion_tiporespuesta: null,
      frm_contestacion_copiacertificada: null,
      frm_contestacion_ordeninspeccion: null,
      frm_contestacion_actaemplazamiento: null,
      frm_contestacion_acuerdoemplazamiento: null,
      frm_contestacion_cedulanotificacion: null,
      frm_contestacion_acuerdoalegatos: null,
      frm_contestacion_resolucionadministrativa: null,
      frm_contestacion_copiacertificadaexpediente: null,
      frm_contestacion_acuerdocierre: null,
      frm_contestacion_visita: null,
      frm_contestacion_prorroga: null,
      dataSourceTipoRespuesta: null,
      frm_contestacion_id: null,
      frm_contestacion_ed_fechaescrito: null,
      frm_contestacion_ed_comentarios: null,
      frm_contestacion_ed_tiporespuestaid: null,
      frm_contestacion_ed_tiporespuesta: null,
      frm_contestacion_ed_copiacertificada: null,
      frm_contestacion_ed_ordeninspeccion: null,
      frm_contestacion_ed_actaemplazamiento: null,
      frm_contestacion_ed_acuerdoemplazamiento: null,
      frm_contestacion_ed_cedulanotificacion: null,
      frm_contestacion_ed_acuerdoalegatos: null,
      frm_contestacion_ed_resolucionadministrativa: null,
      frm_contestacion_ed_copiacertificadaexpediente: null,
      frm_contestacion_ed_acuerdocierre: null,
      frm_contestacion_ed_visita: null,
      frm_contestacion_ed_prorroga: null,
      frm_contestacion_ed_fecharegistro: null,
      dataSourceContestacionIrregularidad: null,
      dataSourceContestacionIrregularidadEdicion: null,

      frm_id: null,
      frm_exp_expediente: null,
      frm_exp_orden: null,
      frm_exp_acta: null,
      frm_exp_competencia: null,
      frm_exp_tipoacuerdo: null,
      frm_exp_fechaemision: null,
      frm_exp_fechaacuerdo: null,
      dataSourceTipoAcuerdo: jstipoacuerdo,
      dataSourceCompetencia: jscompetencia,

      frm_id_medida: null,
      frm_exp_orden_medida: null,
      frm_exp_acta_medida: null,
      frm_exp_observacion_medida: null,

      popupVisible2: false,
      popupVisible3: false,
      popupVisible4: false,
      popupVisible5: false,


      popupVisible6: false,
      popupVisible8: false,
      popupVisible9: false,
      popupVisible10: false,
      popupTitle6: "Alegatos",


      frm_alegatos_id: null,
      frm_alegatos_nooficio: null,
      frm_alegatos_vencimiento: null,
      frm_alegatos_notificacion: null,

      popupVisible7: false,
      popupTitle7: "Resolucion",
      frm_resolucion_id: null,
      frm_resolucion_nooficio: null,
      frm_resolucion_fecharesolucion: null,
      frm_resolucion_multa: null,
      frm_resolucion_multaimporte: null,
      frm_resolucion_irr_pordentrabajoprocedimientoid: null,
      frm_resolucion_irr_ordentrabajoirregularidadid: null,
      frm_resolucion_irr_tiempo: null,
      frm_resolucion_irr_fechavencimiento: null,
      dataSourceProcedimientoResoIrr: null,
      dataSourceProcedimientoResolucionProc: null,
      dataSourceTipoProcesoResolucion: null,

      dataSourceRespuesta: jsrespuesta,
      dataSourceReconsideracion: null,



      popupVisibletps1: false,
      popupTitletps1: "Solicitud de conmutación",
      popupVisibletps2: false,
      popupTitletps2: "Recurso de revisión",
      popupVisibletps3: false,
      popupTitletps3: "Solicitud de reconsideración",
      popupVisibletps4: false,
      popupTitletps4: "Juicio de amparo",
      popupVisibletps5: false,
      popupTitletps5: "Juicio de nulidad",
      popupVisibletps6: false,
      popupTitletps6: "Ampliación Términos",
      popupVisibletps7: false,
      popupTitletps7: "Recurso de inconformidad",
      popupVisibletps8: false,
      popupTitletps8: "Otro",
      popupVisibletps9: false,
      popupTitletps9: "Desistimiento",
      frm_resolucion_proceso_id: null,
      frm_resolucion_proceso_procedimientoid: null,
      frm_conmutacion_proyecto: null,
      frm_conmutacion_proyectoviable: null,
      frm_conmutacion_comentarios: null,
      frm_revision_comentarios: null,
      frm_juicioamparo_comentarios: null,
      frm_juicionulidad_comentarios: null,

      frm_ampliacion_fechanotificacion: null,
      frm_ampliacion_comentarios: null,
      frm_ampliacion_dias: null,
      frm_ampliacion_fechavencimiento: null,
      frm_inconformidad_comentarios: null,
      frm_otros_comentarios: null,
      frm_desistimiento_comentarios: null,




      popupTitle2: "Medidas Correctivas",
      frm_irr_clave: null,
      frm_irr_descripcion: null,
      frm_irr_dia: null,
      frm_irr_tipodia: null,
      frm_irr_estatus: null,
      frm_irr_observacion: null,
      frm_irr_id: null,
      frm_irr_ed_clave: null,
      frm_irr_ed_descripcion: null,
      frm_irr_ed_dia: null,
      frm_irr_ed_tipodia: null,
      frm_irr_ed_estatus: null,
      frm_irr_ed_observacion: null,
      frm_irr_ed_medida: null,
      frm_irr_ed_fechalimite: null,
      dataSourceTipoDia: jstipodia,

      frm_med_seg_clave: null,
      frm_med_seg_descripcion: null,
      frm_med_seg_estatus: null,
      frm_med_seg_observacion: null,
      frm_med_seg_id: null,
      frm_med_seg_ed_clave: null,
      frm_med_seg_ed_descripcion: null,
      frm_med_seg_ed_estatus: null,
      frm_med_seg_ed_observacion: null,


      eca_id: null,
      eca_materia: null,
      eca_recomendacion: null,
      eca_observacion: null,
      eca_pregunta: null,
      eca_si: false,
      eca_no: false,
      eca_parcial: false,
      eca_noaplica: false,
      eca_aplicagantt: false,
      eca_fundamentos: null,

      dataSourceProcedimiento: null,
      dataSourceExpedienteMedidas: null,
      dataSourceMedidas: null,
      dataSourceMedidasFundamento: null,
      dataSourceIrregularidad: null,
      dataSourceIrregularidadFundamento: null,
      dataSourceLeyes: null,
      dataSourceArticulos,
      dataSource: customers,
      dataSourceEstatus: null,
      dataSourceCambioEstatus: null,
      dataSourceEtapaCierre: null,
      dataSourceEstandar: null,
      dataSourceEntrega: null,
      dataSourceListados: null,
      dataSourceDocumentacion: null,
      dataSourceEtapa: null,
      dataSourceECA: null,
      dataSourceHistorial: null,
      dataSourceDocumento: null,

      dataSourceProcedimientoCont: null,
      dataSourceProcedimientoAleg: null,
      dataSourceProcedimientoReso: null,


      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptionsListado: {
        title: 'Resultados',
        showTitle: true,
        width: 650,
        height: 400,
      },
      popupOptions: {
        title: 'Resultados',
        showTitle: true,
        width: 650,
        height: "auto",
      }, popupOptions2: {
        title: 'Resultados',
        showTitle: true,
        width: 400,
        height: 310,
      },
      popupOptions3: {
        title: 'Actualizacion',
        showTitle: true,
        width: 300,
        height: 200,
      },
      popupOptions4: {
        title: 'Irregularidades',
        showTitle: true,
        width: 1000,
        height: 600,
      },
      popupOptions5: {
        title: 'Irregularidades Fundamentos',
        showTitle: true,
        width: 1000,
        height: 350,
      },
      popupOptions7: {
        title: 'Irregularidades Medidas',
        showTitle: true,
        width: 400,
        height: 250,
      },
      popupOptions8: {
        title: 'Prcoeso',
        showTitle: true,
        width: 400,
        height: "auto",
      },
      valetapa: 0,
      valcierre: '',
      valtipodocumento: 0,
      valnombredocumento: '',
      columns: ['CompanyName', 'City', 'State', 'Phone', 'Fax'],
      customer: null,
      buttonOptions: {
        text: 'Actualizar Orden Trabajo',
        type: 'success',
        useSubmitBehavior: true,
      },
      readonlyOptions: {
        disabled: true,
      },
      passwordOptions: {
        mode: 'password',
      },
      dateBoxOptions: {
        invalidDateMessage:
          'Campos tipo fecha debe tener el siguiente formato: MM/dd/yyyy',
      },
      checkBoxOptions: {
        text: 'I agree to the Terms and Conditions',
        value: false,
      },
      phoneEditorOptions: {
        mask: '+1 (X00) 000-0000',
        maskRules: {
          X: /[02-9]/,
        },
        maskInvalidMessage: 'The phone must have a correct USA phone format',
      },
      currencyOptions: {
        mask: '#,##0.00',
        maskInvalidMessage: 'The phone must have a correct USA phone format',

      },

      setLeyValue(rowData, value) {
        rowData.leydetalleid = null;
        this.defaultSetCellValue(rowData, value);
      },

      maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
      namePattern: /^[^0-9]+$/,
      cityPattern: /^[^0-9]+$/,
      phonePattern: /^[02-9]\d{9}$/,
      numsPattern: /[0-9]/,
      vidot: null,
      backendURL: process.env.VUE_APP_APIURL,
      exportButtonOptions: {
        hint: 'Export to PDF',
        icon: 'exportpdf',
        stylingMode: 'text',
        onClick: () => {
          this.exportGantt();
        },
      },
    };
  },
  computed: {
    gantt() {
      return this.$refs[ganttRef].instance;
    },
  },
  created() {
    this.ordentrabajoplanService = new OrdenTrabajoPlanService();
    this.tipoprocesoresolucionService = new TipoProcesoResolucion();
    this.tiporespuestaService = new TipoRespuestaService();
    this.ordentrabajoresultadoService = new OrdenTrabajoResultadoService();
    this.ordentrabajoetapaService = new OrdenTrabajoEtapaService();
    this.ordentrabajodocumentoService = new OrdenTrabajoDocumentoService();
    this.responsabilidadentregaService = new ResponsabilidadEntregaService();
    this.tipodocumentoService = new TipoDocumentoService();
    this.estatusService = new EstatusService();
    this.leyService = new LeyDetalleService();
    this.leyDetalleService = new LeyDetalleService();
    this.leyService = new LeyService();
    this.vidot = this.$route.params.id;

  },
  mounted() {
    this.loadDataInfo();
  },
  methods: {
    exportGantt() {


      exportGanttToPdf({
        component: this.gantt,
        createDocumentMethod: (args) => new jsPDF(args),
        format: 'a4',
        landscape: 'true',
        exportMode: 'all',
        dateRange: "visible",
      }).then((doc) => doc.save('gantt.pdf'));
    },
    leyDisplay(data) {
      if (data.descripcioncorta.includes('Norma')) {
        return data.descripcioncorta;
      }
      return data.descripcioncorta + ' (' + data.descripcionlarga + ')';
    },

    fundamentoDisplay(data) {
      if (data.descripcioncorta.includes('.-') && data.descripcioncorta.includes('Artículo')) {
        return data.descripcionlarga;
      }
      return data.descripcioncorta + '.- ' + data.descripcionlarga;
    },

    getFilteredFundamentos: (options) => ({
      store: dataSourceArticulos,
      filter: options.data ? ['leyid', '=', options.data.leyid] : null,
    }),

    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'leydetalleid') {
        e.editorOptions.disabled = (typeof e.row.data.leyid !== 'number');
      }
    },

    handleValueChangeMulta(e) {

      if (!e.value) {
        this.frm_resolucion_multaimporte = 0;
      }

    },

    agregarContestacion() {
      this.frm_contestacion_id = null;
      this.frm_contestacion_fechaescrito = null;
      this.frm_contestacion_comentarios = null;
      this.frm_contestacion_tiporespuestaid = null;
      this.frm_contestacion_tiporespuesta = null;

      this.frm_contestacion_copiacertificada = null;
      this.frm_contestacion_ordeninspeccion = null;
      this.frm_contestacion_actaemplazamiento = null;
      this.frm_contestacion_acuerdoemplazamiento = null;

      this.frm_contestacion_cedulanotificacion = null;
      this.frm_contestacion_acuerdoalegatos = null;
      this.frm_contestacion_resolucionadministrativa = null;
      this.frm_contestacion_copiacertificadaexpediente = null;


      this.frm_contestacion_acuerdocierre = null;
      this.frm_contestacion_visita = null;
      this.frm_contestacion_prorroga = null;

      this.loading = true;
      this.popupVisible4 = true;

    },
    editProcesoDef(e) {

      /**
        1   Solicitud de conmutacion
        2   Recurso de revision
        3   Solicitud de reconsideracion
        4   Juicio de amparo
        5   Juicio de nulidad
        6   Ampliacion Terminos
        7   Recurso de inconformidad
        8   Otro
        9   Desistimiento  
       
       */
      let tps = e.row.data.tipoprocesoresolucionid;
      this.frm_resolucion_proceso_id = e.row.data.id;
      this.frm_resolucion_proceso_procedimientoid = e.row.data.ordentrabajoprocedimientoid;
      if (tps == 1) {
        this.frm_conmutacion_proyecto = e.row.data.conmutacion_proyecto;
        this.frm_conmutacion_proyectoviable = e.row.data.conmutacion_proyectoviable == 1 ? true : false;
        this.frm_conmutacion_comentarios = e.row.data.conmutacion_comentarios;
        this.popupVisibletps1 = true;
      }
      if (tps == 2) {
        this.frm_revision_comentarios = e.row.data.revision_comentarios;
        this.popupVisibletps2 = true;
      }
      if (tps == 3) {
        //Cargamos irregularidades

        let id = this.vidot;
        let myJSON = JSON.stringify({
          pid: id,

        });
        this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoContestacionIrrEdicion(myJSON).then(data => {
          this.dataSourceContestacionIrregularidadEdicion = data
        }
        );

        let myJSON2 = JSON.stringify({
          pid: this.frm_resolucion_proceso_id,

        });
        this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoRecProcIrr(myJSON2).then(data => {
          this.dataSourceReconsideracion = data
        }
        );
        this.popupVisibletps3 = true;
      }
      if (tps == 4) {
        this.frm_juicioamparo_comentarios = e.row.data.juicioamparo_comentarios;
        this.popupVisibletps4 = true;
      }
      if (tps == 5) {
        this.frm_juicionulidad_comentarios = e.row.data.jucionulidad_comentarios;
        this.popupVisibletps5 = true;
      }
      if (tps == 6) {
        this.frm_ampliacion_fechanotificacion = e.row.data.ampliacion_fechanotificacion;
        this.frm_ampliacion_fechavencimiento = e.row.data.ampliacion_fechavencimiento;
        this.frm_ampliacion_comentarios = e.row.data.ampliacion_comentarios;
        this.frm_ampliacion_dias = e.row.data.ampliacion_dias;
        this.popupVisibletps6 = true;
      }
      if (tps == 7) {

        this.frm_inconformidad_comentarios = e.row.data.inconformidad_comentarios;
        this.popupVisibletps7 = true;
      }
      if (tps == 8) {
        this.frm_otros_comentarios = e.row.data.otros_comentarios;
        this.popupVisibletps8 = true;
      }
      if (tps == 9) {
        this.frm_desistimiento_comentarios = e.row.data.desistimiento_comentarios;
        this.popupVisibletps9 = true;
      }


    },
    agregarIrregularidad() {
      this.frm_irr_clave = null;
      this.frm_irr_descripcion = null;
      this.frm_irr_dia = null;
      this.frm_irr_observacion = null;
      this.frm_irr_tipodia = null;
      this.frm_irr_estatus = null;
      this.loading = true;
      this.popupVisible2 = true;

    },
    editIrregularidad(e) {

      this.estatusService.getEstatusIrregularidad().then(data => this.dataSourceEstatus = data);

      this.frm_irr_id = e.row.data.id;
      this.frm_irr_ed_clave = e.row.data.clave;
      this.frm_irr_ed_descripcion = e.row.data.descripcion;
      this.frm_irr_ed_dia = e.row.data.diashabiles;
      this.frm_irr_ed_observacion = e.row.data.observacion;
      this.frm_irr_ed_medida = e.row.data.medida;
      this.frm_irr_ed_tipodia = e.row.data.tipodiaid;
      this.frm_irr_ed_estatus = e.row.data.estatusid;
      this.frm_irr_ed_fechalimite = e.row.data.fechalimite;

      this.loading = true;
      this.popupVisible3 = true;

    },

    agregarMedidaSeguridad() {
      this.frm_med_seg_clave = null;
      this.frm_med_seg_descripcion = null;
      this.frm_med_seg_observacion = null;
      this.frm_med_seg_estatus = null;
      this.loading = true;
      this.popupVisible9 = true;

    },

    editMedidaSeguridad(e) {

      this.estatusService.getEstatusIrregularidad().then(data => this.dataSourceEstatus = data);

      this.frm_med_seg_id = e.row.data.id;
      this.frm_med_seg_ed_clave = e.row.data.clave;
      this.frm_med_seg_ed_descripcion = e.row.data.descripcion;
      this.frm_med_seg_ed_observacion = e.row.data.observacion;
      this.frm_med_seg_ed_estatus = e.row.data.estatusid;

      this.loading = true;
      this.popupVisible10 = true;

    },
    editContestacion(e) {


      this.frm_contestacion_id = e.row.data.id;
      this.frm_contestacion_ed_fechaescrito = e.row.data.fechaescrito;
      this.frm_contestacion_ed_comentarios = e.row.data.comentarios;
      this.frm_contestacion_ed_tiporespuestaid = e.row.data.tiporespuestaid;
      this.frm_contestacion_ed_tiporespuesta = e.row.data.tiporespuesta;
      this.frm_contestacion_ed_copiacertificada = e.row.data.copiacertificada;
      this.frm_contestacion_ed_ordeninspeccion = e.row.data.ordeninspeccion;
      this.frm_contestacion_ed_actaemplazamiento = e.row.data.actaemplazamiento;
      this.frm_contestacion_ed_acuerdoemplazamiento = e.row.data.acuerdoemplazamiento;
      this.frm_contestacion_ed_cedulanotificacion = e.row.data.cedulanotificacion;
      this.frm_contestacion_ed_acuerdoalegatos = e.row.data.acuerdoalegatos;
      this.frm_contestacion_ed_resolucionadministrativa = e.row.data.resolucionadministrativa;
      this.frm_contestacion_ed_copiacertificadaexpediente = e.row.data.copiacertificadaexpediente;
      this.frm_contestacion_ed_acuerdocierre = e.row.data.acuerdocierre;
      this.frm_contestacion_ed_visita = e.row.data.visita;
      this.frm_contestacion_ed_prorroga = e.row.data.prorroga;

      // Obtenememos irregularidades de contestacion 


      let id = this.vidot;
      let myJSON = JSON.stringify({
        pid: id,

      });


      let myJSON2 = JSON.stringify({
        pid: this.frm_contestacion_id,

      });

      this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoContestacionirre(myJSON2).then(data => {
        this.dataSourceContestacionIrregularidad = data
      }
      );

      this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoContestacionIrrEdicion(myJSON).then(data => {
        this.dataSourceContestacionIrregularidadEdicion = data
      }
      );



      this.loading = true;
      this.popupVisible5 = true;

    },
    editAlegato(e) {
      this.frm_alegatos_id = e.row.data.id;
      this.frm_alegatos_nooficio = e.row.data.alegatos_nooficio;
      this.frm_alegatos_vencimiento = e.row.data.alegatos_vencimiento;
      this.frm_alegatos_notificacion = e.row.data.alegatos_notificacion;



      this.loading = true;
      this.popupVisible6 = true;

    },

    editResolucion(e) {
      this.frm_resolucion_id = e.row.data.id;
      this.frm_resolucion_nooficio = e.row.data.resolucion_nooficio;
      this.frm_resolucion_fecharesolucion = e.row.data.resolucion_fecharesolucion;
      this.frm_resolucion_multa = e.row.data.resolucion_multa;
      this.frm_resolucion_multaimporte = e.row.data.resolucion_multaimporte;

      let id = this.vidot;
      let myJSON = JSON.stringify({
        pid: id,

      });


      this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoResolucionirre(myJSON).then(data => {
        this.dataSourceProcedimientoResoIrr = data
      }
      );

      this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoContestacionIrrEdicion(myJSON).then(data => {
        this.dataSourceContestacionIrregularidadEdicion = data
      }
      );

      //Se aperturan tipo de proceso

      // Se aperturan resolucion de proceso registros
      this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoResolucionProc(myJSON).then(data => {
        this.dataSourceProcedimientoResolucionProc = data
      }
      );

      // Se aperturan resolucion de proceso registros

      this.tipoprocesoresolucionService.getTipoProcesoResolucion().then(data => {
        this.dataSourceTipoProcesoResolucion = data
      }
      );

      this.loading = true;
      this.popupVisible7 = true;

    },
    editExpediente(e) {
      this.frm_id = e.row.data.id;
      this.frm_exp_expediente = e.row.data.expediente;
      this.frm_exp_orden = e.row.data.orden;
      this.frm_exp_acta = e.row.data.acta;
      this.frm_exp_competencia = e.row.data.competenciaid;
      this.frm_exp_tipoacuerdo = e.row.data.tipoacuerdoid;
      this.frm_exp_fechaemision = e.row.data.fechaemision;
      this.frm_exp_fechaacuerdo = e.row.data.fechaacuerdo;

      this.loading = true;
      this.popupVisible = true;

    },

    editExpedienteMedidasSeguridad(e) {
      this.frm_id_medida = e.row.data.id;
      this.frm_exp_orden_medida = e.row.data.orden;
      this.frm_exp_acta_medida = e.row.data.acta;
      this.frm_exp_observacion_medida = e.row.data.observacion;

      this.loading = true;
      this.popupVisible8 = true;

    },

    saveRegisterIrr() {


      let err = 0;
      let msgerror = '';



      if (this.frm_irr_clave == null || this.frm_irr_clave == '') {
        err = 1;
        msgerror = 'Ingresar clave';
      }

      if (err == 0) {
        if (this.frm_irr_descripcion == null || this.frm_irr_descripcion == '') {
          err = 1;
          msgerror = 'Ingresar descripcion';
        }
      }
      if (err == 0) {
        if (this.frm_irr_dia == null || this.frm_irr_dia == '') {
          err = 1;
          msgerror = 'Ingresar dias';
        }
      }
      if (err == 0) {
        if (this.frm_irr_tipodia == null || this.frm_irr_tipodia == '') {
          err = 1;
          msgerror = 'Ingresar tipo dia';
        }
      }

      if (this.frm_irr_observacion == null || this.frm_irr_observacion == '') {
        this.frm_irr_observacion = '';
      }





      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;

      let myJSON = JSON.stringify({
        pordentrabajoid: this.vidot,
        pclave: this.frm_irr_clave,
        pdescripcion: this.frm_irr_descripcion,
        ptipodia: this.frm_irr_tipodia,
        pdiashabiles: this.frm_irr_dia,
        pobservacion: this.frm_irr_observacion,
        pestatusid: 0,
        pusuarioregistroid: idusuario,
      });





      if (err == 0) {
        this.ordentrabajoresultadoService.createOrdenTrabajoIrregularidad(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha insertado el registro';

            notify({
              message: this.message,
            }, this.type, 4000);

            this.popupVisible2 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;

            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;

        notify({
          message: this.message,
        }, this.type, 5000);

      }




    },

    saveRegisterEditIrr() {


      let err = 0;
      let msgerror = '';



      if (this.frm_irr_ed_clave == null || this.frm_irr_ed_clave == '') {
        err = 1;
        msgerror = 'Ingresar clave';
      }

      if (err == 0) {
        if (this.frm_irr_ed_descripcion == null || this.frm_irr_ed_descripcion == '') {
          err = 1;
          msgerror = 'Ingresar descripcion';
        }
      }
      if (err == 0) {
        if (this.frm_irr_ed_dia == null || this.frm_irr_ed_dia == '') {
          err = 1;
          msgerror = 'Ingresar dias';
        }
      }
      if (err == 0) {
        if (this.frm_irr_ed_tipodia == null || this.frm_irr_ed_tipodia == '') {
          err = 1;
          msgerror = 'Ingresar tipo dia';
        }
      }
      if (err == 0) {
        if (this.frm_irr_ed_estatus == null || this.frm_irr_ed_estatus == '') {
          err = 1;
          msgerror = 'Ingresar estatus';
        }
      }

      if (err == 0) {
        if (this.frm_irr_ed_medida == null || this.frm_irr_ed_medida == '') {
          err = 1;
          msgerror = 'Ingresar medida';
        }
      }


      if (this.frm_irr_ed_observacion == null || this.frm_irr_ed_observacion == '') {
        this.frm_irr_ed_observacion = '';
      }





      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;

      let myJSON = JSON.stringify({
        pid: this.frm_irr_id,
        pordentrabajoid: this.vidot,
        pclave: this.frm_irr_ed_clave,
        pdescripcion: this.frm_irr_ed_descripcion,
        ptipodia: this.frm_irr_ed_tipodia,
        pdiashabiles: this.frm_irr_ed_dia,
        pobservacion: this.frm_irr_ed_observacion,
        pestatusid: this.frm_irr_ed_estatus,
        pusuarioregistroid: idusuario,
        pmedida: this.frm_irr_ed_medida,
      });





      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoIrregularidad(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';

            notify({
              message: this.message,
            }, this.type, 4000);

            this.popupVisible3 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;

            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        notify({
          message: this.message,
        }, this.type, 5000);

      }




    },
    editRegister() {


      let err = 0;
      let msgerror = '';



      if (this.frm_exp_expediente == null || this.frm_exp_expediente == '') {
        err = 1;
        msgerror = 'Ingresar expediente';
      }

      if (err == 0) {
        if (this.frm_exp_orden == null || this.frm_exp_orden == '') {
          err = 1;
          msgerror = 'Ingresar orden inspeccion';
        }
      }
      if (err == 0) {
        if (this.frm_exp_acta == null || this.frm_exp_acta == '') {
          err = 1;
          msgerror = 'Ingresar acta';
        }
      }
      if (err == 0) {
        if (this.frm_exp_competencia == null || this.frm_exp_competencia == '') {
          err = 1;
          msgerror = 'Ingresar competencia';
        }
      }
      if (err == 0) {
        if (this.frm_exp_tipoacuerdo == null || this.frm_exp_tipoacuerdo == '') {
          err = 1;
          msgerror = 'Ingresar tipo acuerdo';
        }
      }
      if (err == 0) {
        if (this.frm_exp_fechaemision == null || this.frm_exp_fechaemision == '') {
          err = 1;
          msgerror = 'Ingresar fecha de notificacion';
        }
      }
      if (err == 0) {
        if (this.frm_exp_fechaacuerdo == null || this.frm_exp_fechaacuerdo == '') {
          err = 1;
          msgerror = 'Ingresar fecha acuerdo';
        }
      }

      let myJSON = JSON.stringify({
        pid: this.frm_id,
        pexpediente: this.frm_exp_expediente,
        porden: this.frm_exp_orden,
        pacta: this.frm_exp_acta,
        pcompetenciaid: this.frm_exp_competencia,
        ptipoacuerdoid: this.frm_exp_tipoacuerdo,
        pfechaemision: moment(this.frm_exp_fechaemision).format('YYYY-MM-DD'),
        pfechaacuerdo: moment(this.frm_exp_fechaacuerdo).format('YYYY-MM-DD'),
      });

      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimiento(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;

            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;

        notify({
          message: this.message,
        }, this.type, 4000);

      }




    },


    saveRegisterMedidaSeguridad() {


      let err = 0;
      let msgerror = '';



      if (this.frm_med_seg_clave == null || this.frm_med_seg_clave == '') {
        err = 1;
        msgerror = 'Ingresar clave';
      }

      if (err == 0) {
        if (this.frm_med_seg_descripcion == null || this.frm_med_seg_descripcion == '') {
          err = 1;
          msgerror = 'Ingresar descripcion';
        }
      }

      if (this.frm_med_seg_observacion == null || this.frm_med_seg_observacion == '') {
        this.frm_med_seg_observacion = '';
      }

      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;

      let myJSON = JSON.stringify({
        pordentrabajoid: this.vidot,
        pclave: this.frm_med_seg_clave,
        pdescripcion: this.frm_med_seg_descripcion,
        pobservacion: this.frm_med_seg_observacion,
        pestatusid: 0,
        pusuarioregistroid: idusuario,
      });


      if (err == 0) {
        this.ordentrabajoresultadoService.createOrdenTrabajoProcedimientoMedidas(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha insertado el registro';

            notify({
              message: this.message,
            }, this.type, 4000);

            this.popupVisible9 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;

            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;

        notify({
          message: this.message,
        }, this.type, 5000);

      }
    },

    saveRegisterEditMedidaSeguridad() {


      let err = 0;
      let msgerror = '';



      if (this.frm_med_seg_ed_clave == null || this.frm_med_seg_ed_clave == '') {
        err = 1;
        msgerror = 'Ingresar clave';
      }

      if (err == 0) {
        if (this.frm_med_seg_ed_descripcion == null || this.frm_med_seg_ed_descripcion == '') {
          err = 1;
          msgerror = 'Ingresar descripcion';
        }
      }
      if (err == 0) {
        if (this.frm_med_seg_ed_estatus == null || this.frm_med_seg_ed_estatus == '') {
          err = 1;
          msgerror = 'Ingresar estatus';
        }
      }


      if (this.frm_med_seg_ed_observacion == null || this.frm_med_seg_ed_observacion == '') {
        this.frm_med_seg_ed_observacion = '';
      }


      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;

      let myJSON = JSON.stringify({
        pid: this.frm_med_seg_id,
        pordentrabajoid: this.vidot,
        pclave: this.frm_med_seg_ed_clave,
        pdescripcion: this.frm_med_seg_ed_descripcion,
        pobservacion: this.frm_med_seg_ed_observacion,
        pestatusid: this.frm_med_seg_ed_estatus,
        pusuarioregistroid: idusuario,
      });





      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoMedidas(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';

            notify({
              message: this.message,
            }, this.type, 4000);

            this.popupVisible10 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;

            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;

        notify({
          message: this.message,
        }, this.type, 5000);

      }




    },

    editRegisterMedidasSeguridad() {

      let err = 0;
      let msgerror = '';

      if (this.frm_exp_orden_medida == null || this.frm_exp_orden_medida == '') {
        err = 1;
        msgerror = 'Ingresar orden inspeccion';
      }

      if (err == 0) {
        if (this.frm_exp_acta_medida == null || this.frm_exp_acta_medida == '') {
          err = 1;
          msgerror = 'Ingresar acta';
        }
      }

      let myJSON = JSON.stringify({
        pid: this.frm_id_medida,
        porden: this.frm_exp_orden_medida,
        pacta: this.frm_exp_acta_medida,
        pobservacion: this.frm_exp_observacion_medida,
      });

      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoMedidasExpediente(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';


            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible8 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;

            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;

        notify({
          message: this.message,
        }, this.type, 4000);

      }




    },
    saveAddContestacion() {


      let err = 0;
      let msgerror = '';



      if (this.frm_contestacion_fechaescrito == null || this.frm_contestacion_fechaescrito == '') {
        err = 1;
        msgerror = 'Ingresar fecha escrito';
      }

      if (err == 0) {
        if (this.frm_contestacion_comentarios == null || this.frm_contestacion_comentarios == '') {
          err = 1;
          msgerror = 'Ingresar comentarios';
        }
      }
      if (err == 0) {
        if (this.frm_contestacion_tiporespuestaid == null || this.frm_contestacion_tiporespuestaid == '') {
          err = 1;
          msgerror = 'Ingresar tipo respuesta';
        }
      }
      let vcopiacertificada = this.frm_contestacion_copiacertificada == true ? 1 : 0;
      let vordeninspeccion = this.frm_contestacion_ordeninspeccion == true ? 1 : 0;
      let vactaemplazamiento = this.frm_contestacion_actaemplazamiento == true ? 1 : 0;
      let vacuerdoemplazamiento = this.frm_contestacion_acuerdoemplazamiento == true ? 1 : 0;
      let vcedulanotificacion = this.frm_contestacion_cedulanotificacion == true ? 1 : 0;
      let vacuerdoalegatos = this.frm_contestacion_acuerdoalegatos == true ? 1 : 0;
      let vresolucionadministrativa = this.frm_contestacion_resolucionadministrativa == true ? 1 : 0;
      let vcopiacertificadaexpediente = this.frm_contestacion_copiacertificadaexpediente == true ? 1 : 0;
      let vacuerdocierre = this.frm_contestacion_acuerdocierre == true ? 1 : 0;
      let vvisita = this.frm_contestacion_visita == true ? 1 : 0;
      let vprorroga = this.frm_contestacion_prorroga == true ? 1 : 0;



      let myJSON = JSON.stringify({
        pordentrabajoid: this.vidot,
        pfechaescrito: moment(this.frm_contestacion_fechaescrito).format('YYYY-MM-DD'),
        pcomentarios: this.frm_contestacion_comentarios,
        ptiporespuestaid: this.frm_contestacion_tiporespuestaid,
        pcopiacertificada: vcopiacertificada,
        pordeninspeccion: vordeninspeccion,
        pactaemplazamiento: vactaemplazamiento,
        pacuerdoemplazamiento: vacuerdoemplazamiento,
        pcedulanotificacion: vcedulanotificacion,
        pacuerdoalegatos: vacuerdoalegatos,
        presolucionadministrativa: vresolucionadministrativa,
        pcopiacertificadaexpediente: vcopiacertificadaexpediente,
        pacuerdocierre: vacuerdocierre,
        pvisita: vvisita,
        pprorroga: vprorroga,

      });

      if (err == 0) {
        this.ordentrabajoresultadoService.createOrdenTrabajoProcedimientoContestacion(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha insertado el registro';

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible4 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;

            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;

        notify({
          message: this.message,
        }, this.type, 4000);

      }




    },

    saveContestacion() {


      let err = 0;
      let msgerror = '';



      if (this.frm_contestacion_ed_fechaescrito == null || this.frm_contestacion_ed_fechaescrito == '') {
        err = 1;
        msgerror = 'Ingresar fecha escrito';
      }

      if (err == 0) {
        if (this.frm_contestacion_ed_comentarios == null || this.frm_contestacion_ed_comentarios == '') {
          err = 1;
          msgerror = 'Ingresar comentarios';
        }
      }
      if (err == 0) {
        if (this.frm_contestacion_ed_tiporespuestaid == null || this.frm_contestacion_ed_tiporespuestaid == '') {
          err = 1;
          msgerror = 'Ingresar tipo respuesta';
        }
      }
      let vcopiacertificada = this.frm_contestacion_ed_copiacertificada == true ? 1 : 0;
      let vordeninspeccion = this.frm_contestacion_ed_ordeninspeccion == true ? 1 : 0;
      let vactaemplazamiento = this.frm_contestacion_ed_actaemplazamiento == true ? 1 : 0;
      let vacuerdoemplazamiento = this.frm_contestacion_ed_acuerdoemplazamiento == true ? 1 : 0;
      let vcedulanotificacion = this.frm_contestacion_ed_cedulanotificacion == true ? 1 : 0;
      let vacuerdoalegatos = this.frm_contestacion_ed_acuerdoalegatos == true ? 1 : 0;
      let vresolucionadministrativa = this.frm_contestacion_ed_resolucionadministrativa == true ? 1 : 0;
      let vcopiacertificadaexpediente = this.frm_contestacion_ed_copiacertificadaexpediente == true ? 1 : 0;
      let vacuerdocierre = this.frm_contestacion_ed_acuerdocierre == true ? 1 : 0;
      let vvisita = this.frm_contestacion_ed_visita == true ? 1 : 0;
      let vprorroga = this.frm_contestacion_ed_prorroga == true ? 1 : 0;



      let myJSON = JSON.stringify({
        pid: this.frm_contestacion_id,
        pfechaescrito: moment(this.frm_contestacion_ed_fechaescrito).format('YYYY-MM-DD'),
        pcomentarios: this.frm_contestacion_ed_comentarios,
        ptiporespuestaid: this.frm_contestacion_ed_tiporespuestaid,
        pcopiacertificada: vcopiacertificada,
        pordeninspeccion: vordeninspeccion,
        pactaemplazamiento: vactaemplazamiento,
        pacuerdoemplazamiento: vacuerdoemplazamiento,
        pcedulanotificacion: vcedulanotificacion,
        pacuerdoalegatos: vacuerdoalegatos,
        presolucionadministrativa: vresolucionadministrativa,
        pcopiacertificadaexpediente: vcopiacertificadaexpediente,
        pacuerdocierre: vacuerdocierre,
        pvisita: vvisita,
        pprorroga: vprorroga,

      });

      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoContestacion(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible5 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;

            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;

        notify({
          message: this.message,
        }, this.type, 4000);

      }




    },

    editAlegatoRegister() {


      let err = 0;
      let msgerror = '';



      if (this.frm_alegatos_nooficio == null || this.frm_alegatos_nooficio == '') {
        err = 1;
        msgerror = 'Ingresar oficio';
      }

      if (err == 0) {
        if (this.frm_alegatos_vencimiento == null || this.frm_alegatos_vencimiento == '') {
          err = 1;
          msgerror = 'Ingresar fecha vencimiento';
        }
      }
      if (err == 0) {
        if (this.frm_alegatos_notificacion == null || this.frm_alegatos_notificacion == '') {
          err = 1;
          msgerror = 'Ingresar fecha notificacion';
        }
      }

      let myJSON = JSON.stringify({
        pid: this.frm_alegatos_id,
        palegatos_nooficio: this.frm_alegatos_nooficio,
        palegatos_notificacion: moment(this.frm_alegatos_notificacion).format('YYYY-MM-DD'),
        palegatos_vencimiento: moment(this.frm_alegatos_vencimiento).format('YYYY-MM-DD'),


      });

      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoaleg(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';


            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible6 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;

            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;

        notify({
          message: this.message,
        }, this.type, 4000);

      }




    },
    saveResolucion() {


      let err = 0;
      let msgerror = '';



      if (this.frm_resolucion_nooficio == null || this.frm_resolucion_nooficio == '') {
        err = 1;
        msgerror = 'Ingresar numero resolucion';
      }

      if (err == 0) {
        if (this.frm_resolucion_fecharesolucion == null || this.frm_resolucion_fecharesolucion == '') {
          err = 1;
          msgerror = 'Ingresar fecha resolucion';
        }
      }
      if (err == 0) {
        if (this.frm_resolucion_multa == true) {
          if (this.frm_resolucion_multaimporte == null || this.frm_resolucion_multaimporte == '' || parseFloat(this.frm_resolucion_multaimporte) <= 0) {
            err = 1;
            msgerror = 'Ingresar importe multa';
          }
        }
        else {
          this.frm_resolucion_multaimporte = 0;
        }
      }

      let vmulta = this.frm_resolucion_multa == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pid: this.frm_resolucion_id,
        presolucion_nooficio: this.frm_resolucion_nooficio,
        presolucion_fecharesolucion: moment(this.frm_resolucion_fecharesolucion).format('YYYY-MM-DD'),
        presolucion_multa: vmulta,
        presolucion_multaimporte: this.frm_resolucion_multaimporte,


      });

      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoreso(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';


            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible7 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;

            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }

        });
      } else {
        this.type = 'error';
        this.message = msgerror;

        notify({
          message: this.message,
        }, this.type, 4000);

      }




    },
    deleteContestacion(e) {


      let myJSON = JSON.stringify({
        pid: e.data.id,

      });

      this.ordentrabajoresultadoService.deleteOrdenTrabajoProcedimientoContestacion(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'El registro ha sido eliminado',
          }, 'success', 5000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          this.loadDataInfo();

        }


      });


    },

    onUploaded(e) {
      let id = this.vidot;
      let res = JSON.parse(e.request.responseText);
      let archivosubido = res.image_name;
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
      let myJSON = JSON.stringify({
        ptipodocumentoid: this.valtipodocumento,
        pnombredocumento: this.valnombredocumento,
        pruta: archivosubido,
        pordentrabajoid: id,
        pusuarioregistroid: idusuario
      });

      let err = 0;
      let msgerror = '';

      if (this.valtipodocumento == null || this.valtipodocumento == '' || this.valtipodocumento == 'undefined') {
        err = 1;
        msgerror = 'Ingresar tipo documento';
      }

      if (err == 0) {
        if (this.valnombredocumento == null || this.valnombredocumento == '' || this.valnombredocumento == 'undefined') {
          err = 1;
          msgerror = 'Ingresar nombre documento';
        }
      }

      if (err == 0) {
        this.ordentrabajodocumentoService.createOrdenTrabajoDocumento(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.valtipodocumento = null;
            this.valnombredocumento = null;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }
    },
    onUploadError(e) {
      let xhttp = e.request;
      if (xhttp.status === 400) {
        e.message = e.error.responseText;
      }
      if (xhttp.readyState === 4 && xhttp.status === 0) {
        e.message = "Conexión rechazada";
      }
    },

    deleteRegisterIrregularidadReconsideracion(e) {


      let err = 0;



      let myJSON = JSON.stringify({
        pid: e.data.id,

      });



      if (err == 0) {
        this.ordentrabajoresultadoService.deleteOrdenTrabajoProcedimientoRecProcIrr(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      }



    },
    createRegisterIrregularidadReconsideracion(e) {


      let err = 0;
      let msgerror = '';

      if (e.data.ordentrabajoirregularidadid == null || e.data.ordentrabajoirregularidadid == '') {
        err = 1;
        msgerror = 'Ingresar irregularidad';
      }

      if (err == 0) {
        if (e.data.comentarios == null || e.data.comentarios == '') {
          err = 1;
          msgerror = 'Ingresar comentarios';
        }
      }

      if (err == 0) {
        if (e.data.fecharespuesta == null || e.data.fecharespuesta == '') {
          err = 1;
          msgerror = 'Ingresar fecha respuesta';
        }
      }

      if (err == 0) {
        if (e.data.respuestaid == null || e.data.respuestaid == '') {
          err = 1;
          msgerror = 'Ingresar respuesta';
        }
      }


      let myJSON = JSON.stringify({
        pordentrabajoprocedimientoresolucionprocesoid: this.frm_resolucion_proceso_id,
        pordentrabajoirregularidadid: e.data.ordentrabajoirregularidadid,
        pcomentarios: e.data.comentarios,
        pfecharespuesta: moment(e.data.fecharespuesta).format('YYYY-MM-DD'),
        prespuestaid: e.data.respuestaid,
      });



      if (err == 0) {
        this.ordentrabajoresultadoService.createOrdenTrabajoProcedimientoRecProcIrr(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha insertado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }



    },



    editRegisterIrregularidadReconsideracion(e) {


      let err = 0;
      let msgerror = '';

      if (e.data.ordentrabajoirregularidadid == null || e.data.ordentrabajoirregularidadid == '') {
        err = 1;
        msgerror = 'Ingresar irregularidad';
      }

      if (err == 0) {
        if (e.data.comentarios == null || e.data.comentarios == '') {
          err = 1;
          msgerror = 'Ingresar comentarios';
        }
      }

      if (err == 0) {
        if (e.data.fecharespuesta == null || e.data.fecharespuesta == '') {
          err = 1;
          msgerror = 'Ingresar fecha respuesta';
        }
      }

      if (err == 0) {
        if (e.data.respuestaid == null || e.data.respuestaid == '') {
          err = 1;
          msgerror = 'Ingresar respuesta';
        }
      }


      let myJSON = JSON.stringify({
        pid: e.data.id,
        pordentrabajoirregularidadid: e.data.ordentrabajoirregularidadid,
        pcomentarios: e.data.comentarios,
        pfecharespuesta: moment(e.data.fecharespuesta).format('YYYY-MM-DD'),
        prespuestaid: e.data.respuestaid,
      });



      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoRecProcIrr(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }

        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }



    },



    createRegisterIrregularidadFundamento(e) {


      let myJSON = JSON.stringify({
        pordentrabajoirregularidadid: e.data.ordentrabajoirregularidadid,
        pleydetalleid: e.data.leydetalleid,
      });

      this.ordentrabajoresultadoService.createOrdenTrabajoIrregularidadFundamento(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'El registro ha sido generado',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          this.loadDataInfo();

        }


      });


    },

    editRegisterIrregularidadFundamento(e) {


      let myJSON = JSON.stringify({
        pid: e.data.id,
        pordentrabajoirregularidadid: e.data.ordentrabajoirregularidadid,
        pleydetalleid: e.data.leydetalleid,
      });

      this.ordentrabajoresultadoService.updateOrdenTrabajoIrregularidadFundamento(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'El registro ha sido actualizado',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          this.loadDataInfo();

        }


      });


    },

    deleteRegisterIrregularidadFundamento(e) {


      let myJSON = JSON.stringify({
        pid: e.data.id,

      });

      this.ordentrabajoresultadoService.deleteOrdenTrabajoIrregularidadFundamento(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'El registro ha sido eliminado',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          this.loadDataInfo();

        }


      });


    },

    createRegisterMedidaFundamento(e) {


      let myJSON = JSON.stringify({
        pordentrabajomedidaseguridadid: e.data.ordentrabajomedidaseguridadid,
        pleydetalleid: e.data.leydetalleid,
      });

      this.ordentrabajoresultadoService.createOrdenTrabajoProcedimientoMedidasFundamento(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'El registro ha sido generado',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          this.loadDataInfo();

        }


      });


    },

    editRegisterMedidaFundamento(e) {


      let myJSON = JSON.stringify({
        pid: e.data.id,
        pordentrabajomedidaseguridadid: e.data.ordentrabajomedidaseguridadid,
        pleydetalleid: e.data.leydetalleid,
      });

      this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoMedidasFundamento(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'El registro ha sido actualizado',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          this.loadDataInfo();

        }


      });


    },

    deleteRegisterMedidaFundamento(e) {


      let myJSON = JSON.stringify({
        pid: e.data.id,

      });

      this.ordentrabajoresultadoService.deleteOrdenTrabajoProcedimientoMedidasFundamento(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'El registro ha sido eliminado',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          this.loadDataInfo();

        }


      });


    },

    editRegisterIrregularidad(e) {
      let id = this.vidot;
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;

      let myJSON = JSON.stringify({
        pid: e.data.id,
        pordentrabajoid: id,
        pclave: e.data.clave,
        pdescripcion: e.data.descripcion,
        pfechaemision: moment(e.data.fechaemision).format('YYYY-MM-DD'),
        pdiashabiles: e.data.diashabiles,
        pobservacion: e.data.observacion,
        pestatusid: e.data.estatusid,
        pusuarioregistroid: idusuario,
      });

      this.ordentrabajoresultadoService.updateOrdenTrabajoIrregularidad(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'El registro ha sido actualizado',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          this.loadDataInfo();

        }


      });


    },

    deleteRegisterIrregularidad(e) {


      let myJSON = JSON.stringify({
        pid: e.data.id,

      });

      this.ordentrabajoresultadoService.deleteOrdenTrabajoIrregularidad(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'El registro ha sido eliminado',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          this.loadDataInfo();

        }


      });


    },
    deleteRegisterMedida(e) {


      let myJSON = JSON.stringify({
        pid: e.data.id,

      });

      this.ordentrabajoresultadoService.deleteOrdenTrabajoProcedimientoMedidas(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'El registro ha sido eliminado',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
          this.loadDataInfo();

        }


      });


    },
    editRegisterCambioEtapa(e) {
      let id = this.vidot;
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
      let myJSON = JSON.stringify({
        pid: id,
        pusuario: idusuario,
        petapaid: e.data.etapaid,
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoCierreEtapa(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'OT Etapa ha sido actualizada',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);


        }


      });


    },
    editRegisterCambioEstatus(e) {

      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
      let myJSON = JSON.stringify({
        pid: this.vidot,
        pusuario: idusuario,
        pobservacion: e.data.observacion,
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoCierre(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'OT ha sido actualizada',
          }, 'success', 9000);
          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);


        }


      });

    },

    handleValueChange1(e) {
      console.log(e);
      this.eca_no = false;
      this.eca_parcial = false;
      this.eca_noaplica = false;
      this.eca_aplicagantt = false;

    },
    downloadfile(e) {
      let name_file = e.row.data.ruta;

      axios({
        url: process.env.VUE_APP_APIURL + '/api/archivodownload?name_file=' + name_file,
        method: 'GET',
        responseType: 'blob',

      }).then((res) => {
        var url = window.URL.createObjectURL((res.data));
        var namepdf = name_file;

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', namepdf);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });



    },
    handleValueChange2(e) {
      console.log(e);
      this.eca_si = false;
      this.eca_parcial = false;
      this.eca_noaplica = false;

    },
    handleValueChange3(e) {
      console.log(e);
      this.eca_no = false;
      this.eca_si = false;
      this.eca_noaplica = false;

    },
    handleValueChange4(e) {
      console.log(e);
      this.eca_no = false;
      this.eca_si = false;
      this.eca_parcial = false;
      this.eca_aplicagantt = false;


    },
    onExporting(e) {
      const doc = new jsPDF();
      exportDataGrid({
        jsPDFDocument: doc,
        component: e.component,
        indent: 5,
      }).then(() => {
        doc.save('ResultadosECA.pdf');
      });
    },
    onExporting2(e) {
      const doc = new jsPDF("landscape", "mm", "a4");

      exportDataGrid({
        jsPDFDocument: doc,
        component: e.component,
        indent: 5,
      }).then(() => {
        doc.save('ResultadoDetalleEvaluacion.pdf');
      });
    },
    onResourceAssigned(e) {

      let myJSON = JSON.stringify({
        pordentrabajoid: this.vidot,
        pordentrabajoplanid: e.values.taskId,
        pclientecontactoid: e.values.resourceId,

      });


      if (e.values.taskID != 0) {
        this.ordentrabajoplanService.createOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      }
    }, onResourceUnassigned(e) {

      let myJSON = JSON.stringify({
        pid: e.key,


      });

      if (e.key != 0) {
        this.ordentrabajoplanService.deleteOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      }
    },
    onTaskDeleted(e) {
      let err = 0;
      if (e.key != 0) {


        let myJSON = JSON.stringify({
          pid: e.key,
          pot: this.vidot
        });


        if (e.key != 0) {

          if (err == 0) {


            this.ordentrabajoplanService.deleteOrdenTrabajoPlan(myJSON).then(data => {

              if (data.err == 0) {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;

                notify({
                  message: this.message,
                }, this.type, 5000);
                this.popupVisible = false;
                this.loadDataInfo();
              } else {

                this.type = 'error';
                this.message = 'Ocurrio un error ' + data.msg;
                this.isVisible = true;
                notify({
                  message: 'Ocurrio un error ' + data.msg,
                }, this.type, 4000);


              }


            });




          } else {
            this.type = 'error';
            this.message = 'error';
            this.isVisible = true;
            notify({
              message: this.message,
            }, this.type, 4000);

          }
          e.cancel = true;
        }
      }
    },

    onTaskInserted(e) {
      let err = 0;
      let msgerror = '';

      let vfechainicio = '';
      let vfechafin = '';
      let vprogreso = '0';
      let vdescripcion = '';
      let vpadre = '';


      // Si esta indefinido no hubo cambio
      // De lo contrario si hubo cambio

      if (e.values.parentId === undefined || e.values.parentId === '' || e.values.parentId === 'null' || e.values.parentId === null) {
        vpadre = "-1";

      }
      else {
        vpadre = e.values.parentId;
      }
      vdescripcion = e.values.title;
      vfechainicio = moment(e.values.start).format('YYYY-MM-DD');
      vfechafin = moment(e.values.end).format('YYYY-MM-DD');

      let myJSON = JSON.stringify({

        pordentrabajoid: this.vidot,
        vpadre: vpadre,
        pdescripcion: vdescripcion,
        pfechainicio: vfechainicio,
        pfechafin: vfechafin,
        pprogreso: vprogreso
      });


      if (e.key != 0) {

        if (err == 0) {
          this.ordentrabajoplanService.createOrdenTrabajoPlan(myJSON).then(data => {

            if (data.err == 0) {
              this.type = 'success';
              this.message = 'Se ha insertado el registro';
              this.isVisible = true;

              notify({
                message: this.message,
              }, this.type, 5000);
              this.popupVisible = false;
              this.loadDataInfo();
            } else {

              this.type = 'error';
              this.message = 'Ocurrio un error ' + data.msg;
              this.isVisible = true;
              notify({
                message: 'Ocurrio un error ' + data.msg,
              }, this.type, 4000);


            }


          });
        } else {
          this.type = 'error';
          this.message = msgerror;
          this.isVisible = true;
          notify({
            message: this.message,
          }, this.type, 4000);

        }
        e.cancel = true;
      }



    },
    onTaskUpdating(e) {

      let err = 0;
      let msgerror = '';

      let vfechainicio = '';
      let vfechafin = '';
      let vprogreso = '';
      let vdescripcion = '';

      // Si esta indefinido no hubo cambio
      // De lo contrario si hubo cambio

      if (e.newValues.title === undefined || e.newValues.title === '' || e.newValues.title === 'null' || e.newValues.title === null) {
        vdescripcion = e.values.title;

      }
      else {
        if (e.newValues.title == undefined || e.newValues.title == null || e.newValues.title == '') {
          err = 1;
          msgerror = 'Ingresar descripción';
        }
        else {
          vdescripcion = e.newValues.title;

        }
      }


      if (e.newValues.start === undefined || e.newValues.start === '' || e.newValues.start === 'null' || e.newValues.start === null) {
        vfechainicio = moment(e.values.start).format('YYYY-MM-DD');

      }
      else {
        if (e.newValues.start == undefined || e.newValues.start == null || e.newValues.start == '') {
          err = 1;
          msgerror = 'Ingresar fecha inicio';
        }
        else {
          vfechainicio = moment(e.newValues.start).format('YYYY-MM-DD');

        }
      }

      if (e.newValues.end === undefined || e.newValues.end === '' || e.newValues.end === 'null' || e.newValues.end === null) {
        vfechafin = moment(e.values.end).format('YYYY-MM-DD');
      }
      else {
        if (e.newValues.end == undefined || e.newValues.end == null || e.newValues.end == '') {
          err = 1;
          msgerror = 'Ingresar fecha fin';
        }
        else {
          vfechafin = moment(e.newValues.end).format('YYYY-MM-DD');
        }
      }

      if (e.newValues.progress === undefined || e.newValues.progress === '' || e.newValues.progress === 'null' || e.newValues.progress === null) {


        vprogreso = e.values.progress;
      }
      else {
        if (e.newValues.progress === undefined || e.newValues.progress == null || e.newValues.progress == '') {
          err = 1;
          msgerror = 'Ingresar progreso';
        }
        else {

          vprogreso = e.newValues.progress;
        }
      }


      let myJSON = JSON.stringify({
        pid: e.key,
        pordentrabajoid: this.vidot,
        pdescripcion: vdescripcion,
        pfechainicio: vfechainicio,
        pfechafin: vfechafin,
        pprogreso: vprogreso
      });



      if (e.key != 0) {

        if (err == 0) {
          this.ordentrabajoplanService.updateOrdenTrabajoPlan(myJSON).then(data => {

            if (data.err == 0) {
              this.type = 'success';
              this.message = 'Se ha actualizado el registro';
              this.isVisible = true;

              notify({
                message: this.message,
              }, this.type, 5000);
              this.popupVisible = false;
              this.loadDataInfo();
            } else {

              this.type = 'error';
              this.message = 'Ocurrio un error ' + data.msg;
              this.isVisible = true;
              notify({
                message: 'Ocurrio un error ' + data.msg,
              }, this.type, 4000);


            }


          });
        } else {
          this.type = 'error';
          this.message = msgerror;
          this.isVisible = true;
          notify({
            message: this.message,
          }, this.type, 4000);

        }
        e.cancel = true;
      }
    },

    showPopup(data) {

      console.log(data);
      this.loading = true;

      this.popupVisible = true;

    },
    editECA(e) {
      this.eca_id = e.row.data.id;
      this.eca_materia = e.row.data.habilidad;
      this.eca_recomendacion = e.row.data.recomendacion;
      this.eca_observacion = e.row.data.observacion;
      this.eca_pregunta = e.row.data.descripcion;
      this.eca_si = e.row.data.valorsi;
      this.eca_no = e.row.data.valorno;
      this.eca_parcial = e.row.data.valorparcial;
      this.eca_noaplica = e.row.data.noaplica;
      this.eca_fundamentos = e.row.data.fundamentos;
      this.showPopup({ ...e.row.data });
    },
    saveClick() {
      let vsi = this.eca_si == true ? 1 : 0;
      let vno = this.eca_no == true ? 1 : 0;
      let vparcial = this.eca_parcial == true ? 1 : 0;
      let vnoaplica = this.eca_noaplica == true ? 1 : 0;
      let vaplicagantt = this.eca_aplicagantt == true ? 1 : 0;
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
      let myJSON = JSON.stringify({
        pid: this.eca_id,
        pvalorsi: vsi,
        pvalorno: vno,
        pvalorparcial: vparcial,
        pnoaplica: vnoaplica,
        pusuarioregistroid: idusuario,
        pdiashabiles: 0,
        pobservacion: this.eca_observacion,
        precomendacion: this.eca_recomendacion,
        paplicagantt: vaplicagantt,
      });

      /** VALIDACIONES DE CAPTURA EN FUNCION DE LO CAPTURADO */
      /**
       * 1 Debe tener un resultado capturado
       * 2. Si el valor es SI y No Aplica la recomendacion y observacion no son necesarias
       * 3. Si el valor es parcial y NO la recomendacion y observacion son obligatorias
       */
      let err = 0;
      let msgerror = '';

      if (this.eca_si == false && this.eca_no == false && this.eca_parcial == false && this.eca_noaplica == false) {
        err = 1;
        msgerror = 'Ingresar resultado';
      } else {
        if (this.eca_no == true || this.eca_parcial == true) {
          if (this.eca_observacion == '' || this.eca_recomendacion == '') {
            err = 1;
            msgerror = 'Ingresar observacion y recomendacion';
          }
        }
      }

      if (err == 0) {
        if (this.eca_si == true) {
          this.eca_observacion = '';
          this.eca_recomendacion = '';
        }
      }

      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoResultado(myJSON).then(data => {

          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisible = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }





    },


    saveRegistertps6() {

      let err = 0;
      let msgerror = '';



      if (this.frm_ampliacion_fechanotificacion == '' || this.frm_ampliacion_fechanotificacion == null) {
        err = 1;
        msgerror = 'Ingresar fecha notificacion';
      }

      if (err == 0) {
        if (this.frm_ampliacion_comentarios == '' || this.frm_ampliacion_comentarios == null) {
          err = 1;
          msgerror = 'Ingresar comentarios';
        }
      }


      if (err == 0) {
        if (this.frm_ampliacion_dias == '' || this.frm_ampliacion_dias == null) {
          err = 1;
          msgerror = 'Ingresar dias';
        }
      }


      if (err == 0) {
        if (this.frm_ampliacion_fechavencimiento == '' || this.frm_ampliacion_fechavencimiento == null) {
          err = 1;
          msgerror = 'Ingresar fecha vencimiento';
        }
      }



      let myJSON = JSON.stringify({
        pid: this.frm_resolucion_proceso_id,
        pampliacion_fechanotificacion: moment(this.frm_ampliacion_fechanotificacion).format('YYYY-MM-DD'),
        pampliacion_comentarios: this.frm_ampliacion_comentarios,
        pampliacion_dias: this.frm_ampliacion_dias,
        pampliacion_fechavencimiento: moment(this.frm_ampliacion_fechavencimiento).format('YYYY-MM-DD')
      });



      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoResolucionProcTps6(myJSON).then(data => {

          if (data.err == 0) {
            let id = this.vidot;
            let myJSON2 = JSON.stringify({
              pid: id,
            });
            // Se aperturan resolucion de proceso registros
            this.dataSourceProcedimientoResolucionProc = null;
            this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoResolucionProc(myJSON2).then(data => {
              this.dataSourceProcedimientoResolucionProc = data
            }
            );
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisibletps6 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }





    },

    saveRegistertps1() {

      let err = 0;
      let msgerror = '';

      if (this.frm_conmutacion_proyecto == '' || this.frm_conmutacion_proyecto == null) {
        err = 1;
        msgerror = 'Ingresar descripcion proyecto';
      }

      if (err == 0) {
        if (this.frm_conmutacion_comentarios == '' || this.frm_conmutacion_comentarios == null) {
          err = 1;
          msgerror = 'Ingresar comentarios';
        }
      }

      let vproviable = this.frm_conmutacion_proyectoviable == true ? 1 : 0;

      let myJSON = JSON.stringify({
        pid: this.frm_resolucion_proceso_id,
        pconmutacion_proyecto: this.frm_conmutacion_proyecto,
        pconmutacion_proyectoviable: vproviable,
        pconmutacion_comentarios: this.frm_conmutacion_comentarios,
      });



      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoResolucionProcTps1(myJSON).then(data => {

          if (data.err == 0) {
            let id = this.vidot;
            let myJSON2 = JSON.stringify({
              pid: id,
            });
            // Se aperturan resolucion de proceso registros
            this.dataSourceProcedimientoResolucionProc = null;
            this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoResolucionProc(myJSON2).then(data => {
              this.dataSourceProcedimientoResolucionProc = data
            }
            );
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisibletps1 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }





    },

    saveRegistertps2() {

      let err = 0;
      let msgerror = '';


      if (err == 0) {
        if (this.frm_revision_comentarios == '' || this.frm_revision_comentarios == null) {
          err = 1;
          msgerror = 'Ingresar comentarios';
        }
      }



      let myJSON = JSON.stringify({
        pid: this.frm_resolucion_proceso_id,
        prevision_comentarios: this.frm_revision_comentarios,

      });



      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoResolucionProcTps2(myJSON).then(data => {

          if (data.err == 0) {
            let id = this.vidot;
            let myJSON2 = JSON.stringify({
              pid: id,
            });
            // Se aperturan resolucion de proceso registros
            this.dataSourceProcedimientoResolucionProc = null;
            this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoResolucionProc(myJSON2).then(data => {
              this.dataSourceProcedimientoResolucionProc = data
            }
            );
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisibletps2 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }





    },


    saveRegistertps4() {

      let err = 0;
      let msgerror = '';


      if (err == 0) {
        if (this.frm_juicioamparo_comentarios == '' || this.frm_juicioamparo_comentarios == null) {
          err = 1;
          msgerror = 'Ingresar comentarios';
        }
      }



      let myJSON = JSON.stringify({
        pid: this.frm_resolucion_proceso_id,
        pjuicioamparo_comentarios: this.frm_juicioamparo_comentarios,

      });



      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoResolucionProcTps4(myJSON).then(data => {

          if (data.err == 0) {
            let id = this.vidot;
            let myJSON2 = JSON.stringify({
              pid: id,
            });
            // Se aperturan resolucion de proceso registros
            this.dataSourceProcedimientoResolucionProc = null;
            this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoResolucionProc(myJSON2).then(data => {
              this.dataSourceProcedimientoResolucionProc = data
            }
            );
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisibletps4 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }





    },



    saveRegistertps5() {

      let err = 0;
      let msgerror = '';


      if (err == 0) {
        if (this.frm_juicionulidad_comentarios == '' || this.frm_juicionulidad_comentarios == null) {
          err = 1;
          msgerror = 'Ingresar comentarios';
        }
      }



      let myJSON = JSON.stringify({
        pid: this.frm_resolucion_proceso_id,
        pjucionulidad_comentarios: this.frm_juicionulidad_comentarios,

      });



      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoResolucionProcTps5(myJSON).then(data => {

          if (data.err == 0) {
            let id = this.vidot;
            let myJSON2 = JSON.stringify({
              pid: id,
            });
            // Se aperturan resolucion de proceso registros
            this.dataSourceProcedimientoResolucionProc = null;
            this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoResolucionProc(myJSON2).then(data => {
              this.dataSourceProcedimientoResolucionProc = data
            }
            );
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisibletps5 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }

        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }





    },



    saveRegistertps7() {

      let err = 0;
      let msgerror = '';


      if (err == 0) {
        if (this.frm_inconformidad_comentarios == '' || this.frm_inconformidad_comentarios == null) {
          err = 1;
          msgerror = 'Ingresar comentarios';
        }
      }



      let myJSON = JSON.stringify({
        pid: this.frm_resolucion_proceso_id,
        pinconformidad_comentarios: this.frm_inconformidad_comentarios,

      });



      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoResolucionProcTps7(myJSON).then(data => {

          if (data.err == 0) {
            let id = this.vidot;
            let myJSON2 = JSON.stringify({
              pid: id,
            });
            // Se aperturan resolucion de proceso registros
            this.dataSourceProcedimientoResolucionProc = null;
            this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoResolucionProc(myJSON2).then(data => {
              this.dataSourceProcedimientoResolucionProc = data
            }
            );
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisibletps7 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }





    },





    saveRegistertps8() {

      let err = 0;
      let msgerror = '';


      if (err == 0) {
        if (this.frm_otros_comentarios == '' || this.frm_otros_comentarios == null) {
          err = 1;
          msgerror = 'Ingresar comentarios';
        }
      }



      let myJSON = JSON.stringify({
        pid: this.frm_resolucion_proceso_id,
        potros_comentarios: this.frm_otros_comentarios,

      });



      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoResolucionProcTps8(myJSON).then(data => {

          if (data.err == 0) {
            let id = this.vidot;
            let myJSON2 = JSON.stringify({
              pid: id,
            });
            // Se aperturan resolucion de proceso registros
            this.dataSourceProcedimientoResolucionProc = null;
            this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoResolucionProc(myJSON2).then(data => {
              this.dataSourceProcedimientoResolucionProc = data
            }
            );
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisibletps8 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }





    },




    saveRegistertps9() {

      let err = 0;
      let msgerror = '';


      if (err == 0) {
        if (this.frm_desistimiento_comentarios == '' || this.frm_desistimiento_comentarios == null) {
          err = 1;
          msgerror = 'Ingresar comentarios';
        }
      }



      let myJSON = JSON.stringify({
        pid: this.frm_resolucion_proceso_id,
        pdesistimiento_comentarios: this.frm_desistimiento_comentarios,

      });



      if (err == 0) {
        this.ordentrabajoresultadoService.updateOrdenTrabajoProcedimientoResolucionProcTps9(myJSON).then(data => {

          if (data.err == 0) {
            let id = this.vidot;
            let myJSON2 = JSON.stringify({
              pid: id,
            });
            // Se aperturan resolucion de proceso registros
            this.dataSourceProcedimientoResolucionProc = null;
            this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoResolucionProc(myJSON2).then(data => {
              this.dataSourceProcedimientoResolucionProc = data
            }
            );
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;

            notify({
              message: this.message,
            }, this.type, 5000);
            this.popupVisibletps9 = false;
            this.loadDataInfo();
          } else {

            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
            }, this.type, 4000);


          }


        });
      } else {
        this.type = 'error';
        this.message = msgerror;
        this.isVisible = true;
        notify({
          message: this.message,
        }, this.type, 4000);

      }





    },

    onClickCierraEtapa() {

      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
      let myJSON = JSON.stringify({
        pid: this.vidot,
        pusuario: idusuario,
        petapaid: this.valetapa,
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoCierreEtapa(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'OT Etapa ha sido actualizada',
          }, 'success', 9000);

          this.loadDataInfo();
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);


        }


      });


    },
    onClickCierra() {

      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
      let myJSON = JSON.stringify({
        pid: this.vidot,
        pusuario: idusuario,
        pobservacion: this.valcierre,
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoCierre(myJSON).then(data => {

        if (data.err == 0) {

          notify({
            message: 'OT ha sido actualizada',
          }, 'success', 9000);
          this.$router.push({ name: 'ordentrabajoresultadoconguia', params: { id: this.vidot } });
        } else {

          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);


        }


      });

    },
    loadDataInfo() {
      this.responsabilidadentregaService.getResponsabilidadEntrega().then(data => this.dataSourceResponsabilidadEntrega = data);
      
      let id = this.vidot;
      let myJSON = JSON.stringify({
        pid: id,

      });


      this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoContestacion(myJSON).then(data => {
        data.forEach(
          element => {

            if (element.copiacertificada == 1) {
              element.copiacertificada = true;
            } else {
              element.copiacertificada = false;
            }
            if (element.ordeninspeccion == 1) {
              element.ordeninspeccion = true;
            } else {
              element.ordeninspeccion = false;
            }
            if (element.actaemplazamiento == 1) {
              element.actaemplazamiento = true;
            } else {
              element.actaemplazamiento = false;
            }

            if (element.acuerdoemplazamiento == 1) {
              element.acuerdoemplazamiento = true;
            } else {
              element.acuerdoemplazamiento = false;
            }
            if (element.cedulanotificacion == 1) {
              element.cedulanotificacion = true;
            } else {
              element.cedulanotificacion = false;
            }
            if (element.acuerdoalegatos == 1) {
              element.acuerdoalegatos = true;
            } else {
              element.acuerdoalegatos = false;
            }



            if (element.resolucionadministrativa == 1) {
              element.resolucionadministrativa = true;
            } else {
              element.resolucionadministrativa = false;
            }
            if (element.copiacertificadaexpediente == 1) {
              element.copiacertificadaexpediente = true;
            } else {
              element.copiacertificadaexpediente = false;
            }
            if (element.copiacertificadaexpediente == 1) {
              element.copiacertificadaexpediente = true;
            } else {
              element.copiacertificadaexpediente = false;
            }

            if (element.acuerdocierre == 1) {
              element.acuerdocierre = true;
            } else {
              element.acuerdocierre = false;
            }
            if (element.visita == 1) {
              element.visita = true;
            } else {
              element.visita = false;
            }
            if (element.prorroga == 1) {
              element.prorroga = true;
            } else {
              element.prorroga = false;
            }


          });
        this.dataSourceProcedimientoCont = data
      });

      this.tiporespuestaService.getTipoRespuesta().then(data => {
        this.dataSourceTipoRespuesta = data
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoIrregularidad(myJSON).then(data => {
        this.dataSourceIrregularidad = data
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoIrregularidadFundamento(myJSON).then(data => {
        this.dataSourceIrregularidadFundamento = data
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId(myJSON).then(data => {
        this.customer = data
        this.pdfRazon = data.razonsocial;
        this.pdfFechaInicio = data.fechainicioplaneada;
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoProcedimiento(myJSON).then(data => {
        data.forEach(
          element => {

            if (element.resolucion_multa == 1) {
              element.resolucion_multa = true;
            } else {
              element.resolucion_multa = false;
            }


          });

        this.dataSourceProcedimiento = data
        this.dataSourceProcedimientoAleg = data
        this.dataSourceProcedimientoReso = data
      });

      this.tipodocumentoService.getTipoDocumento().then(data => {
        this.dataSourceDocumento = data;
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId2(myJSON).then(data => {
        this.dataSourceCambioEstatus = data
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoPorId2(myJSON).then(data => {
        this.dataSourceEtapaCierre = data
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoHistorialCambios(myJSON).then(data => {
        this.dataSourceHistorial = data;
      });

      this.ordentrabajodocumentoService.getOrdenTrabajoDocumento(myJSON).then(data => {
        this.dataSourceDocumentacion = data;
      });

      // GANTT
      this.ordentrabajoplanService.getOrdenTrabajoPlanTareas(myJSON).then(data => {
        this.tasks = data
      }
      );

      this.ordentrabajoplanService.getOrdenTrabajoPlanDependencias(myJSON).then(data => {
        this.dependencies = data
      }
      );


      this.ordentrabajoplanService.getOrdenTrabajoPlanContactoCliente(myJSON).then(data => { this.resources = data });


      this.ordentrabajoplanService.getOrdenTrabajoPlanRecursoTarea(myJSON).then(data => {
        this.resourceAssignments = data
      }
      );

      // FIN GANTT


      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoDetalle(myJSON).then(data => {
        data.forEach(
          element => {

            if (element.valorsi == 1) {
              element.valorsi = true;
            } else {
              element.valorsi = false;
            }
            if (element.valorno == 1) {
              element.valorno = true;
            } else {
              element.valorno = false;
            }
            if (element.valorparcial == 1) {
              element.valorparcial = true;
            } else {
              element.valorparcial = false;
            }
            if (element.noaplica == 1) {
              element.noaplica = true;
            } else {
              element.noaplica = false;
            }

          });
        this.dataSourceEstandar = data;
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoListadoDocumentacion(myJSON).then(data => {
        data.forEach(
          element => {
            if (element.entregado == 1) {
              element.entregado = true;
            } else {
              element.entregado = false;
            }
            if (element.aplica == 1) {
              element.aplica = true;
            } else {
              element.aplica = false;
            }
          });
        this.dataSourceListados = data;
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoResultadoListadoInformacion(myJSON).then(data => {
        data.forEach(
          element => {

            if (element.entregado == 1) {
              element.entregado = true;
            } else {
              element.entregado = false;
            }
            if (element.aplica == 1) {
              element.aplica = true;
            } else {
              element.aplica = false;
            }
          });
        this.dataSourceEntrega = data;
      });

      this.ordentrabajoetapaService.getOrdenTrabajoEtapaConsulta(myJSON).then(data => {
        this.dataSourceEtapa = data
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoMedidasExpediente(myJSON).then(data => {
        this.dataSourceExpedienteMedidas = data
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoMedidas(myJSON).then(data => {
        this.dataSourceMedidas = data
      });

      this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoMedidasFundamento(myJSON).then(data => {
        this.dataSourceMedidasFundamento = data
      });


      this.leyService.getLey().then(data => this.dataSourceLeyes = data);
      this.leyDetalleService.getLeyDetalle(myJSON).then(data => this.articulos2 = dataSourceArticulos = data);

    },
    createRegisterIrregularidadContestacionMedida(e) {


      let myJSON = JSON.stringify({
        pordentrabajoprocedimientocontestacionid: this.frm_contestacion_id,
        pordentrabajoirregularidadid: e.data.ordentrabajoirregularidadid
      });


      this.ordentrabajoresultadoService.createOrdenTrabajoProcedimientoContestacionirre(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha insertado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);

        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }

      });


    },



    createRegisterResolucionProceso(e) {


      let myJSON = JSON.stringify({
        pid: this.vidot,
        ptipoprocesoresolucionid: e.data.tipoprocesoresolucionid
      });


      this.ordentrabajoresultadoService.createOrdenTrabajoProcedimientoResolucionProc(myJSON).then(data => {

        if (data.err == 0) {

          let id = this.vidot;
          let myJSON2 = JSON.stringify({
            pid: id,

          });


          // Se aperturan resolucion de proceso registros
          this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoResolucionProc(myJSON2).then(data => {
            this.dataSourceProcedimientoResolucionProc = data
          }
          );

          this.type = 'success';
          this.message = 'Se ha insertado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);

        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }


      });


    },
    deleteRegisterResolucionProceso(e) {


      let myJSON = JSON.stringify({
        pid: e.data.id
      });


      this.ordentrabajoresultadoService.deleteOrdenTrabajoProcedimientoResolucionProc(myJSON).then(data => {

        if (data.err == 0) {

          let id = this.vidot;
          let myJSON2 = JSON.stringify({
            pid: id,

          });


          // Se aperturan resolucion de proceso registros
          this.ordentrabajoresultadoService.getOrdenTrabajoProcedimientoResolucionProc(myJSON2).then(data => {
            this.dataSourceProcedimientoResolucionProc = data
          }
          );
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);

        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }


      });


    },


    createRegisterIrregularidadResolucionMedida(e) {

      let myJSON = JSON.stringify({
        pordentrabajoprocedimientoid: this.frm_resolucion_id,
        pordentrabajoirregularidadid: e.data.ordentrabajoirregularidadid,
        ptiempo: e.data.tiempo,
        pfechavencimiento: moment(e.data.fechavencimiento).format('YYYY-MM-DD'),
      });


      this.ordentrabajoresultadoService.createOrdenTrabajoProcedimientoResolucionirre(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha insertado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);

        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }


      });


    },
    deleteRegisterIrregularidadResolucionMedida(e) {


      let myJSON = JSON.stringify({
        pid: e.data.id
      });


      this.ordentrabajoresultadoService.deleteOrdenTrabajoProcedimientoResolucionirre(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);

        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }


      });


    },
    deleteRegisterIrregularidadContestacionMedida(e) {


      let myJSON = JSON.stringify({
        pid: e.data.id
      });


      this.ordentrabajoresultadoService.deleteOrdenTrabajoProcedimientoContestacionirre(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);

        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }


      });


    },
    editProcedureListadoInformacion(e) {
      let vresponsabilidadentregaid = e.data.responsabilidadentregaid == true ? 1 : 0;
      let ventregado = e.data.entregado == true ? 1 : 0;
      let vaplica = e.data.aplica == true ? 1 : 0;
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
      let myJSON = JSON.stringify({
        pid: e.data.id,
        pentregado: ventregado,
        pfechaentrega: (e.data.fechaentrega == '') ? '9999-01-01' : moment(e.data.fechaentrega).format('YYYY-MM-DD'),
        pfechacompromisoentrega: (e.data.fechacompromisoentrega == '') ? '9999-01-01' : moment(e.data.fechacompromisoentrega).format('YYYY-MM-DD'),
        pusuarioregistroid: idusuario,
        pobservaciones: (e.data.observaciones == '') ? '' : e.data.observaciones,
        precibio: (e.data.recibio == '') ? '' : e.data.recibio,
        paplica: vaplica,
        presponsabilidadentregaid : vresponsabilidadentregaid
      });



      this.ordentrabajoresultadoService.updateOrdenTrabajoListadoInformacion(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);

        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }
        this.loadDataInfo();

      });

    },
    editProcedureListadoDocumentacion(e) {
      let vresponsabilidadentregaid = e.data.responsabilidadentregaid == true ? 1 : 0;
      let ventregado = e.data.entregado == true ? 1 : 0;
      let vaplica = e.data.aplica == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pid: e.data.id,
        pentregado: ventregado,
        pfechaentrega: moment(e.data.fechaentrega).format('YYYY-MM-DD'),
        paplica: vaplica,
        presponsabilidadentregaid : vresponsabilidadentregaid
      });



      this.ordentrabajoresultadoService.updateOrdenTrabajoListadoDocumentacion(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);

        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }
        this.loadDataInfo();

      });

    },
    editProcedureResultado(e) {

      let vsi = e.data.valorsi == true ? 1 : 0;
      let vno = e.data.valorno == true ? 1 : 0;
      let vparcial = e.data.valorparcial == true ? 1 : 0;
      let vnoaplica = e.data.noaplica == true ? 1 : 0;
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
      let myJSON = JSON.stringify({
        pid: e.data.id,
        pvalorsi: vsi,
        pvalorno: vno,
        pvalorparcial: vparcial,
        pnoaplica: vnoaplica,
        pusuarioregistroid: idusuario,
        pdiashabiles: 0,
        pobservacion: e.data.observacion,
        precomendacion: e.data.recomendacion,

      });



      this.ordentrabajoresultadoService.updateOrdenTrabajoResultado(myJSON).then(data => {

        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;

          notify({
            message: this.message,
          }, this.type, 9000);
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
          notify({
            message: 'Ocurrio un error ' + data.msg,
            position: {
              my: 'center top',
              at: 'center top',
            },
          }, 'error', 9000);
        }
        this.loadDataInfo();

      });

    },
    onInitNewRow(e) {
      e.data.entregado = true;
    },
  },
};
