
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxValidationRule,
  DxEmailRule

} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import PuestoService from '../services/puesto-service';
import ClienteService from '../services/cliente-service';
import ClienteContactoService from '../services/clientecontacto-service';
import Cookies from "js-cookie";
window.Cookies = Cookies;




export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxValidationRule,
    DxEmailRule
  },
  data() {
    return {
      procedimientos: null,
      dataSourceClienteContacto: null,
      dataSourcePuestos: null,
      dataSourceClientes: null,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Cliente',
        showTitle: true,
        width: 800,
        height: 'auto',
      },
    };
  },
  created() {
    this.puestoService = new PuestoService();
    this.clientecontactoService = new ClienteContactoService();
    this.clienteService = new ClienteService();

  },
  mounted() {
    this.loadDataInfo();
  },
  methods: {
    loadDataInfo() {
      this.puestoService.getPuesto().then(data => this.dataSourcePuestos = data);
      this.clientecontactoService.getClienteContacto().then(data => this.dataSourceClienteContacto = data);
      this.clienteService.getCliente().then(data => this.dataSourceClientes = data);
    },
    onSaved() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editProcedure(e) {


      let vmaterno = e.data.materno == null ? '' : e.data.materno;
      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pid: e.data.id,
        pclienteid: e.data.clienteid,
        pnombre: e.data.nombre,
        ppaterno: e.data.paterno,
        pmaterno: vmaterno,
        ptelefono: e.data.telefono,
        pcorreo: e.data.correo,
        pbactivo: vactivo,
        ppuestoid: e.data.puestoid,
      });


      this.clientecontactoService.updateClienteContacto(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });
    },
    deleteProcedure(e) {
      let myJSON = JSON.stringify({
        pid: e.data.id,
      });


      this.clientecontactoService.deleteClienteContacto(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });
    },
    createProcedure(e) {

      let vmaterno = e.data.materno == null ? '' : e.data.materno;
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pclienteid: e.data.clienteid,
        pnombre: e.data.nombre,
        ppaterno: e.data.paterno,
        pmaterno: vmaterno,
        ptelefono: e.data.telefono,
        pcorreo: e.data.correo,
        pusuarioregistroid: idusuario,
        pbactivo: vactivo,
        ppuestoid: e.data.puestoid,
      });


      this.clientecontactoService.createClienteContacto(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha creado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });
    },
    onInitNewRow(e) {
      e.data.bactivo = true;
    },
  },
};
