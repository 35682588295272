
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,

    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    DxFormItem,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import 'devextreme-vue/text-area';


import LeyDetalleService from '../services/leydetalle-service';

import Cookies from "js-cookie";
window.Cookies = Cookies;


export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,

        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,
        DxFormItem,
    },
    props: {
        supplierId: {
            type: Number,
            default: null,
        },
    },
    data() {

        return {

            procedimientos: null,
            dataSourceUsuarios: null,


            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Titulos',
                showTitle: true,
                width: 500,
                height: 580,
            },



        };
    },
    created() {

        this.LeyDetalleService = new LeyDetalleService();


    },
    mounted() {

        this.loadDataInfo();
    },
    methods: {
        loadDataInfo() {

            let id = this.supplierId;
            let myJSON = JSON.stringify({
                pleyid: id,
                pparteleyid: 2 //Titulo
            });
            this.LeyDetalleService.getLeyDetalleParte(myJSON).then(data => this.dataSourceUsuarios = data);



        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },

        deleteRegister(e) {

            let myJSON = JSON.stringify({
                pid: e.data.id,
            });


            this.LeyDetalleService.deleteLeyDetalle(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        editProcedure(e) {

            let idley = this.supplierId;
            let myJSON = JSON.stringify({
                pid: e.data.id,
                porden: e.data.orden,
                pleyid: idley,
                pparteleyid: 2,
                pleydetalleid: idley,
                pdescripcioncorta: e.data.descripcioncorta,
                pdescripcionlarga: e.data.descripcionlarga,
                pbactivo: e.data.bactivo,
                pbvisible: 1,
            });


            this.LeyDetalleService.updateLeyDetalle(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        createRegister(e) {

            let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
            let idley = this.supplierId;
            let myJSON = JSON.stringify({
                porden: e.data.orden,
                pleyid: idley,
                pparteleyid: 2,
                pleydetalleid: idley,
                pdescripcioncorta: e.data.descripcioncorta,
                pdescripcionlarga: e.data.descripcionlarga,
                pbactivo: e.data.bactivo,
                pusuarioregistroid: idusuario,
                pbvisible: 1,
            });


            this.LeyDetalleService.createLeyDetalle(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        onInitNewRow(e) {
            e.data.bactivo = true;
        },
    },
};
