

import {
    DxDataGrid, DxColumn, DxPaging,
    DxButton,

} from 'devextreme-vue/data-grid';
import DxPopup from 'devextreme-vue/popup';
import NotificationService from "../services/notification-service";
import Cookies from "js-cookie";
import { DxTextBox } from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import notify from 'devextreme/ui/notify';

window.Cookies = Cookies;
export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxButton,
        DxPopup,
        DxTextBox,
        DxTextArea,
    },
    data() {
        return {
            dataSourceCompany: [],
            idusuario: 0,
            popupVisible: false,
            popupTitle: "Notificación",
            loading: true,
            frm_notificacion: null,
            frm_usuarioregistro: null,
            frm_fecharegistro: null,
            frm_id: null,
            frm_bactivo: null,
        };
    },
    created() {
        this.notificationService = new NotificationService();
    },
    mounted() {
        this.idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
        this.getnotificationuser();
    },
    methods: {

        saveRegister() {


            let err = 0;


            let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
            let vactivo = 1;//this.frm_bactivo == true ? 1 : 0;

            let myJSON = JSON.stringify({
                pid: this.frm_id,
                pusuario: idusuario,
                pbactivo: vactivo
            });


            console.log(myJSON);
            if (err == 0) {
                this.notificationService.updateNotification(myJSON).then(data => {

                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha leido el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        //this.popupVisible = false;
                        this.getnotificationuser();

                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);


                    }
                    //this.loadDataInfo();

                });
            }



        },

        getnotificationuser() {
            let dataJson = {
                pusuario: this.idusuario
            };

            this.notificationService.registerNotification(dataJson).then(data => {
                this.dataSourceCompany = data;
            });
        },

        leer(e) {
            this.loading = true;
            this.popupVisible = true;
            this.frm_notificacion = e.row.data.alerta;
            this.frm_fecharegistro = e.row.data.fecharegistro;
            this.frm_usuarioregistro = e.row.data.usuarioregistro;
            this.frm_id = e.row.data.id;
            this.frm_bactivo = e.row.data.leida;
            this.saveRegister();
        },

    },
};
