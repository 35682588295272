
import AppFooter from "./components/app-footer";
import Cookies from "js-cookie";
window.Cookies = Cookies;
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import {
  getCurrentInstance,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
} from "vue";
import { loadMessages, locale } from "devextreme/localization";
import esMessages from "devextreme/localization/messages/es.json";
import enMessages from "devextreme/localization/messages/en.json";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
  };
}

export default {
  components: {
    AppFooter,
  },
 
  setup() {
    const vm = getCurrentInstance();

    const title = vm.proxy.$appInfo.title;
    const screen = reactive({ getScreenSizeInfo: {} });
    screen.getScreenSizeInfo = getScreenSizeInfo();

    function screenSizeChanged() {
      screen.getScreenSizeInfo = getScreenSizeInfo();
    }

    onMounted(() => {
      subscribe(screenSizeChanged);
    });

    onBeforeUnmount(() => {
      unsubscribe(screenSizeChanged);
    });

    const cssClasses = computed(() => {
      return ["app"].concat(screen.getScreenSizeInfo.cssClasses);
    });

    return {
      title,
      screen,
      cssClasses,
    };
  },

  created() {
    loadMessages(esMessages);
    loadMessages(enMessages);
    loadMessages({
      "es": {
        "dxGantt-dialogButtonAdd": "Agregar",
        "dxGantt-deleteSelectedTask": "Eliminar tarea",
        "dxGantt-contextMenuNewTask": "Tarea",
        "dxGantt-contextMenuNewSubtask": "SubTarea",
        "dxGantt-contextMenuDeleteTask": "Eliminar",
        "dxGantt-addNewTask": "Agregar nueva tarea",
        "dxGantt-expandAll": "Descomprimir",
        "dxGantt-collapseAll": "Comprimir",
        "dxGantt-undo": "Deshacer",
        "dxGantt-redo": "Rehacer",
        "dxGantt-dialogTaskDetailsTitle": "Detalles",
        "dxGantt-dialogTitle": "Titulo",
        "dxGantt-dialogStartTitle": "Comienzo",
        "dxGantt-dialogEndTitle": "Final",
        "dxGantt-dialogProgressTitle": "Progreso",
        "dxGantt-dialogResourcesTitle": "Responsable",
        "dxGantt-quarter": "Q{0}",
        "dxGantt-dialogTaskDeleteConfirmation": "Al eliminar esta tarea también se eliminaran todas sus dependencias y subtareas. ¿Estás seguro de que deseas eliminar esta tarea?"
      }
    });
    locale(navigator.language);
  },

  mounted() {
    
    this.userdata = Cookies.get("ets_user_ec");
    if (typeof this.userdata == "undefined") {
      Cookies.set("ets_user_ec", null);
    }


    if (this.userdata == null || this.userdata == "null") {
      this.$router.push({ path: "/login" });
    } else {
      this.user = 1;
    }
     
  },
};
