
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,

    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    DxButton,
    DxFormItem
} from 'devextreme-vue/data-grid';

import { DxToast } from 'devextreme-vue/toast';
 
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxDateBox from 'devextreme-vue/date-box';
import DxTextArea from 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box';
import 'devextreme-vue/text-area';
import { DxTextBox } from 'devextreme-vue/text-box';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import OrdenTrabajoService from '../services/ordentrabajo-service';
import CotizacionServicioService from '../services/cotizacionservicio-service';
import ResponsableTecnicoService from '../services/responsabletecnico-service';
import EstatusService from '../services/estatus-service';
import TipoProyectoService from '../services/tipoproyecto-service';
import VisitaCampoService from '../services/reportevisitacampo-service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import Servicioervice from '../services/servicio-service';

import Cookies from "js-cookie";
window.Cookies = Cookies;


export default {
    components: {
        DxFormItem,
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,

        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,
        DxTextBox,
        DxButton,
        DxPopup,
        DxScrollView,
        DxToolbarItem,
        DxDateBox,
        DxTextArea,
        DxSelectBox
    },
    data() {
        return {
            dataSourceVisitaCampo:null,
            dataSourceOrdenTrabajo: null,
            dataSourceTipoProyecto: null,
            dataSourceSolicitud: null,
            dataSourceResponsable:null,
            frm_responsable:null,
            frm_servicio: null,
            frm_numero: null,
            frm_fechainiciovigencia: null,
            frm_fechafinvigencia: null,
            frm_seguimiento: null,
            frm_fechainicioplaneada: null,
            frm_fechafinplaneada: null,
            frm_observaciones: null,
            frm_aplicaeca: null,
            frm_tipoproyectoid: null,
            frm_ecaid: null,
            dataSourceECA: null,
            v_es_eca: 'N',
            frm_visitacampoid:null,
            popupVisible: false,
            popupTitle: "Orden Trabajo",
            loading: true,

            popupVisible2: false,
            popupTitle2: "Edición de Orden Trabajo",
            v_ed_aplicacambioservicio: 'S',
            frm_ed_id:null,
            frm_ed_servicio: null,
            frm_ed_tiposervicioid: null,
            frm_ed_responsable: null,
            frm_ed_tipoproyectoid: null,
            frm_ed_visitacampoid: null,
            frm_ed_fechainicioplaneada: null,
            frm_ed_fechafinplaneada: null,
            frm_ed_observaciones: null,
            frm_ed_solicitud: null,
            frm_ed_cotizacion: null,
            frm_ed_ot: null,
            frm_ed_cliente: null,
            dataSourceServicio: null,


            dataSourceEstatus: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Generacion Orden Trabajo Base',
                showTitle: true,
                width: 1000,
                height: 450,
            },
            suppliersData: createStore({
                key: 'SupplierID',
            }),
        };
    },
    created() {
        this.ordentrabajoService = new OrdenTrabajoService();
        this.cotizacionservicioService = new CotizacionServicioService();
        this.responsabletecnicoService = new ResponsableTecnicoService();
        this.estatusService = new EstatusService();
        this.tipoproyectoService = new TipoProyectoService();
        this.visitacampoService = new VisitaCampoService();
        this.servicioService = new Servicioervice();
    },
    mounted() {
        this.loadDataInfo();
    },
    methods: {
        editarOT(e) {
           console.log(e.row.data);
           this.servicioService.getServicio().then(data => this.dataSourceServicio = data);
           
           this.frm_ed_id = e.row.data.idedicion;
           this.frm_ed_servicio = e.row.data.servicioid;
           
           this.frm_ed_tiposervicioid = e.row.data.tiposervicioid;
           this.frm_ed_responsable= e.row.data.responsabletecnicoid;
           this.frm_ed_tipoproyectoid= e.row.data.tipoproyectoid;
           this.frm_ed_visitacampoid= e.row.data.visitacampoid;
           this.frm_ed_fechainicioplaneada= e.row.data.fechainicioplaneada;
           this.frm_ed_fechafinplaneada= e.row.data.fechafinplaneada;
           this.frm_ed_observaciones= e.row.data.observacion;
           this.frm_ed_solicitud= e.row.data.solicitud;
           this.frm_ed_cotizacion= e.row.data.cotizacion;
           this.frm_ed_ot= e.row.data.numero;
           this.frm_ed_cliente= e.row.data.cliente;
            
            if(
                this.frm_ed_tiposervicioid == '1' || this.frm_ed_tiposervicioid == 1 ||
                this.frm_ed_tiposervicioid == '3' || this.frm_ed_tiposervicioid == 3
            )
            {
                this.v_ed_aplicacambioservicio = 'N';
            }
            else{
                this.v_ed_aplicacambioservicio = 'S';
            }
           this.popupVisible2=true;

        },

        onValueChanged(e) {

           
            let datos = this.dataSourceSolicitud;
           
            datos.forEach(element => {
                if (element.id == e.value) {
                    
                    this.dataSourceVisitaCampo=null;
                    let myJSON_vs = JSON.stringify({
                        pclienteid: element.clienteid,
                    });
                    
                    this.visitacampoService.getDetalleReporteVisitaPorCliente(myJSON_vs).then(data => this.dataSourceVisitaCampo = data);
                  


                    this.v_es_eca='N';
                    this.frm_seguimiento = element.seguimiento;
                    this.dataSourceECA=null;
                    this.frm_ecaid=null;
                    this.frm_aplicaeca=0;
                    if(element.tiposervicioid==1){
                        this.v_es_eca='S';
                        this.frm_aplicaeca=1;
                            let myJSON = JSON.stringify({
                                pusuarioid: 0,
                                pclienteid: 0,
                            });
                            this.ordentrabajoService.getECAParaOT(myJSON).then(data => this.dataSourceECA = data);
                        }
                }
                
            });

            

        },
        editRegister(e) {

            console.log(e.row.data);

        },

        agregar() {
            this.frm_servicio = null;
            let myJSON = JSON.stringify({
                pordentrabajo: 0,
            });
            this.cotizacionservicioService.getCotizacionServicioOrdenTrabajoPorId(myJSON).then(data => this.dataSourceSolicitud = data);
            this.frm_ecaid=null;
            this.frm_visitacampoid=null;
            this.frm_tipoproyectoid=null;
            this.dataSourceECA=null;
            this.frm_numero = null;
            this.frm_fechainiciovigencia = null;
            this.frm_fechafinvigencia = null;
            this.frm_seguimiento = null;
            this.frm_fechainicioplaneada = null;
            this.frm_fechafinplaneada = null;
            this.frm_observaciones = null;
            this.frm_responsable = null;
            this.loading = true;
            this.popupVisible = true;

        },
        saveClick() {


            let err = 0;
            let msgerror = '';
            let guion = null;
            let id = null;
            let tipo = null;
            let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;


            if (this.frm_servicio == null) {
                err = 1;
                msgerror = 'Ingresar servicio';
            } else {
                guion = this.frm_servicio.indexOf('-');
                id = this.frm_servicio.substring(0, guion);
                tipo = this.frm_servicio.substring(guion + 1, 10);
            }

            if (err == 0) {
                if (this.frm_responsable == null) {
                    err = 1;
                    msgerror = 'Ingresar responsable de OT';
                }
            }
            if (err == 0) {
                if (this.frm_aplicaeca == 1 && this.frm_ecaid==null) {
                    err = 1;
                    msgerror = 'Seleccionar la ECA';
                }
            }

            if (err == 0) {
                if (this.frm_tipoproyectoid == null) {
                    err = 1;
                    msgerror = 'Ingresar tipo de proyecto';
                }
            }
            if (this.frm_visitacampoid == null || this.frm_visitacampoid=='null') {
                   
                this.frm_visitacampoid =0;
                }
            
            let myJSON = JSON.stringify({
                pnumeroordentrabajo: "",
                pcotizacionservicioid: id,
                pfechainicioplaneada: this.frm_fechainicioplaneada ? moment(this.frm_fechainicioplaneada).format('YYYY-MM-DD') : null,
                pfechafinplaneada: this.frm_fechafinplaneada ? moment(this.frm_fechafinplaneada).format('YYYY-MM-DD') : null,
                pfechainiciovigencia: tipo == 3 && this.frm_fechainiciovigencia ? moment(this.frm_fechainiciovigencia).format('YYYY-MM-DD') : null,
                pfechafinvigencia: tipo == 3 && this.frm_fechafinvigencia ? moment(this.frm_fechafinvigencia).format('YYYY-MM-DD') : null,
                pfechainicioreal: null,
                pfechafinreal: null,
                pobservacion: this.frm_observaciones,
                pusuarioregistroid: idusuario,
                ppoliza: 0,
                presponsable: this.frm_responsable,
                pecaid: this.frm_ecaid,
                ptipoproyectoid: this.frm_tipoproyectoid,
                pvisitacampoid: this.frm_visitacampoid
            });

            let myJSONPOliza = JSON.stringify({
                pnumeroordentrabajo: "",
                pcotizacionservicioid: id,
                pfechainicioplaneada: this.frm_fechainicioplaneada ? moment(this.frm_fechainicioplaneada).format('YYYY-MM-DD') : null,
                pfechafinplaneada: this.frm_fechafinplaneada ? moment(this.frm_fechafinplaneada).format('YYYY-MM-DD') : null,
                pfechainiciovigencia: tipo == 3 && this.frm_fechainiciovigencia ? moment(this.frm_fechainiciovigencia).format('YYYY-MM-DD') : null,
                pfechafinvigencia: tipo == 3 && this.frm_fechafinvigencia ? moment(this.frm_fechafinvigencia).format('YYYY-MM-DD') : null,
                pfechainicioreal: null,
                pfechafinreal: null,
                pobservacion: this.frm_observaciones,
                pusuarioregistroid: idusuario,
                ppoliza: 0,
                presponsable: this.frm_responsable,
                ptipoproyectoid: this.frm_tipoproyectoid,
                pvisitacampoid: this.frm_visitacampoid

            });

            if (tipo == 3) {
                if (err == 0) {
                    this.ordentrabajoService.createPoliza(myJSONPOliza).then(data => {

                        if (data.err == 0) {
                            this.type = 'success';
                            this.message = 'Se ha guardado el registro';
                            this.isVisible = true;

                            notify({
                                message: this.message,
                            }, this.type, 5000);
                            this.popupVisible = false;
                            this.loadDataInfo();
                        } else {

                            this.type = 'error';
                            this.message = 'Ocurrio un error ' + data.msg;
                            this.isVisible = true;
                            notify({
                                message: 'Ocurrio un error ' + data.msg,
                            }, this.type, 4000);


                        }


                    });
                } else {
                    this.type = 'error';
                    this.message = msgerror;
                    this.isVisible = true;
                    notify({
                        message: this.message,
                    }, this.type, 4000);

                }
            } else {

                if (err == 0) {
                console.log(myJSON);
                    this.ordentrabajoService.createOrdenTrabajo(myJSON).then(data => {

                        if (data.err == 0) {
                            this.type = 'success';
                            this.message = 'Se ha guardado el registro';
                            this.isVisible = true;

                            notify({
                                message: this.message,
                            }, this.type, 5000);
                            this.popupVisible = false;
                            this.loadDataInfo();
                        } else {

                            this.type = 'error';
                            this.message = 'Ocurrio un error ' + data.msg;
                            this.isVisible = true;
                            notify({
                                message: 'Ocurrio un error ' + data.msg,
                            }, this.type, 4000);


                        }


                    });
                } else {
                    this.type = 'error';
                    this.message = msgerror;
                    this.isVisible = true;
                    notify({
                        message: this.message,
                    }, this.type, 4000);

                }
            }




        },
        editClick() {

            let err = 0;
            let msgerror = '';
         
          
            let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;


            if (this.frm_ed_servicio == null) {
                err = 1;
                msgerror = 'Ingresar servicio';
            } 

            if (err == 0) {
                if (this.frm_ed_responsable == null) {
                    err = 1;
                    msgerror = 'Ingresar responsable de OT';
                }
            }
            

            if (err == 0) {
                if (this.frm_ed_tipoproyectoid == null) {
                    err = 1;
                    msgerror = 'Ingresar tipo de proyecto';
                }
            }
            if (this.frm_ed_visitacampoid == null || this.frm_ed_visitacampoid=='null') {
                
                this.frm_ed_visitacampoid =0;
                }

            let myJSON = JSON.stringify({
                pid: this.frm_ed_id,
                pservicioid: this.frm_ed_servicio,
                pfechainicioplaneada: this.frm_ed_fechainicioplaneada ? moment(this.frm_ed_fechainicioplaneada).format('YYYY-MM-DD') : null,
                pfechafinplaneada: this.frm_ed_fechafinplaneada ? moment(this.frm_ed_fechafinplaneada).format('YYYY-MM-DD') : null,
                pobservacion: this.frm_ed_observaciones,
                presponsabletecnicoid: this.frm_ed_responsable,
                ptipoproyectoid: this.frm_ed_tipoproyectoid,
                pvisitacampoid: this.frm_ed_visitacampoid,
                pusuarioregistroid: idusuario,
               
            });
            
          
                if (err == 0) {
                

                    if(this.frm_ed_tiposervicioid == 3 || this.frm_ed_tiposervicioid =='3'){
                        this.ordentrabajoService.updatePolizaOT(myJSON).then(data => {

                            if (data.err == 0) {
                                this.type = 'success';
                                this.message = 'Se ha actualizado el registro';
                                this.isVisible = true;

                                notify({
                                    message: this.message,
                                }, this.type, 5000);

                                this.popupVisible2=false;
                                this.loadDataInfo();
                            } else {

                                this.type = 'error';
                                this.message = 'Ocurrio un error ' + data.msg;
                                this.isVisible = true;
                                notify({
                                    message: 'Ocurrio un error ' + data.msg,
                                }, this.type, 4000);


                            }


                            });
                    }
                    else{
                        this.ordentrabajoService.updateOrdenTrabajo(myJSON).then(data => {

                            if (data.err == 0) {
                                this.type = 'success';
                                this.message = 'Se ha actualizado el registro';
                                this.isVisible = true;

                                notify({
                                    message: this.message,
                                }, this.type, 5000);

                                this.popupVisible2=false;
                                this.loadDataInfo();
                            } else {

                                this.type = 'error';
                                this.message = 'Ocurrio un error ' + data.msg;
                                this.isVisible = true;
                                notify({
                                    message: 'Ocurrio un error ' + data.msg,
                                }, this.type, 4000);


                            }


                            });
                    }
                   
                } else {
                    this.type = 'error';
                    this.message = msgerror;
                    this.isVisible = true;
                    notify({
                        message: this.message,
                    }, this.type, 4000);

                }
           




            },
        loadDataInfo() {
            this.dataSourceVisitaCampo=null;
            this.ordentrabajoService.getOrdenTrabajo().then(data => this.dataSourceOrdenTrabajo = data);
           
            this.responsabletecnicoService.getResponsableTecnicoOrden().then(data => this.dataSourceResponsable = data);
            this.tipoproyectoService.getTipoProyecto().then(data => this.dataSourceTipoProyecto = data);
            
        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },
        deleteProcedure(e) {

            let id = e.data.id
            let tiposervicio = e.data.tiposervicioid

            let myJSON = JSON.stringify({
                pid: id.substring(2, 50),
            });
            console.log(myJSON);



            if (tiposervicio != 3) {

                this.ordentrabajoService.deleteOrdenTrabajo(myJSON).then(data => {
                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha eliminado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible = false;
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: 'Ocurrio un error ' + data.msg,
                        }, this.type, 4000);
                        this.loadDataInfo();

                    }


                });
            } else {
                this.ordentrabajoService.deletePoliza(myJSON).then(data => {
                    if (data.err == 0) {
                        this.type = 'success';
                        this.message = 'Se ha eliminado el registro';
                        this.isVisible = true;

                        notify({
                            message: this.message,
                        }, this.type, 5000);
                        this.popupVisible = false;
                        this.loadDataInfo();
                    } else {

                        this.type = 'error';
                        this.message = 'Ocurrio un error ' + data.msg;
                        this.isVisible = true;
                        notify({
                            message: this.message,
                        }, this.type, 4000);
                        this.loadDataInfo();

                    }


                });

            }


        },

        onInitNewRow(e) {
            e.data.bactivo = true;
        },
    },
};
