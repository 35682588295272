
  //import { nullLiteral } from '@babel/types';
  import {
    DxDataGrid, DxColumn, DxEditing, DxPaging, DxLookup, DxValidationRule, DxStringLengthRule, DxRangeRule
  } from 'devextreme-vue/data-grid';
  import { DxToast } from 'devextreme-vue/toast';
  import TipoFolioService from '../services/tipofolio-service';
  import FolioDocumentoService from '../services/foliodocumento-service';
  import TipoServicioService from '../services/tiposervicio-service';
  import UsuarioService from '../services/usuario-service';
  import Cookies from "js-cookie";

//import { data } from '../data/data';
  window.Cookies = Cookies;
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxEditing,
      DxPaging,
      DxToast,
      DxLookup,
      DxValidationRule,
      DxStringLengthRule,
      DxRangeRule
    },
    data() {
      return {
        dataSourceCompany: null,
        dataSourceTipoFolio: null,
        dataSourceTipoServicio: null,
        dataSourceUsuario: null,
        empresa: {},
        events: [],
        tipoFolioService: null,
        folioDocumentoService: null,
        tipoServicioService: null,
        usuarioService: null,
        isVisible: false,
        message: '',
        type: 'info',
        popupOptions: {
          title: 'Folio Documento',
          showTitle: true,
          //width: 420,
          //height: 250,
        },
      };
    },
    created() {
      this.tipoFolioService = new TipoFolioService();
      this.folioDocumentoService = new FolioDocumentoService();
      this.tipoServicioService = new TipoServicioService();
      this.usuarioService = new UsuarioService();
    },
    mounted() {
      this.loadinfo();
    },
    methods: {
      logEvent(eventName) {
        this.events.unshift(eventName);
      },
      loadinfo() {
  
        this.useconver = Cookies.get("user");
  
        this.folioDocumentoService.getFolioDocumento().then(data => this.dataSourceCompany = data);
        this.tipoFolioService.getTipoFolio().then(data => this.dataSourceTipoFolio = data);
        this.tipoServicioService.getTipoServicio().then(data => this.dataSourceTipoServicio = data);
        this.usuarioService.getUsuario().then(data => this.dataSourceUsuario = data);
      },
      editRegister(e) {
        let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;

        if(e.data.tiposervicioid === null){
            e.data.tiposervicioid = 1;
        }

        let myJSON = JSON.stringify({
          pid: e.data.id,
          pusuario: idusuario,
          pdescripcion: e.data.descripcion,
          ptipofolioid: e.data.tipofolioid,
          ptiposervicioid: e.data.tiposervicioid,
          panio: e.data.anio,
          pclave: e.data.clave,
          pnomenclatura: e.data.nomenclatura,
          pconsecutivo: e.data.consecutivo,
        });

        this.folioDocumentoService.updateFolioDocumento(myJSON).then(data => {
          
            if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;
            this.loadinfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
  
          }
        });
      },
      deleteRegister(e) {

        let myJSON = JSON.stringify({
          pid: e.data.id,
        });
  
        this.folioDocumentoService.deleteFolioDocumento(myJSON).then(data => {
  
            if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha eliminado el registro';
            this.isVisible = true;
            this.loadinfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            this.loadinfo();
  
          }
        });
  
  
      },
      createRegister(e) {

        let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;
        if(e.data.tiposervicioid === undefined){
            e.data.tiposervicioid = 1;
        }

        let myJSON = JSON.stringify({
          pid: idusuario,
          pdescripcion: e.data.descripcion,
          ptipofolioid: e.data.tipofolioid,
          ptiposervicioid: e.data.tiposervicioid,
          panio: e.data.anio,
          pclave: e.data.clave,
          pnomenclatura: e.data.nomenclatura,
          pconsecutivo: e.data.consecutivo,
        });

        console.log(e.data.tiposervicioid);
  
        this.folioDocumentoService.createFolioDocumento(myJSON).then(data => {
            if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha creado el registro';
            this.isVisible = true;
            
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            
          }
          this.loadinfo();
        });
  
  
  
      },
      onInitNewRow(e) {
        e.data.anio = 2024;
      },
    },
  };
  