
  
  
  import DxForm, {
    DxGroupItem,
    DxSimpleItem,
    DxLabel,
    DxItem
  } from 'devextreme-vue/form';
  
  
  import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxFormItem,
    DxValidationRule,
    DxButton,
    DxLookup,
   
  
  } from 'devextreme-vue/data-grid';
  
 
  
  import { DxTextBox } from 'devextreme-vue/text-box';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import DxDateBox from 'devextreme-vue/date-box';
import DxTextArea from 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box';

  import DxTabPanel from "devextreme-vue/tab-panel";
  import { customers } from './../data/dataperfil.js';
  import { DxToast } from 'devextreme-vue/toast';
  import 'devextreme-vue/text-area';
  import notify from 'devextreme/ui/notify';
  import moment from 'moment';
  import Cookies from "js-cookie";
   
  window.Cookies = Cookies;
  import OrdenTrabajoResultadoService from '../services/ordentrabajoresultado-service';
  import OrdenTrabajoService from '../services/ordentrabajo-service';
  import EstatusService from '../services/estatus-service';

  import Servicioervice from '../services/servicio-service';
   
 
import ResponsableTecnicoService from '../services/responsabletecnico-service';
import TipoProyectoService from '../services/tipoproyecto-service';
import VisitaCampoService from '../services/reportevisitacampo-service';
  
  
  const sendRequest = function (value) {
    const validEmail = 'test@dx-email.com';
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(value === validEmail);
      }, 1000);
    });
  };
  
  export default {
    components: {
     
      
      DxGroupItem,
      DxSimpleItem,
      DxDataGrid,
      DxTabPanel,
      DxItem,
      DxToast,
      DxEditing,
      DxColumn,
      DxPaging,
      DxForm,
      DxFilterRow,
      DxSearchPanel,
      DxScrolling,
      DxPager,
      DxLabel,
      DxFormItem,
      DxButton,
 
      DxValidationRule,
      DxLookup,

      DxTextBox,
        DxPopup,
        DxScrollView,
        DxToolbarItem,
        DxDateBox,
        DxTextArea,
        DxSelectBox
      
    },
    data() {
      return {
        

            dataSourceVisitaCampo:null,
            dataSourceTipoProyecto: null,
            dataSourceResponsable:null,
            frm_responsable:null,
            frm_servicio: null,
            frm_numero: null,
            frm_fechainiciovigencia: null,
            frm_fechafinvigencia: null,
            frm_seguimiento: null,
            frm_fechainicioplaneada: null,
            frm_fechafinplaneada: null,
            frm_observaciones: null,
            frm_aplicaeca: null,
            frm_tipoproyectoid: null,
            frm_ecaid: null,
            dataSourceECA: null,
            v_es_eca: 'N',
            frm_visitacampoid:null,
            popupVisible2: false,
            popupTitle2: "Orden Trabajo",
             

            popupVisible3: false,
            popupTitle3: "Edición de Orden Trabajo",
            v_ed_aplicacambioservicio: 'S',
            frm_ed_id:null,
            frm_ed_servicio: null,
            frm_ed_tiposervicioid: null,
            frm_ed_responsable: null,
            frm_ed_tipoproyectoid: null,
            frm_ed_visitacampoid: null,
            frm_ed_fechainicioplaneada: null,
            frm_ed_fechafinplaneada: null,
            frm_ed_observaciones: null,
            frm_ed_solicitud: null,
            frm_ed_cotizacion: null,
            frm_ed_ot: null,
            frm_ed_cliente: null,
            dataSourceServicio: null,



        popupVisible:false,
        popupTitle: "Servicios Poliza",
        loading: true,
         
        dataSource: customers,
        dataSourceOT: null,
        vclienteid: null,

        dataSourceCierre: null,
        dataSourceEstatus: null,
        dataSourceHistorial: null,
       
        showInfo: true,
        showNavButtons: true,
        isVisible: false,
        message: '',
        type: 'info',
        popupOptions: {
          title: 'Servicios en Poliza',
          showTitle: true,
          width: 900,
          height: 600,
        }, popupOptions2: {
          title: 'Cambio Estatus',
          showTitle: true,
          width: 500,
          height: 310,
        },
        valetapa: 0,
        valcierre: '',
        valtipodocumento: 0,
        valnombredocumento: '',
        columns: ['CompanyName', 'City', 'State', 'Phone', 'Fax'],
        customer: null, 
        buttonOptions: {
          text: 'Actualizar Orden Trabajo',
          type: 'success',
          useSubmitBehavior: true,
        },
        readonlyOptions: {
          disabled: true,
        },
        passwordOptions: {
          mode: 'password',
        },
        dateBoxOptions: {
          invalidDateMessage:
            'Campos tipo fecha debe tener el siguiente formato: MM/dd/yyyy',
        },
        checkBoxOptions: {
          text: 'I agree to the Terms and Conditions',
          value: false,
        },
        phoneEditorOptions: {
          mask: '+1 (X00) 000-0000',
          maskRules: {
            X: /[02-9]/,
          },
          maskInvalidMessage: 'The phone must have a correct USA phone format',
        },
        currencyOptions: {
          mask: '#,##0.00',
          maskInvalidMessage: 'The phone must have a correct USA phone format',
  
        },
  
        maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
        namePattern: /^[^0-9]+$/,
        cityPattern: /^[^0-9]+$/,
        phonePattern: /^[02-9]\d{9}$/,
        numsPattern: /[0-9]/,
        vidot: null,
      };
    },
    created() {
      this.ordentrabajoresultadoService = new OrdenTrabajoResultadoService();
      this.servicioService = new Servicioervice();
      this.ordentrabajoService = new OrdenTrabajoService();
      this.estatusService = new EstatusService();

      this.responsabletecnicoService = new ResponsableTecnicoService();
        this.tipoproyectoService = new TipoProyectoService();
        this.visitacampoService = new VisitaCampoService();

      this.vidot = this.$route.params.id;
      
  
    },
    mounted() {
      this.loadDataInfo();
    },
    methods: {

      agregar() {
            this.frm_servicio = null;
             this.frm_ecaid=null;
            this.frm_visitacampoid=null;
            this.frm_tipoproyectoid=null;
            this.dataSourceECA=null;
            this.frm_numero = null;
            this.frm_fechainiciovigencia = null;
            this.frm_fechafinvigencia = null;
            this.frm_seguimiento = null;
            this.frm_fechainicioplaneada = null;
            this.frm_fechafinplaneada = null;
            this.frm_observaciones = null;
            this.frm_responsable = null;
            this.loading = true;
            this.popupVisible2 = true;

        },

        onValueChanged(e) {

       
          this.v_es_eca='N';
          this.frm_aplicaeca=0;
          if(e.value == 1){
            this.v_es_eca='S';
            this.frm_aplicaeca=1;
            let myJSON = JSON.stringify({
                                pusuarioid: 0,
                                pclienteid: 0,
                            });
             this.ordentrabajoService.getECAParaOT(myJSON).then(data => this.dataSourceECA = data);
          }

          },
          editarOT(e) {
           console.log(e.row.data);
           this.servicioService.getServicio().then(data => this.dataSourceServicio = data);
           
           this.frm_ed_id = e.row.data.idedicion;
           this.frm_ed_servicio = e.row.data.servicioid;
           
           this.frm_ed_tiposervicioid = e.row.data.tiposervicioid;
           this.frm_ed_responsable= e.row.data.responsabletecnicoid;
           this.frm_ed_tipoproyectoid= e.row.data.tipoproyectoid;
           this.frm_ed_visitacampoid= e.row.data.visitacampoid;
           this.frm_ed_fechainicioplaneada= e.row.data.fechainicioplaneada;
           this.frm_ed_fechafinplaneada= e.row.data.fechafinplaneada;
           this.frm_ed_observaciones= e.row.data.observacion;
           this.frm_ed_solicitud= e.row.data.solicitud;
           this.frm_ed_cotizacion= e.row.data.cotizacion;
           this.frm_ed_ot= e.row.data.numero;
           this.frm_ed_cliente= e.row.data.cliente;
            
            if(
                this.frm_ed_tiposervicioid == '1' || this.frm_ed_tiposervicioid == 1 ||
                this.frm_ed_tiposervicioid == '3' || this.frm_ed_tiposervicioid == 3
            )
            {
                this.v_ed_aplicacambioservicio = 'N';
            }
            else{
                this.v_ed_aplicacambioservicio = 'S';
            }
           this.popupVisible3=true;

        },

        saveClick() {


            let err = 0;
            let msgerror = '';
           
            let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;


            if (this.frm_servicio == null) {
                err = 1;
                msgerror = 'Ingresar servicio';
            } 

            if (err == 0) {
                if (this.frm_responsable == null) {
                    err = 1;
                    msgerror = 'Ingresar responsable de OT';
                }
            }
            if (err == 0) {
                if (this.frm_aplicaeca == 1 && this.frm_ecaid==null) {
                    err = 1;
                    msgerror = 'Seleccionar la ECA';
                }
            }

            if (err == 0) {
                if (this.frm_tipoproyectoid == null) {
                    err = 1;
                    msgerror = 'Ingresar tipo de proyecto';
                }
            }
            if (this.frm_visitacampoid == null || this.frm_visitacampoid=='null') {
                   
                this.frm_visitacampoid =0;
                }
  
            let myJSONPOliza = JSON.stringify({
                pnumeroordentrabajo: "",
                pfechainicioplaneada: this.frm_fechainicioplaneada ? moment(this.frm_fechainicioplaneada).format('YYYY-MM-DD') : null,
                pfechafinplaneada: this.frm_fechafinplaneada ? moment(this.frm_fechafinplaneada).format('YYYY-MM-DD') : null,
                pobservacion: this.frm_observaciones,
                pusuarioregistroid: idusuario,
                ppoliza: this.vidot,
                pservicioid: this.frm_servicio,
                presponsabletecnicoid: this.frm_responsable,
                pecaid: this.frm_ecaid,
                ptipoproyectoid: this.frm_tipoproyectoid,
                pvisitacampoid: this.frm_visitacampoid

            });
            
            if (err == 0) {
                    this.ordentrabajoService.createPolizaDetalle(myJSONPOliza).then(data => {
                     
                        if (data.err == 0) {
                            this.type = 'success';
                            this.message = 'Se ha guardado el registro';
                            this.isVisible = true;

                            notify({
                                message: this.message,
                            }, this.type, 5000);
                            this.popupVisible2 = false;
                            this.loadDataInfo();
                        } else {

                            this.type = 'error';
                            this.message = 'Ocurrio un error ' + data.msg;
                            this.isVisible = true;
                            notify({
                                message: 'Ocurrio un error ' + data.msg,
                            }, this.type, 4000);


                        }


                    });
                } else {
                    this.type = 'error';
                    this.message = msgerror;
                    this.isVisible = true;
                    notify({
                        message: this.message,
                    }, this.type, 4000);

                }



        },
        editClick() {

            let err = 0;
            let msgerror = '';
         
          
            let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;


            if (this.frm_ed_servicio == null) {
                err = 1;
                msgerror = 'Ingresar servicio';
            } 

            if (err == 0) {
                if (this.frm_ed_responsable == null) {
                    err = 1;
                    msgerror = 'Ingresar responsable de OT';
                }
            }
            

            if (err == 0) {
                if (this.frm_ed_tipoproyectoid == null) {
                    err = 1;
                    msgerror = 'Ingresar tipo de proyecto';
                }
            }
            if (this.frm_ed_visitacampoid == null || this.frm_ed_visitacampoid=='null') {
                
                this.frm_ed_visitacampoid =0;
                }

            let myJSON = JSON.stringify({
                pid: this.frm_ed_id,
                pservicioid: this.frm_ed_servicio,
                pfechainicioplaneada: this.frm_ed_fechainicioplaneada ? moment(this.frm_ed_fechainicioplaneada).format('YYYY-MM-DD') : null,
                pfechafinplaneada: this.frm_ed_fechafinplaneada ? moment(this.frm_ed_fechafinplaneada).format('YYYY-MM-DD') : null,
                pobservacion: this.frm_ed_observaciones,
                presponsabletecnicoid: this.frm_ed_responsable,
                ptipoproyectoid: this.frm_ed_tipoproyectoid,
                pvisitacampoid: this.frm_ed_visitacampoid,
                pusuarioregistroid: idusuario,
               
            });
            
          
                if (err == 0) {
                

                  this.ordentrabajoService.updatePolizaOTDetalle(myJSON).then(data => {

                            if (data.err == 0) {
                                this.type = 'success';
                                this.message = 'Se ha actualizado el registro';
                                this.isVisible = true;

                                notify({
                                    message: this.message,
                                }, this.type, 5000);

                                this.popupVisible3=false;
                                this.loadDataInfo();
                            } else {

                                this.type = 'error';
                                this.message = 'Ocurrio un error ' + data.msg;
                                this.isVisible = true;
                                notify({
                                    message: 'Ocurrio un error ' + data.msg,
                                }, this.type, 4000);


                            }


                            });
                   
                } else {
                    this.type = 'error';
                    this.message = msgerror;
                    this.isVisible = true;
                    notify({
                        message: this.message,
                    }, this.type, 4000);

                }
           




            },



      deleteRegister(e) {

             
            let myJSON = JSON.stringify({
                pid: e.data.id.substring(2,50),
            });


             
            this.ordentrabajoService.deleteOrdenTrabajo(myJSON).then(data => {

            if (data.err == 0) {
                this.type = 'success';
                this.message = 'Se ha actualizado el registro';
                this.isVisible = true;

                notify({
                    message: this.message,
                }, this.type, 5000);
                this.popupVisible = false;
                this.loadDataInfo();
                } else {

                this.type = 'error';
                this.message = 'Ocurrio un error ' + data.msg;
                this.isVisible = true;
                notify({
                    message: 'Ocurrio un error ' + this.message,
                }, this.type, 4000);
                this.loadDataInfo();


            }
             

            });

        },
        createRegister(e) {
            let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id; 
            let myJSON = JSON.stringify({
                pnumeroordentrabajo: e.data.numero,
                
                pfechainicioplaneada: moment(e.data.fechainicioplaneada).format('YYYY-MM-DD'),
                pfechafinplaneada: moment(e.data.fechafinplaneada).format('YYYY-MM-DD'), 
                pfechainiciovigencia:'9999-01-01' ,
                pfechafinvigencia: '9999-01-01' ,
                pfechainicioreal: '9999-01-01',
                pfechafinreal: '9999-01-01',
                pobservacion: e.data.observacion ,
                pusuarioregistroid: idusuario ,
                ppoliza: this.vidot ,
                pservicioid: e.data.servicioid,
            });


             
            this.ordentrabajoService.createPolizaDetalle(myJSON).then(data => {

            if (data.err == 0) {
                this.type = 'success';
                this.message = 'Se ha guardado el registro';
                this.isVisible = true;

                notify({
                    message: this.message,
                }, this.type, 5000);
                this.popupVisible = false;
                this.loadDataInfo();
                } else {

                this.type = 'error';
                this.message = 'Ocurrio un error ' + data.msg;
                this.isVisible = true;
                notify({
                    message: 'Ocurrio un error ' + this.message,
                }, this.type, 4000);
                this.loadDataInfo();


            }
             

            });

      },
       
        editRegisterCierre(e) {
            
            let myJSON = JSON.stringify({
                pid: e.data.id,
                pestatusid: e.data.estatusid,
                pobservacion: e.data.observacion,
            });


             
            this.ordentrabajoService.updatePoliza(myJSON).then(data => {

            if (data.err == 0) {
                this.type = 'success';
                this.message = 'Se ha actualizado el registro';
                this.isVisible = true;

                notify({
                    message: this.message,
                }, this.type, 5000);
                this.popupVisible = false;
                this.loadDataInfo();
                } else {

                this.type = 'error';
                this.message = 'Ocurrio un error ' + data.msg;
                this.isVisible = true;
                notify({
                    message: 'Ocurrio un error ' + this.message,
                }, this.type, 4000);
                this.loadDataInfo();


            }
             

            });

      },
      showPopup() {
         
         
        this.loading = true;      
        
        this.popupVisible = true;
        
      },
      editECA(e) {  
         
        let vid = e.row.data.id;
        let vtipo = e.row.data.tiposervicioid;
        let vidparse = vid.substring(2,50);
        // 1 = ECA
        if(vtipo==1){
            this.$router.push({ name: 'ordentrabajoresultadoconguia', params: { id: vidparse }});
        } 
        else{
            this.$router.push({ name: 'ordentrabajoresultadoestudio', params: { id: vidparse }});
        }  
        
         
      },
      onClickCierraEtapa() {
  
        let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
        let myJSON = JSON.stringify({
          pid: this.vidot,
          pusuario: idusuario,
          petapaid: this.valetapa,
        });
  
        this.ordentrabajoresultadoService.getOrdenTrabajoCierreEtapa(myJSON).then(data => {
  
          if (data.err == 0) {
  
            notify({
              message: 'OT Etapa ha sido actualizada',
            }, 'success', 9000);
             
            this.loadDataInfo();
          } else {
  
            notify({
              message: 'Ocurrio un error ' + data.msg,
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'error', 9000);
             
  
          }
  
  
        });
  
  
      },
      onClickCierra() {
  
        let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
        let myJSON = JSON.stringify({
          pid: this.vidot,
          pusuario: idusuario,

          pobservacion: this.valcierre,
        });
  
        this.ordentrabajoresultadoService.getOrdenTrabajoCierre(myJSON).then(data => {
  
          if (data.err == 0) {
  
            notify({
              message: 'OT ha sido actualizada',
            }, 'success', 9000);
            this.$router.push({ name: 'ordentrabajoresultadoconguia', params: { id: this.vidot } });
          } else {
  
            notify({
              message: 'Ocurrio un error ' + data.msg,
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'error', 9000);
             
  
          }
  
  
        });
  
      },
      loadDataInfo() {
  
        let id = this.vidot;
        let myJSON = '';
        myJSON = JSON.stringify({
          pid: id,
  
        });
  
       
                    
         
  
        this.ordentrabajoresultadoService.getPolizaResultadoPorId(myJSON).then(data => {
              this.customer = data;
             
              let myJSON_vs = JSON.stringify({
                  pclienteid: data.clienteid,
              });
              this.visitacampoService.getDetalleReporteVisitaPorCliente(myJSON_vs).then(data => this.dataSourceVisitaCampo = data);
            }
        );

        
       
        this.responsabletecnicoService.getResponsableTecnicoOrden().then(data => this.dataSourceResponsable = data);
        this.tipoproyectoService.getTipoProyecto().then(data => this.dataSourceTipoProyecto = data);    
        
        this.ordentrabajoresultadoService.getPolizaPorServicio(myJSON).then(data => {
              this.dataSourceOT = data
            }
        );
      
        this.ordentrabajoService.getPolizaDetallePorId(myJSON).then(data => {
              this.dataSourceCierre = data
            }
        );  
        this.servicioService.getServicio().then(data => this.dataSourceServicio = data);
        
        this.estatusService.getGrupoEstatusOrdenPoliza(myJSON).then(data => this.dataSourceEstatus = data);
         
      },
      
      asyncValidation(params) {
        return sendRequest(params.value);
      },
       
      editProcedureListadoInformacion(e) {
  
        let ventregado = e.data.entregado == true ? 1 : 0;
        let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
        let myJSON = JSON.stringify({
          pid: e.data.id,
          pentregado: ventregado,
          pfechaentrega: moment(e.data.fechaentrega).format('YYYY-MM-DD'),
          pfechacompromisoentrega: moment(e.data.fechacompromisoentrega).format('YYYY-MM-DD'),
          pusuarioregistroid: idusuario,
          pobservaciones: e.data.observaciones,
          precibio: e.data.recibio,
        });
  
  
  
        this.ordentrabajoresultadoService.updateOrdenTrabajoListadoInformacion(myJSON).then(data => {
  
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;
  
            notify({
              message: this.message,
            }, this.type, 9000);
             
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'error', 9000);
          }
          this.loadDataInfo();
  
        });
  
      },
      editProcedureListadoDocumentacion(e) {
  
        let ventregado = e.data.entregado == true ? 1 : 0;
  
        let myJSON = JSON.stringify({
          pid: e.data.id,
          pentregado: ventregado,
          pfechaentrega: moment(e.data.fechaentrega).format('YYYY-MM-DD'),
  
  
        });
  
  
  
        this.ordentrabajoresultadoService.updateOrdenTrabajoListadoDocumentacion(myJSON).then(data => {
  
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;
  
            notify({
              message: this.message,
            }, this.type, 9000);
             
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'error', 9000);
          }
          this.loadDataInfo();
  
        });
  
      },
      editProcedureResultado(e) {
  
        let vsi = e.data.valorsi == true ? 1 : 0;
        let vno = e.data.valorno == true ? 1 : 0;
        let vparcial = e.data.valorparcial == true ? 1 : 0;
        let vnoaplica = e.data.noaplica == true ? 1 : 0;
        let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id; 
        let myJSON = JSON.stringify({
          pid: e.data.id,
          pvalorsi: vsi,
          pvalorno: vno,
          pvalorparcial: vparcial,
          pnoaplica: vnoaplica,
          pusuarioregistroid: idusuario,
          pdiashabiles: 0,
          pobservacion: e.data.observacion,
          precomendacion: e.data.recomendacion,
  
        });
  
  
  
        this.ordentrabajoresultadoService.updateOrdenTrabajoResultado(myJSON).then(data => {
  
          if (data.err == 0) {
            this.type = 'success';
            this.message = 'Se ha actualizado el registro';
            this.isVisible = true;
  
            notify({
              message: this.message,
            }, this.type, 9000);
            this.loadDataInfo();
          } else {
  
            this.type = 'error';
            this.message = 'Ocurrio un error ' + data.msg;
            this.isVisible = true;
            notify({
              message: 'Ocurrio un error ' + data.msg,
              position: {
                my: 'center top',
                at: 'center top',
              },
            }, 'error', 9000);
          }
          this.loadDataInfo();
  
        });
  
      },
      onInitNewRow(e) {
        e.data.entregado = true;
      },
    },
  };
  