
import {
  DxDataGrid,
  DxEditing,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxFilterRow,
  DxSearchPanel,
  DxScrolling,
  DxPager,
  DxValidationRule,
  DxEmailRule,
  DxFormItem
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import EstadoService from '../services/estado-service';
import GiroService from '../services/giro-service';
import SubGiroService from '../services/subgiro-service';
import ClienteService from '../services/cliente-service';
import ClienteTipoService from '../services/clientetipo-service';
import TamanoService from '../services/tamano-service';
import Cookies from "js-cookie";
window.Cookies = Cookies;

var dataSourceSubGiros = [];

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxLookup,
    DxForm,
    DxItem,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxToast,
    DxValidationRule,
    DxEmailRule,
    DxFormItem
  },
  data() {
    return {
      customer: null,
      procedimientos: null,
      dataSourceClientes: null,
      dataSourceTamano: null,
      dataSourceClienteTipo: null,
      dataSourceEstados: null,
      dataSourceGiros: null,
      dataSourceSubGiros,
      allowedPageSizes: [5, 10, 20, 50, 'all'],
      showInfo: true,
      showNavButtons: true,
      isVisible: false,
      message: '',
      type: 'info',
      popupOptions: {
        title: 'Cliente',
        showTitle: true,
        width: 800,
        height: 'auto',
      },
      opcionesColumna: {
        maxLength: 3
      },
      setStateValueGiro(rowData, value) {
        rowData.subgiroid = null;
        this.defaultSetCellValue(rowData, value);
      },
    };
  },
  
  created() {
    this.estadoService = new EstadoService();
    this.giroService = new GiroService();
    this.clienteService = new ClienteService();
    this.subgiroService = new SubGiroService();
    this.tamanoService = new TamanoService();
    this.clientetipoService = new ClienteTipoService();
  },
  mounted() {
    this.loadDataInfo();
  },
  methods: {
    loadDataInfo() {
      this.estadoService.getEstados().then(data => this.dataSourceEstados = data);
      this.giroService.getGiro().then(data => this.dataSourceGiros = data);
      this.clienteService.getCliente().then(data => this.dataSourceClientes = data);
      this.tamanoService.getTamano().then(data => this.dataSourceTamano = data);
      this.clientetipoService.getClienteTipo().then(data => this.dataSourceClienteTipo = data);
      this.subgiroService.getSubGirosGenerales().then(data => dataSourceSubGiros = data);
      
    },
    onSaved() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    onEditCanceled() {
      if (this.retryButtonVisible) {
        this.retryButtonVisible = false;
      }
    },
    logEvent(eventName) {
      this.events.unshift(eventName);
    },
    editProcedure(e) {

      let subgiro = e.data.subgiroid != null ? e.data.subgiroid: 0;
      let vactivo = e.data.bactivo == true ? 1 : 0;
      console.log(e.data);
      let myJSON = JSON.stringify({
        pid: e.data.id,
        pnombre: e.data.nombre,
        prazonsocial: e.data.razonsocial,
        prfc: e.data.rfc,
        pdireccion: e.data.direccion,
        ptelefono: e.data.telefono,
        pcorreo: e.data.correo,
        pestadoid: e.data.estadoid,
        pgiroid: e.data.giroid,
        psubgiroid: subgiro,
        pbactivo: vactivo,
        ptamanoid: e.data.tamanoid,
        pclientetipoid: e.data.clientetipoid,
        pcodigocliente: '',
      });
      console.log(myJSON);

      /**
      this.clienteService.updateCliente(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha actualizado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });*/
      
      this.clienteService.updateCliente(myJSON).then(data => {
        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha actualizado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
        }
      });
    },
    deleteProcedure(e) {
      let myJSON = JSON.stringify({
        pid: e.data.id,
      });

      /**
      this.clienteService.deleteCliente(myJSON).then(() => {
        this.type = 'success';
        this.message = 'Se ha eliminado el registro';
        this.isVisible = true;
        this.loadDataInfo();
      });
       */

      this.clienteService.deleteCliente(myJSON).then(data => {
        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha eliminado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
        }
      });
    },
    createProcedure(e) {

      let subgiro = e.data.subgiroid != null ? e.data.subgiroid: 0;
      let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
      let vactivo = e.data.bactivo == true ? 1 : 0;
      let myJSON = JSON.stringify({
        pnombre: e.data.nombre,
        prazonsocial: e.data.razonsocial,
        prfc: e.data.rfc,
        pdireccion: e.data.direccion,
        ptelefono: e.data.telefono,
        pcorreo: e.data.correo,
        pestadoid: e.data.estadoid,
        pgiroid: e.data.giroid,
        psubgiroid: subgiro,
        pusuarioregistroid: idusuario,
        pbactivo: vactivo,
        ptamanoid: e.data.tamanoid,
        pclientetipoid: e.data.clientetipoid,
        pcodigocliente: '',
      });

      console.log(myJSON);


      
      this.clienteService.createCliente(myJSON).then(data => {
        if (data.err == 0) {
          this.type = 'success';
          this.message = 'Se ha creado el registro';
          this.isVisible = true;
          this.loadDataInfo();
        } else {

          this.type = 'error';
          this.message = 'Ocurrio un error ' + data.msg;
          this.isVisible = true;
        }
      });
    },
    /*
    giroCambio(e){
      
      if (e.dataField === 'giroid' && e.parentType === 'dataRow') {
          const defaultValueChangeHandler = e.editorOptions.onValueChanged;
          e.editorOptions.onValueChanged = function (args) { // Override the default handler
              // ...
              // Custom commands go here
              // ...
              // If you want to modify the editor value, call the setValue function:
              // e.setValue(newValue);
              // Otherwise, call the default handler:
              console.log("Cambio valor de Giro ", e.row.data);
              //console.log(this.dataSourceSubGiros)[]; 
              defaultValueChangeHandler(args);
          }
      }
      
    },*/
    
    getFilteredFundamentos: (options) => ({
      store: dataSourceSubGiros,
      filter: options.data ? ['giroid', '=', options.data.giroid] : null,
    }),
    onInitNewRow(e) {
      e.data.bactivo = true;
      e.data.subgiroid = null;
    },
  },
};
