import axios from 'axios';

export default class ListadoDocumentacionService {

    getListadoDocumentacion() {            
        const url = process.env.VUE_APP_APIURL + `/api/listadodocumentacion`;    
        return axios.get(url).then(res=>res.data);
    }

    updateListadoDocumentacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/listadodocumentacionedicion`;     
        return axios.put(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    createListadoDocumentacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/listadodocumentacionalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteListadoDocumentacion(info){
        const url = process.env.VUE_APP_APIURL + `/api/listadodocumentacionbaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }


    // Documento

    getListadoDocumento(info) {            
        
        const url = process.env.VUE_APP_APIURL + `/api/listadodocumentaciondocumento`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

     
    createListadoDocumento(info){
        const url = process.env.VUE_APP_APIURL + `/api/listadodocumentaciondocumentoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteListadoDocumento(info){
        const url = process.env.VUE_APP_APIURL + `/api/listadodocumentaciondocumentobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}