
 
import {
  DxDataGrid, DxColumn, DxEditing, DxPaging, DxExport, DxFormItem,
  DxGrouping,
  DxGroupPanel
} from 'devextreme-vue/data-grid';
import { DxToast } from 'devextreme-vue/toast';
import Reporteservice from '../services/reportes-service';

import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';





export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPaging,
    DxToast,
    DxExport,


    DxFormItem,
    DxGrouping,
    DxGroupPanel
  },
  data() {
    return {
      dataSourceCompany: null,
      empresa: {},
      events: [],
      rolService: null,
      isVisible: false,
      message: '',
      type: 'info',
      v_valor: null,
      popupOptions: {
        title: 'Informacion Alerta Envio',
        showTitle: true,
        width: 1000,
        height: 700,
      },
    };
  },
  created() {
    this.reportesService = new Reporteservice();
  },
  mounted() {
    this.reportesService.getReporteAlertasEnviado().then(data => { this.dataSourceCompany = data; });

  },
  methods: {

     
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('AvanceListado');

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReporteAlertas.xlsx');
        });
      });
      e.cancel = true;
    },


  },
};
